






































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { globalFunctions } from '@/util/global-functions';
import { List } from '@/interfaces/list';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';
import { RawLocation } from 'vue-router';

@Component
export default class IconBar extends Vue {
  @Prop({ required: true }) dataObject: Project | Procurement | ProcurementReference;
  @Prop({ default: 'lg' }) size: string;
  @Prop({ default: 'primary' }) variant: string;
  @Prop({ default: false }) isDropdown: boolean;
  @Prop({ default: false }) fixedHeight: boolean;
  linkCopied = false;

  get hasProduct(): boolean {
    return this.$vmx.user.hasProduct;
  }

  get containerClasses() {
    return [
      `bg-${this.variant}`,
      this.fixedHeight ? 'fixed-height' : '',
    ];
  }

  get firstListWithObject(): List {
    if (this.isOnList) {
      return this.$vmx.list.listsWithObject(this.dataObject)[0];
    }
    return null;
  }

  get exportlink(): RawLocation {
    const isProcurement = globalFunctions.isProcurement(this.dataObject as Project | Procurement);
    return {
      name: isProcurement ? 'PrintProcurement' : 'PrintProject',
      params: {
        id: this.dataObject.id.toString(),
      },
    };
  }

  get isOnList(): boolean {
    return this.$vmx.list.isInList(this.dataObject);
  }

  get isOnUserBlacklist(): boolean {
    return this.$vmx.blacklist.isOnUserBlacklist(this.dataObject as Project | Procurement);
  }

  get listButtonTooltip(): string {
    return this.$t(this.isOnList ? 'components.iconBar.list.removeTooltip' : 'components.iconBar.list.addTooltip') as string;
  }

  // --- OTHER ---------------------------------------------------------------
  emitUpdate() {
    this.$emit('listUpdated');
  }

  mailToString() {
    const email = 'sample@gmail.com';
    const subject = encodeURI('Projektagenten - ' + this.dataObject.name);
    const body = encodeURI(`Hej!\n\nTag lige et kig på noget jeg har fundet på Projektagenten: ${this.getUrl()}\n\n`)

    window.open(`mailto:${email}?subject=${subject}&body=${body}`);
  }

  getUrl() {
    let baseUrl = process.env.VUE_APP_BASE_URL;

    if (!(process.env.NODE_ENV === 'development')) {
      baseUrl += this.$vmx.domain.domain.sld + '.' + this.$vmx.domain.domain.tld;
    }

    if (globalFunctions.isProcurementOrProcurementReference(this.dataObject)) {
      return baseUrl + '/procurement/' + this.dataObject.id;
    } else {
      return baseUrl + '/project/' + this.dataObject.id;
    }
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.getUrl());
    this.linkCopied = true;
    globalFunctions.timeout(4000).then(() => this.linkCopied = false);
  }

  openBlacklistSettingsModal() {
    if (this.hasProduct) {
      this.$vmx.modal.openBlacklistSettingsModal(this.dataObject);
    } else {
      this.openPaywallModal();
    }
  }

  openBlacklistRemoveModal() {
    if (this.hasProduct) {
      this.$vmx.modal.openBlacklistRemoveModal(this.dataObject);
    } else {
      this.openPaywallModal();
    }
  }

  openListAddModal() {
    if (this.hasProduct) {
      this.$vmx.modal.openListAddModal(this.dataObject);
    } else {
      this.openPaywallModal();
    }
  }

  openPaywallModal() {
    this.$vmx.modal.openPaywallModal();
  }
}
