



































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { globalFunctions } from '@/util/global-functions';
import { Quote } from '@/interfaces/quote';
import { quoteService } from '@/services/restapi/quote-service';

@Component({})
export default class Quotes extends Vue {
  // The wait time for quote change in seconds
  @Prop({ default: 7 }) activeTime: number;

  @Ref() quoteContainer: HTMLDivElement;

  quotes: Quote[] = [];

  loading = false;

  quoteIndex = 0;
  isVisible = false;
  mouseHover = false;

  created() {
  }

  mounted() {
    quoteService.getQuotes().then((quotes) => {
      this.quotes = quotes.filter(
        (quote) => quote != null && quote.text != null && quote.text != '',
      );
    }).finally(() => {
      this.loading = false;
      if (this.quotes && this.quoteContainer) {
        this.quoteContainer.addEventListener('transitionend', this.transitionEnd);
        globalFunctions.timeout(100).then(() => this.isVisible = true);
      }
    });
  }

  get colClasses() {
    return [
      'col-12',
      'col-sm-8',
      this.hasImage ? '' : 'col-lg-6 col-xl-5 col-xxl-4',
    ];
  }

  get hasImage(): boolean {
    return !!this.quotes[this.quoteIndex]?.fileId;
  }

  get fadeClasses() {
    return [
      !this.isVisible ? 'fade-out' : '',
    ];
  }

  get signature() {
    return `${this.quotes[this.quoteIndex].author}, ${this.quotes[this.quoteIndex].companyName}`
  }

  mouseEnter() {
    this.mouseHover = true;
  }

  mouseLeave() {
    this.mouseHover = false;
  }

  transitionEnd(event: TransitionEvent) {
    if (event.propertyName === 'transform') { // Only select one of the transitions, as there are more
      if (this.isVisible) {
        globalFunctions.timeout(this.activeTime * 1000).then(() => {
          this.isVisible = false;
        });
      } else {
        if (!this.mouseHover) {
          this.isVisible = true;
          this.quoteIndex = (this.quoteIndex + 1) % this.quotes.length;
        }
      }
    }
  }

  getFromFile(index: number) {
    const image = this.quotes[index]?.fileId;
    if (image == null) {
      return null;
    }

    return image.link ?? this.$vmx.file.fromFile(`${image.code}.${image.extension}`);
  }
}
