

















































import { Component, Vue } from 'vue-property-decorator';
import { chooseDomain } from '@/util/domain-utils';
import { Domain } from '@/interfaces/domain';

@Component({})
export default class DropdownDomain extends Vue {
  getDomains(): Domain[] {
    return (this.$vmx.domain.domains as Domain[])
      .sort((domain1, domain2) => domain1.name.localeCompare(domain2.name));
  }

  getDomainSVG(tld: string) {
    return {
      backgroundImage: `url('${require('@/assets/flags/' + tld + '.svg')}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  }

  isActive(domain: Domain): boolean {
    return domain.id === (this.$vmx.domain.domain as Domain).id;
  }

  getUrl(domain: Domain): string {
    if (location.host.split(':')[0] === 'localhost') {
      return `https://www.${domain.sld}.${domain.tld}`;
    }
    const [ root, ...bla] = location.host.split('.');
    return `https://${root}.${domain.sld}.${domain.tld}`;
  }

  onClick(domain: Domain) {
    chooseDomain(domain)
  }
}
