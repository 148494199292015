







































































































import { Component, Vue } from 'vue-property-decorator';
import BaseLogo from '@/components/buttons/base-logo.vue';
import { Language } from '@/interfaces/language';
import { RouterNames } from '@/util/enums/router-names';
@Component({
  components: {
    BaseLogo,
  },
})
export default class TheFooter extends Vue {

  routeNames = {
    about: RouterNames.LOGGED_OUT_ABOUT,
    bookDemo: RouterNames.LOGGED_OUT_BOOK_DEMO,
    contact: RouterNames.LOGGED_OUT_CONTACT,
    cookiePolicy: RouterNames.LOGGED_OUT_COOKIE_POLICY,
    jura: RouterNames.LOGGED_OUT_JURA,
    prices: RouterNames.LOGGED_OUT_PRICES,
    services: RouterNames.LOGGED_OUT_SERVICES,
  };

  get languages() {
    return (this.$vmx.language.languages as Language[])
      .sort((language1, language2) => language1.nativeName.localeCompare(language2.nativeName))
      .map((languageId: Language)  => [
        languageId,
        this.$vmx.language.language.id === languageId.id,
        () => {
          this.$vmx.language.updateLanguage(languageId);
          location.reload();
        },
      ]);
  }

}
