export enum RouterNames {
  ERROR_NOT_FOUND = '404',
  LOGGED_IN_ACCESS_EXPIRED = 'Access Expired',
  LOGGED_IN_BLACKLIST_VIEW = 'Blacklist View',
  LOGGED_IN_COMPANY_LIST_VIEW = 'Company List View',
  LOGGED_IN_COMPANY_SEARCH = 'Company Search',
  LOGGED_IN_COMPANY_VIEW = 'Company View',
  LOGGED_IN_DASHBOARD = 'Dashboard',
  LOGGED_IN_HELP = 'Help',
  LOGGED_IN_LIST_VIEW = 'List View',
  LOGGED_IN_PROCUREMENT_VIEW = 'Procurement View',
  LOGGED_IN_PROJECT_VIEW = 'Project View',
  LOGGED_IN_SEARCH = 'Search',
  LOGGED_IN_USER_SETTINGS = 'User Settings',
  LOGGED_OUT_ABOUT = 'About',
  LOGGED_OUT_BOOK_DEMO = 'Book Demo',
  LOGGED_OUT_CHANGE_PASSWORD = 'Change Password',
  LOGGED_OUT_CONTACT = 'Contact',
  LOGGED_OUT_COOKIE_POLICY = 'Cookie Policy',
  LOGGED_OUT_HOME = 'Home',
  LOGGED_OUT_IMPERSONATE_USER = 'Impersonate User',
  LOGGED_OUT_JURA = 'Jura',
  LOGGED_OUT_PRICES = 'Prices',
  LOGGED_OUT_SERVICES = 'Services',
  LOGGED_OUT_UPDATE_EMAIL = 'Update Email',
  PRINT_PROCUREMENT = 'PrintProcurement',
  PRINT_PROJECT = 'PrintProject',
  INVALID_SESSION = 'Invalid Session',
}