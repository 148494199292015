




























































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '../form-fields/base-input-field.vue';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { CompanyAgent } from '@/interfaces/search/company-agent';
import FormMailTime from '@/components/forms/form-mail-time.vue';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';
import { RouterNames } from '@/util/enums/router-names';
import { User } from '@/interfaces/user';

@Component({
  components: {
    AbstractForm,
    BaseModalContainer,
    FormMailTime,
  },
})
export default class ModalAgentSettings extends Vue {
  @Ref() companyAgentName: BaseInputField;

  companyAgentCopy: CompanyAgent = null;
  users: User[] = [];
  selectedUsers: User[] = this.$vmx.user.colleagues;
  loading = true;
  submitting = false;
  error = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  get buttonRowClasses() {
    return [
      `justify-content-${this.companyAgentCopy.id ? 'between' : 'end'}`,
    ];
  }

  get companyAgent(): CompanyAgent {
    return this.$vmx.modal.companyAgent;
  }
  
  get formIsValid() {
    return this.companyAgentName.validate() && this.selectedUsers.length > 0;
  }

  get modalId(): string {
    return ModalIds.COMPANY_AGENT_SETTINGS_MODAL;
  }


  get validAgentName(): boolean {
    return this.companyAgentCopy.name.trim().length >= 1 && this.companyAgentCopy.name.trim().length <= 100;
  }

  @Watch('selectedUsers')
  selectedUsersChanged() {
    this.companyAgentCopy.paUserIds = this.selectedUsers.map(u => u.id);
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  initializeModal() {
    this.loading = true;
    if (!this.companyAgent) {
      this.hideModal();
      return;
    }
    this.companyAgentCopy = {...this.companyAgent};
    this.setUsers();
    this.loading = false;
  }

  private setUsers() {
    const user = this.$vmx.user.user as User;
    this.users = this.$vmx.user.colleagues;
    if (this.companyAgentCopy.id) {
      this.selectedUsers = this.users.filter(u => this.companyAgentCopy.paUserIds.some(userId => userId === u.id));
    } else {
      this.selectedUsers = [user];
    }
  }

  openCompanyAgentDeleteModal() {
    this.$vmx.modal.openCompanyAgentDeleteModal(this.companyAgent);
  }

  async submit() {
    if (this.formIsValid) {
      try {
        this.submitting = true;

        const newAgent =
          this.companyAgentCopy.id == null
            ? await this.$vmx.companyAgent.postCompanyAgent(this.companyAgentCopy)
            : await this.$vmx.companyAgent.pushCompanyAgent(this.companyAgentCopy);

        if (this.$route.name === RouterNames.LOGGED_IN_COMPANY_SEARCH) {
          this.$vmx.searchCompany.setCompanyAgent(newAgent);
        }
        this.error = false;
        this.submitting = false;
        this.hideModal();
      } catch (error) {
        this.error = true;
        this.submitting = false;
      }
    }
  }
}
