






































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseModalContainer extends Vue {
  // Modal props
  @Prop({ required: true }) modalId: string;
  @Prop({ default: true}) centered: boolean;
  @Prop({ default: true }) fade: boolean;
  @Prop({ default: 'md'}) size: string;
  // Container props
  @Prop({ default: 4 }) paddingX: number;
  @Prop({ default: 4 }) paddingY: number;
  @Prop({ default: 2 }) gutter: number;
  @Prop({ default: true }) showHeader: boolean;
  @Prop({ default: 'trademark'}) headerBgColor: string;
  @Prop({ default: 'white'}) headerTextColor: string;
  @Prop({ default: 'white' }) bodyBgColor: string;
  @Prop({ default: 'dark' }) bodyTextColor: string;
  @Prop({ default: true }) bsBackdrop: 'static' | boolean;
  @Prop({ default: false }) scrollable: boolean;

  get modalClasses() {
    return [
      this.size != 'md' ? `modal-${this.size}` : '',
      this.centered ? 'modal-dialog-centered' : '',
      this.scrollable ? 'modal-dialog-scrollable' : '',
    ];
  }
}
