import { createModule, preserve } from '../preloader';
import { action } from 'vuex-class-component';
import { Product } from '@/interfaces/product';
import { ProductFeature } from '@/interfaces/product-feature';
import { productService } from '@/services/restapi/product-service';

export default class ProductStore extends createModule('product') {
  private productsData = undefined as Product[];
  private productsPromise = undefined as Promise<Product[]>;

  private productFeaturesData = undefined as ProductFeature[];
  private productFeaturesPromise = undefined as Promise<ProductFeature[]>;

  get products(): Product[] {
    return this.productsData ?? [];
  }

  get productFeatures(): ProductFeature[] {
    return this.productFeaturesData?.sort((f1, f2) => (f1.index < f2.index ? -1 : 1));
  }

  @action
  async setProducts(products: Product[]) {
    this.productsData = products;
  }

  @action
  async setProductFeatures(productFeatures: ProductFeature[]) {
    this.productFeaturesData = productFeatures;
  }

  @action
  async fetchProducts(): Promise<Product[]> {
    if (this.productsData != null) {
      return this.productsData;
    }

    if (this.productsPromise == null) {
      this.productsPromise = productService.getProducts();
      this.productsPromise.then((products) => {
        this.productsData = products;
      });
    }

    return this.productsPromise;
  }

  @action
  async fetchProductsFeatures(): Promise<ProductFeature[]> {
    return (
      this.productFeaturesData ??
      preserve(this.productFeaturesPromise, productService.getFeatures, this.setProductFeatures)
    );
  }
}
