


































































import { Component, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import { Agent } from '@/interfaces/agent';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { Modal } from 'bootstrap';
import ModalAgentSettings from '@/components/modals/modal-agent-settings.vue';
import { ModalIds } from '@/util/enums/modal-ids';

@Component({
  components: {
    AbstractForm,
    BaseModalContainer,
    ModalAgentSettings,
  },
})
export default class ModalAgentReplace extends Vue {
  chosenAgent: Agent = null;
  loading = true;
  submitting = false;
  error = false;


  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  get agent(): Agent {
    return this.$vmx.modal.agent;
  }

  get agents(): Agent[] {
    return this.$vmx.agent.agents;
  }

  get modalId(): string {
    return ModalIds.AGENT_REPLACE_MODAL;
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  initializeModal() {
    this.loading = true;
    if (!this.agent) {
      this.hideModal();
      return;
    }
    if (this.agents != null && this.agents.length > 0) {
      this.chosenAgent = this.agents[0];
    }
    this.loading = false;
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  async submit() {
    this.submitting = true;
    try {
      let updatedAgent = {...this.agent};
      updatedAgent.name = this.chosenAgent.name;
      updatedAgent.id = this.chosenAgent.id;
      updatedAgent.mailTime = this.chosenAgent.mailTime;
      updatedAgent.paUserIds = this.chosenAgent.paUserIds;

      this.$vmx.agent.pushAgent(updatedAgent);

      this.error = false;
      this.submitting = false;
      this.$emit('agentSaved', updatedAgent);
      this.hideModal();
    } catch (error) {
      this.error = true;
      this.submitting = false;
    }
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  openSettingsModal() {
    this.$vmx.modal.openAgentSettingsModal(this.agent);
  }
}
