











































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Blacklist } from '@/interfaces/blacklist';
import DropdownUpdates from '@/components/dropdowns/dropdown-updates.vue';
import { File } from '@/interfaces/file';
import { formatUtils } from '@/util/format-utils';
import { globalFunctions } from '@/util/global-functions';
import IconBar from '@/components/containers/icon-bar.vue';
import { List } from '@/interfaces/list';
import moment from 'moment';
import { Procurement } from '@/interfaces/procurement';
import { Project } from '@/interfaces/project';
import { RouterNames } from '@/util/enums/router-names';
import SearchItemsListView from '@/components/search-components/search-items-list-view.vue';

@Component({
  components: {
    DropdownUpdates,
    IconBar,
    SearchItemsListView,
  },
})
export default class SearchItemCard extends Vue {
  @Prop({ required: true }) searchItem: Procurement | Project;
  @Prop({ default: false }) hideProcurements: boolean;
  @Prop({ default: 'components.itemCard.relatedProcurements' }) relatedProcurementText: string;
  @Prop() generalModalId: string;


  get blacklistModalId() {
    return `modal-blacklist-${this.commonId}`;
  }

  get listModalId() {
    return `modal-list-${this.commonId}`;
  }

  get commonId() {
    const isProcurement = globalFunctions.isProcurementOrProcurementReference(this.searchItem);
    return `${isProcurement ? 'proc' : 'proj'}-${this.searchItem.id}`;
  }

  get firstListWithObject(): List {
    if (this.isOnList) {
      return this.$vmx.list.listsWithObject(this.searchItem)[0];
    }
    return null;
  }

  get isOnList(): boolean {
    return this.$vmx.list.isInList(this.searchItem);
  }

  get cardPadding() {
    return [
      this.isProcurement ? 'pe-4' : 'px-4 ps-sm-0',
    ];
  }

  get isBlacklisted(): boolean {
    return this.$vmx.blacklist.isOnUserBlacklist(this.searchItem);
  }

  get blacklist(): Blacklist {
    const isProcurement = globalFunctions.isProcurement(this.searchItem);
    return this.$vmx.blacklist.userBlacklists.find((blacklist) =>
      this.searchItem.id == (isProcurement ? blacklist.procurementId : blacklist.projectId),
    );
  }

  get blacklistHasMoreUsers(): boolean {
    return this.blacklist ? this.blacklist.usersIds.length > 1 : false;
  }

  async deleteBlacklist() {
    try {
      await this.$vmx.blacklist.deleteBlacklist(this.blacklist.id);
      this.error = false;
    } catch (error) {
      this.error = true;
    }
  }

  async removeUserFromBlacklist() {
    if (this.blacklist) {
      const blacklist = this.blacklist;
      try {
        blacklist.usersIds = blacklist.usersIds.filter((userId) => userId !== this.$vmx.user.user.id);
        await this.$vmx.blacklist.pushBlacklist(blacklist);
        this.error = false;
      } catch (error) {
        this.error = true;
      }
    } else {
      this.error = true;
    }
  }

  public nextDeadline: string = null;
  isRemoved = false;
  showProcurements = true;
  error = false;

  created() {
    if (this.hideProcurements) {
      this.showProcurements = false;
    }
    if (globalFunctions.isProcurement(this.searchItem)) {
      if (
        this.isNextDeadline(
          this.searchItem.materialTo,
          this.searchItem.offersTo,
          this.searchItem.inquiryTo,
          this.searchItem.participationTo,
        )
      ) {
        this.nextDeadline =
        formatUtils.formatDate(this.searchItem.materialTo, 'DD.MM.YYYY');
      } else if (
        this.isNextDeadline(
          this.searchItem.offersTo,
          this.searchItem.materialTo,
          this.searchItem.inquiryTo,
          this.searchItem.participationTo,
        )
      ) {
        this.nextDeadline =
        formatUtils.formatDate(this.searchItem.offersTo, 'DD.MM.YYYY');
      } else if (
        this.isNextDeadline(
          this.searchItem.inquiryTo,
          this.searchItem.materialTo,
          this.searchItem.offersTo,
          this.searchItem.participationTo,
        )
      ) {
        this.nextDeadline =
        formatUtils.formatDate(this.searchItem.inquiryTo, 'DD.MM.YYYY');
      } else if (
        this.isNextDeadline(
          this.searchItem.participationTo,
          this.searchItem.materialTo,
          this.searchItem.offersTo,
          this.searchItem.inquiryTo,
        )
      ) {
        this.nextDeadline = formatUtils.formatDate(
          this.searchItem.participationTo,
          'DD.MM.YYYY',
        );
      }
    }
  }

  sanitizedText(s: string): string {
    if (s == null) {
      return '&hellip;';
    }
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.innerText;
  }

  isNextDeadline(date1: string, date2: string, date3: string, date4: string) {
    const now = moment.now();
    if (date1 == null) {
      return false;
    } else if (moment(date1).isBefore(now)) {
      return false;
    } else if (date2 != null && moment(date2).isAfter(now) && moment(date2).isBefore(date1)) {
      return false;
    } else if (date3 != null && moment(date3).isAfter(now) && moment(date3).isBefore(date1)) {
      return false;
    } else if (date4 != null && moment(date4).isAfter(now) && moment(date4).isBefore(date1)) {
      return false;
    }
    return true;
  }

  get hasProcurements(): boolean {
    return !globalFunctions.isProcurement(this.searchItem)
     && this.searchItem.procurements != null
     && this.searchItem.procurements.length > 0;
  }

  getCriteriaNamesOfType(typeId: number): string[] {
    return this.searchItem.criteria?.filter(c => c.typeId === typeId).map(c => c.name) ?? [];
  }

  openPage() {
    this.$router.push({
      name: this.isProcurement ? RouterNames.LOGGED_IN_PROCUREMENT_VIEW : RouterNames.LOGGED_IN_PROJECT_VIEW,
      params: { id: this.searchItem.id.toString() },
    });
  }

  get routerLink() {
    return {
      name: this.isProcurement ? RouterNames.LOGGED_IN_PROCUREMENT_VIEW : RouterNames.LOGGED_IN_PROJECT_VIEW,
      params: { id: this.searchItem.id.toString() },
    };
  }

  get isProcurement(): boolean {
    return globalFunctions.isProcurement(this.searchItem);
  }


  public imageFromFile(file: File, backupImageFormat?: string) {
    if (file) {
      return 'url(' + (file.link ? file.link : this.$vmx.file.fromFile(`${file.code}.${file.extension}`, backupImageFormat)) + ')';
    } else {
      return 'url(' + this.$vmx.file.fromFile(null, backupImageFormat) + ')';
    }
  }

  public formatDate(date: string) {
    return formatUtils.formatDate(date, 'DD.MM.YYYY');
  }
}
