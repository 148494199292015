import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import FormContact from '@/components/forms/form-contact.vue';
import ProductBox from '@/components/general-components/product-box.vue';
import TheHeader from '@/components/headers/the-header.vue';

@Component({
  components: {
    FormContact,
    ProductBox,
    TheHeader,
  },
})
export default class InvalidSessionView extends Vue {
  @Ref() contactContainer: HTMLDivElement;

  scrollToContact() {
    if (this.contactContainer) {
      this.contactContainer.scrollIntoView();
    }
  }
}
