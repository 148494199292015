import 'vuex-class-component';
import { createModule } from '../preloader';
import { File } from '@/interfaces/file';

export default class FileStore extends createModule('file') {
  private fileDomain = process.env.VUE_APP_FILE_DOMAIN || 'https://f.nordiskemedier.dk/';

  get fromFile(): (url: string, backupImageFormat?: string) => string {
    return (url, backupImageFormat) => {
      if (url && !url.endsWith('.') && !url.includes('null')) {
        return this.fileDomain + url;
      } else if (backupImageFormat === '1x1') {
        return this.fileDomain + 'project-agent/image-coming-1x1.png';
      } else if (backupImageFormat === '3x1') {
        return this.fileDomain + 'project-agent/image-coming-3x1.png';
      } else {
        return this.fileDomain + 'project-agent/image-coming-1x2.png';
      }
    };
  }

  get imageFromFile(): (file: File, backupImageFormat?: string) => string {
    return (file, backupImageFormat) => {
      if (file) {
        return file.link ? file.link : this.fromFile(`${file.code}.${file.extension}`, backupImageFormat);
      } else {
        return this.fromFile(undefined, backupImageFormat);
      }
    }
  }
}
