































import { Component, Prop, Vue } from 'vue-property-decorator';
import IconBar from '@/components/containers/icon-bar.vue';
import { List } from '@/interfaces/list';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';

@Component({
  components: {
    IconBar,
  },
})
export default class DropdownIconBar extends Vue {
  @Prop({ required: true }) dataObject: Procurement | Project | ProcurementReference;
  @Prop({ default: 'sm' }) size: string;
  @Prop({ default: 'primary' }) variant: string;
  @Prop({ default: false }) rightAlign: boolean; // TODO

  get firstListWithObject(): List {
    if (this.isOnList) {
      return this.$vmx.list.listsWithObject(this.dataObject)[0];
    }
    return null;
  }

  get isOnList(): boolean {
    return this.$vmx.list.isInList(this.dataObject);
  }

}
