import '@fontsource/lato';
import '@fontsource/lato/300.css';
import '@fontsource/lato/700.css';
import '@fontsource/roboto';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'bootstrap';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-tel-input/dist/vue-tel-input.css';
import * as domainUtils from './util/domain-utils';
import { store, vmx } from './store';
import addToLibrary from './util/font-awesome-import';
import App from './app.vue';
import BootstrapVue from 'bootstrap-vue';
import Component from 'vue-class-component';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import i18n from './localization/i18n';
import importComponents from './util/component-import';
import { Language } from './interfaces/language';
import router from './router';
import { translationService } from './services/restapi/translation-service';
import { User } from './interfaces/user';
import Vue from 'vue';
import vueDebounce from 'vue-debounce';
import VueGtm from '@gtm-support/vue2-gtm';
import VueSlider from 'vue-slider-component';
import VueTelInput from 'vue-tel-input';

Vue.config.productionTip = false;

addToLibrary();

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('VueSlider', VueSlider);

importComponents();

Vue.use(VueTelInput);

Vue.use(BootstrapVue);

Vue.use(vueDebounce);

// TODO: Quick fix løsning, lav mere generel løsning
const gtmCode = domainUtils.domain.tld == 'se'
  ? 'GTM-N347SDF'
  : 'GTM-ML8VKDV'

Vue.use(VueGtm, {
  /* Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects
    [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}},
    {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}],
    Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    */
  // id: 'GTM-ML8VKDV',
  id: gtmCode,
  /* Script can be set to `defer` to speed up page load at the cost of less accurate results
    (in case visitor leaves before script is loaded, which is unlikely but possible).
    Defaults to false, so the script is loaded `async` by default
    */
  defer: false,
  // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  enabled: true,
  // Whether or not display console logs debugs (optional)
  debug: true,
  /* Whether or not to load the GTM Script (Helpful if you are including GTM manually,
    but need the dataLayer functionality in your components) (optional)
    */
  loadScript: true,
  // Pass the router instance to automatically sync with router (optional)
  vueRouter: router,
  // Don't trigger events for specified router names (case insensitive) (optional)
  // ignoredViews: ['homepage'],
  // Whether or not call trackView in Vue.nextTick
  trackOnNextTick: false,
});

Vue.prototype.$vmx = vmx;

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

function splash(language: string, user: User) {
  console.log(`
    _____           _      _    _                         _             
   |  __ |         (_)    | |  | |                       | |            
   | |__) _ __ ___  _  ___| | _| |_ __ _  __ _  ___ _ __ | |_ ___ _ __  
   |  ___| '__/ _ || |/ _ | |/ | __/ _' |/ _' |/ _ | '_ || __/ _ | '_ | 
   | |   | | | (_) | |  __|   <| || (_| | (_| |  __| | | | ||  __| | | |
   |_|   |_|  |___/| ||___|_||_||__|__,_||__, ||___|_| |_||__|___|_| |_|
                  _/ |                    __/ |                         
                 |__/                    |___/                          

    Enviroment: ${process.env.NODE_ENV}
    Domain:     ${domainUtils.domain.name}
    Language:   ${language}
    PaUser Id:  ${user?.id}
    User Id:    ${user?.userId}
    Product:    ${user?.product?.name}
  `)
}

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    const language: Language = this.$vmx.language.language;

    splash(language.nativeName, this.$vmx.user.user)

    const html = document.documentElement;
    html.setAttribute('lang', language.iso6391);

    vmx.session.init();

    (window as any).$vmx = vmx; // For debugging; Use browser terminal to access vmx
    (window as any).$router = this.$router; // For debugging

    translationService.getTranslations(language.id).then((message) => {
      if (message != null) {
        i18n.setLocaleMessage(language.iso6391, message);
      }
    });

    // addEventListener('keyup', (event) => {
    //   if (event.ctrlKey && event.key == 'i') {
    //     if (i18n.messages[language.iso6391] == null) {
    //       translationService.getTranslations(language.id).then((message) => {
    //         i18n.setLocaleMessage(language.iso6391, message);
    //       });
    //     } else {
    //       i18n.setLocaleMessage(language.iso6391, null);
    //     }
    //   }
    // });
  },
  render: (h) => h(App),
}).$mount('#app');
