

































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { Criterion } from '@/interfaces/criterias/criterion';
import { CriterionType } from '@/interfaces/criterias/criterion-type';
import { File } from '@/interfaces/file';
import { globalFunctions } from '@/util/global-functions';
import ModalBookDemo from '@/components/modals/modal-book-demo.vue';
import { Project } from '@/interfaces/project';

@Component({
  components: {
    ModalBookDemo,
  },
})
export default class ProjectShowcaseBox extends Vue {
  @Prop({required: true}) project: Project;
  @Prop() modal: string;
  @Ref() modalBookDemo: ModalBookDemo;
  getFromFile = this.$vmx.file.fromFile;

  getCriteriaOfType(typeId: number): string[] {
    return this.project.criteria?.filter(c => c.typeId === typeId)?.map(c => c.name) ?? [];
  }

  public imageFromFile(file: File) {
    if (file) {
      return file.link ? file.link : this.getFromFile(`${file.code}.${file.extension}`);
    } else {
      return this.getFromFile(null);
    }
  }

  resetSize() {
    globalFunctions.timeout(250).then(() => {
      this.modalBookDemo.resetSize();
    });
  }

  emitClick() {
    this.$emit('clicked', this.project.id);
  }
}
