import { render, staticRenderFns } from "./agent-criteria-quarter-selector.vue?vue&type=template&id=20b4fb79&scoped=true&"
import script from "./agent-criteria-quarter-selector.vue?vue&type=script&lang=ts&"
export * from "./agent-criteria-quarter-selector.vue?vue&type=script&lang=ts&"
import style0 from "./agent-criteria-quarter-selector.vue?vue&type=style&index=0&id=20b4fb79&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b4fb79",
  null
  
)

export default component.exports