
























import { Component, Ref, Vue } from 'vue-property-decorator';
import { companyService } from '@/services/restapi/company-service';
import { File } from '@/interfaces/file';

@Component
export default class TemplateComponent extends Vue {
  @Ref() readonly carousel!: HTMLDivElement;
  @Ref() readonly carouselContainer!: HTMLDivElement;

  private getFromFile = this.$vmx.file.fromFile;

  logos: File[] = [];
  currentIndex = 0;
  windowSize = window.innerWidth
  loading = true;

  skipFactor = 0;


  mounted() {
    window.addEventListener('resize', () => (this.windowSize = window.innerWidth));
    companyService.getCompanyLogosByDomain().then(logos => {
      if (logos) {
        this.logos = logos;
        if (this.logos.length > 0) {
          this.pageScroll();
          this.carousel.addEventListener('scroll', this.scrollEvent);
        }
      }
    }).finally(() => {
      this.loading = false;
    });
  }

  get files() {
    return [...Array(this.logos.length > 8 ? this.logos.length : 8).keys()]
      .map((i) => this.logos[(i + this.skipFactor) % this.logos.length]);
  }

  get divider() {
    if (this.windowSize >= 1400) {
      return 6;
    } else if (this.windowSize >= 992) {
      return 4;
    } else if (this.windowSize >= 768) {
      return 3;
    } else {
      return 2;
    }
  }

  pageScroll() {
    if (this.$route.name === 'Home') {
      this.carousel.scrollBy(1, 0)
      setTimeout(this.pageScroll, 15);
    }
  }

  scrollEvent() {
    if (this.$route.name === 'Home') {
      if (this.carouselContainer) {
        const width = this.carouselContainer.clientWidth;
        if (this.carousel.scrollLeft >= width / this.divider) {
          this.skipFactor = this.skipFactor + 1;
          this.carousel.scrollLeft = 0;
        }
      }
    }
  }

  public imageFromFile(file: File) {
    if (file) {
      return file.link ? file.link : this.getFromFile(`${file.code}.${file.extension}`);
    } else {
      return this.getFromFile(undefined);
    }
  }
}
