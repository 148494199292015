import { render, staticRenderFns } from "./procurement-view.vue?vue&type=template&id=7016a600&scoped=true&"
import script from "./procurement-view.ts?vue&type=script&lang=ts&"
export * from "./procurement-view.ts?vue&type=script&lang=ts&"
import style0 from "./procurement-view.scss?vue&type=style&index=0&id=7016a600&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7016a600",
  null
  
)

export default component.exports