













































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { Blacklist } from '@/interfaces/blacklist';
import { blacklistService } from '@/services/restapi/blacklist-service';
import { formatUtils } from '@/util/format-utils';
import { globalFunctions } from '@/util/global-functions';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';
import { User } from '@/interfaces/user';

@Component({
  components: {
    BaseModalContainer,
  },
})
export default class ModalBlacklistSettings extends Vue {
  blacklist: Blacklist = null;
  users: User[] = [];
  selectedUsers: User[] = [];

  submitting = false;
  error = false;
  loading = true;

  mounted() {
    this.setEventListeners();
  }

  activated() {
    this.setEventListeners();
  }

  setEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  initializeModal() {
    this.loading = true;
    if (!this.dataObject) {
      this.hideModal();
      return;
    }
    this.checkLoaded();
  }

  @Watch('$vmx.user.colleagues')
  @Watch('$vmx.user.user')
  checkLoaded() {
    const initLoading = !this.dataObject || this.colleagues == null || this.user == null;
    if (!initLoading) {
      this.blacklist = this.blacklistFromStore;
      this.users = this.colleagues;

      this.selectedUsers = this.users?.filter(
        (colleage) => this.blacklist?.usersIds?.some((userId) => userId === colleage.id),
      );
      this.selectedUsers = this.selectedUsers.some((user) => user.id === this.user.id)
        ? this.selectedUsers
        : this.selectedUsers.concat(this.user);
      this.loading = false;
    }
  }

  get modalId(): string {
    return ModalIds.BLACKLIST_SETTINGS_MODAL;
  }

  get dataObject(): Project | Procurement | ProcurementReference {
    return this.$vmx.modal.blacklistObject;
  }

  get user(): User {
    return this.$vmx.user.user;
  }

  get colleagues(): User[] {
    return this.$vmx.user.colleagues;
  }

  get selectedUsersFromStore(): User[] {
    const selectedUsers = this.users?.filter(
      (colleage) => this.blacklist?.usersIds?.some((userId) => userId === colleage.id),
    );

    return selectedUsers.some((user) => user.id === this.user.id)
      ? selectedUsers
      : selectedUsers.concat(this.user);
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  get blacklistFromStore(): Blacklist {
    const isProcurement = globalFunctions.isProcurementOrProcurementReference(this.dataObject);
    return this.$vmx.blacklist.blacklists
      ?.filter((blacklist: Blacklist) =>
        isProcurement || (blacklist?.projectId === this.dataObject.id),
      )
      ?.filter((blacklist: Blacklist) =>
        !isProcurement || (blacklist?.procurementId === this.dataObject.id),
      )
      ?.pop() ?? this.createNewBlacklist(isProcurement);
  }

  get isUserOnBlacklist(): boolean {
    return this.blacklist != null
      && (this.blacklist?.usersIds.some((userId) => userId == this.$vmx.user.user.id) ?? false);
  }

  private createNewBlacklist(isProcurement: boolean): Blacklist {
    const newBlacklist: Blacklist = {
      id: null,
      created: formatUtils.formatDate(new Date(), 'YYYY-MM-DD HH:mm'),
      usersIds: [this.$vmx.user.user.id],
      userIsOnList: true,
    }
    if (isProcurement) {
      newBlacklist.procurementId = this.dataObject.id;
    } else {
      newBlacklist.projectId = this.dataObject.id;
    }
    return newBlacklist;
  }

  async persistBlacklist() {
    this.submitting = true;
    const isProcurement = globalFunctions.isProcurementOrProcurementReference(this.dataObject);
    const blCopy = this.blacklist && this.blacklist.id ? {...this.blacklist} : this.createNewBlacklist(isProcurement);
    blCopy.usersIds = this.selectedUsers.map((user) => user.id);
    let newBlacklist: Blacklist = null;
    try {
      if (blCopy.id) {
        newBlacklist = await blacklistService.editBlacklist(blCopy);
      } else {
        newBlacklist = await blacklistService.createBlacklist(blCopy);
      }
      if (newBlacklist != null) {
        this.error = false;
        this.$vmx.blacklist.pushBlacklist(newBlacklist);
        this.hideModal();
      } else {
        this.error = true;
      }
      this.submitting = false;
    } catch (error) {
      this.error = true;
      this.submitting = false;
    }

  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }
}
