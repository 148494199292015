





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { dateUtils } from '@/util/date-utils';
import { formatUtils } from '@/util/format-utils';
import moment from 'moment';
import { RawLocation } from 'vue-router';
import { RouterNames } from '@/util/enums/router-names';
import { Update } from '@/interfaces/update';
import { updateService } from '@/services/restapi/update-service';

@Component
export default class UpdateList extends Vue {
  @Prop({ required: true }) updates: Update[];
  @Prop({ default: false }) blurred: boolean;
  @Prop({ default: 0 }) amount: number; // 0 specify unlimited amount
  @Prop({ default: '' }) scrollSize: string;

  // @Prop() itemId: number;
  // @Prop({ required: true }) isProject: boolean;
  // @Prop({ default: null }) onClick?: (type: string) => void;

  get updateClasses() {
    return [
      this.blurred ? 'blurred' : '',
      // this.clickable ? 'clickable' : '',
    ];
  }

  get isScrollable() {
    return this.scrollSize.length != 0
  }

  // get clickable(): boolean {
  //   return this.onClick !== null
  //   || (
  //     this.$route.name !== RouterNames.LOGGED_IN_PROJECT_VIEW
  //     && this.$route.name !== RouterNames.LOGGED_IN_PROCUREMENT_VIEW
  //   )
  //   || (
  //     this.$route.name == RouterNames.LOGGED_IN_PROJECT_VIEW && !this.isProject
  //   )
  // }

  get loading() {
    return this.updates == null || this.updates.length == 0;
    // const bla = this.getLoadedUpdates;
    // return bla == null || bla.length == 0;
  }

  get getLoadedUpdates(): { updates: Update[], date: Date }[] {
    if (this.updates != null) {
      return this.convertUpdates(this.updates)
    }

    return null;
  }

  public formatDate(date: Date): string {
    const day = formatUtils.formatDate(date, 'DD.MM.YYYY');
    const daysSince = moment().diff(moment(date), 'days');
    const dayString = `${day} (${this.$tc('components.activityLog.days', daysSince, [daysSince])})`;

    return dayString;
  }

  private convertUpdates(updates: Update[]): { updates: Update[], date: Date }[] {
    const updatesByDay: { updates: Update[], date: Date }[] = [];

    updates.forEach(update => {
      if (!update.text) return;

      const date: Date = new Date(update.created);

      const index = updatesByDay.findIndex(x => x.date.getFullYear() === date.getFullYear()
        && x.date.getMonth() == date.getMonth()
        && x.date.getDate() == date.getDate(),
      );
      console.log(`index: ${index}`);

      if (index >= 0) {
        updatesByDay[index].updates.push(update);
      } else {
        updatesByDay.push({
          updates: [update],
          date: date,
        });
      }
    });

    updatesByDay.sort((a, b) => a.date < b.date ? 1 : -1);
    updatesByDay.forEach(daily => daily.updates.sort((a, b) => dateUtils.isDateStringAfter(a.created, b.created) ? -1 : 1))

    if (updatesByDay.length > this.amount && this.amount != 0) {
      return updatesByDay.slice(0, this.amount);
    } else {
      return updatesByDay;
    }
  }

  getLink(update: Update): RawLocation {
    if (this.blurred) return null;

    return {
      name: update.projectId != null ? RouterNames.LOGGED_IN_PROJECT_VIEW : RouterNames.LOGGED_IN_PROCUREMENT_VIEW,
      params: { id: (update.projectId ?? update.procurementId).toString() },
      hash: update.scrollId ? `#${update.scrollId}` : '',
    }
  }
}
