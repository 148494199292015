import { action } from 'vuex-class-component';
import { Agent } from '@/interfaces/agent';
import { agentService } from '@/services/restapi/agent-service';
import { createModule } from '../preloader';
import { vmx } from '..';

export default class AgentStore extends createModule('agent') {
  private agentsData = null as Agent[];
  private agentsPromise = null as Promise<Agent[]>;

  get agents(): Agent[] {
    return this.agentsData?.sort((agent1, agent2) => agent1.id - agent2.id);
  }

  get agentsLoaded(): boolean {
    return this.agents != null;
  }

  @action
  async fetchAgents(): Promise<Agent[]> {
    if (this.agentsData != null) {
      return this.agentsData;
    }

    if (this.agentsPromise != null) {
      return this.agentsPromise;
    }

    this.agentsPromise = agentService.getAgents();
    this.agentsPromise.then(this.setAgents);

    return this.agentsPromise;
  }

  @action
  async pushAgent(agent: Agent): Promise<Agent> {
    agent.name = agent.name.trim();

    const updatedAgent =
      agent.id == null ? await agentService.postAgent(agent) : await agentService.putAgent(agent);

    this.agentsPromise = agentService.getAgents();
    this.agentsData = await this.agentsPromise;

    return updatedAgent;
  }

  @action
  async deleteAgent(agent: Agent): Promise<void> {
    await agentService.deleteAgent(agent.id);

    if (vmx.search.agent.id == agent.id) {
      vmx.search.clearAgent();
    }

    await agentService.getAgents().then(this.setAgents);
  }

  @action
  async fetchAgentCount(agent: Agent, maxDaysOld?: number): Promise<number> {
    return agentService.searchCount(agent, maxDaysOld);
  }

  @action
  private async setAgents(agents: Agent[]) {
    this.agentsData = agents.sort((a1, a2) => a1.name.localeCompare(a2.name));
  }
}
