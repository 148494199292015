import { render, staticRenderFns } from "./modal-role-info.vue?vue&type=template&id=89c3dcb2&scoped=true&"
import script from "./modal-role-info.vue?vue&type=script&lang=ts&"
export * from "./modal-role-info.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89c3dcb2",
  null
  
)

export default component.exports