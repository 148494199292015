






































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component
export default class DropdownOrderBy extends Vue {
  @Prop({default: 'primary'}) variant: string;
  @Prop({default: ''}) size: string;
  @VModel() orderBy: string;
  @Prop({required: true}) options: Object;
}
