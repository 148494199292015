


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Update } from '@/interfaces/update';
import UpdateList from '@/components/general-components/update-list.vue';
import { updateService } from '@/services/restapi/update-service';

@Component({
  components: {
    UpdateList,
  },
})
export default class DropdownUpdates extends Vue {
  @Prop({ default: 'updates-dropdown' }) id: string;
  @Prop({ default: 'sm' }) size: string;
  @Prop({ default: 'sm' }) iconSize: string;
  @Prop({ default: 'bell' }) iconName: string;
  @Prop({ default: 'primary' }) variant: string;
  @Prop({ default: false }) showBadge: boolean;
  @Prop() label: string;
  @Prop({ required: true }) itemId: number;
  @Prop({ required: true }) isProject: boolean;
  @Prop({ default: null }) onClick?: (type: string) => void;

  updates: Update[] = [];
  fetching = false;

  loadUpdates() {
    if (this.fetching) {
      return;
    }
    this.fetching = true;

    if (this.updates) {
      if (this.isProject) {
        updateService.getUpdatesByProjectId(this.itemId).then(updates => {
          this.updates = updates;
        });
      } else {
        updateService.getUpdatesByProcurementId(this.itemId).then(updates => {
          this.updates = updates;
        });
      }
    }
  }
}
