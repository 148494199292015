








































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Company } from '@/interfaces/company';
import { CompanyList } from '@/interfaces/company-list';
import { companyListService } from '@/services/restapi/company-list-service';
import TableCompanyList from '@/components/company-list-components/table-company-list.vue';

@Component({
  components: {
    TableCompanyList,
  },
})
export default class TableCompanyListsAll extends Vue {

  companyLists: Array<{ list: CompanyList, companies: Company[] }> = [];

  loading = false;
  error = false;

  async mounted() {
    this.fillCompanyLists();
  }

  @Watch('$vmx.companyList.companyLists')
  @Watch('$vmx.companyList.companyListsLoaded')
  async fillCompanyLists() {
    if (!this.loading && this.$vmx.companyList.companyListsLoaded) {
      this.loading = true;
      this.companyLists = [];
      for (const companyList of this.$vmx.companyList.companyLists) {
        try {
          const companies = await companyListService.getCompanyListItems(companyList.id);
          this.companyLists.push({
            list: companyList,
            companies,
          });
        } catch (error) {
          this.error = true;
          this.companyLists = [];
          break;
        }
      }
      this.loading = false;
    }
  }

  openCompanyListSettingsModal(companyList: CompanyList) {
    this.$vmx.modal.openCompayListSettingsModal({ companyList: companyList });
  }
}
