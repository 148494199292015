














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AgentCountField extends Vue {
  @Prop({ default: null }) value: number;
}
