






























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseContainer extends Vue {
  @Prop({ default: true }) showHeader: boolean;
  @Prop({ default: true }) showBody: boolean;
  @Prop({ default: 'trademark'}) headerBgColor: string;
  @Prop({ default: 'white'}) headerTextColor: string;
  @Prop({ default: 'white' }) bodyBgColor: string;
  @Prop({ default: 'dark' }) bodyTextColor: string;
  @Prop({ default: 4 }) paddingX: number;
  @Prop() headerPaddingX: number;
  @Prop({ default: 4 }) paddingY: number;
  @Prop({ default: false }) noPadding: boolean;
  @Prop({ default: 2 }) gutter: number;
  @Prop({ default: false }) fullHeight: boolean;
  @Prop({ default: 0 }) rounded: number;
  @Prop() bodyStyleClasses: string;

  get headerClasses() {
    return [
      `bg-${this.headerBgColor}`,
      `text-${this.headerTextColor}`,
      this.noPadding ? '' : this.headerPaddingX ? `px-${this.headerPaddingX}` : `px-${this.paddingX}`,
      this.showBody ? `mb-${this.gutter}` : '',
      // `py-${this.headerPaddingY}`,
    ];
  }

  get bodyClasses() {
    return [
      `bg-${this.bodyBgColor}`,
      `text-${this.bodyTextColor}`,
      this.noPadding ? '' : `px-${this.paddingX}`,
      this.noPadding ? '' : `py-${this.paddingY}`,
      this.rounded ? `rounded rounded-${this.rounded}` : '',
      this.bodyStyleClasses,
    ];
  }

}
