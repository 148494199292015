














































































import * as domainUtils from '@/util/domain-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LanguageIds } from '@/util/enums/language-ids';
import { Product } from '@/interfaces/product';
import { ProductFeature } from '@/interfaces/product-feature';

@Component
export default class ProductBox extends Vue {
  @Prop({ required: true }) product: Product;
  @Prop() routerParams: Object;
  @Prop() bsDismiss: string;
  
  selectedIndices: number[] = [];

  // formatPrice(price: string) {
  //   if (this.$vmx.language.language.id === LanguageIds.danish) {
  //     price = price.replace('kr.', ',-');
  //   }
  //   return price;
  // }

  get features() {
    return this.$vmx.product.productFeatures ?? [];
  }

  get sortedDomains() {
    const domainId = this.$vmx.domain.domain.id;
    return [...this.product.domains].sort((a, b) => a.id === domainId ? -1 : b.id === domainId ? 1 : 0)
  }

  formatPrice(price: number) {
    let formattedPrice = this.$n(price, 'currency', domainUtils.domain.numberFormat);
    if (this.$vmx.language.language.id === LanguageIds.danish) {
      formattedPrice = formattedPrice.replace(' kr.', ',-');
    }
    return formattedPrice;
  }

  buttonClicked() {
    this.$emit('buttonClicked', this.product.id);
  }

  getDomainSVG(tld: string) {
    return {
      backgroundImage: `url('${require('@/assets/flags/' + tld + '.svg')}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  }

  productHasFeature(feature: ProductFeature): boolean {
    return this.product.features.some((f) => f.id === feature.id);
  }

  selectFeature(index: number) {
    const existingIndex = this.selectedIndices.findIndex(x => x === index);
    if (existingIndex >= 0) {
      this.selectedIndices.splice(existingIndex, 1);
    } else {
      this.selectedIndices.push(index);
    }
  }
}
