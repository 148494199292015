import { createModule, preserve } from '../preloader';
import { action } from 'vuex-class-component';
import { ProcurementPhase } from '@/interfaces/criterias/procurement-phase';
import { procurementPhaseService } from '@/services/restapi/procurement-phase-service';

export default class ProcurementPhaseStore extends createModule('procurementPhase') {
  private procurementPhasesData = null as ProcurementPhase[];
  private procurementPhasesPromise = null as Promise<ProcurementPhase[]>;

  get procurementPhases() {
    return this.procurementPhasesData;
  }

  @action
  async setProcurementPhases(procurementPhases: ProcurementPhase[]) {
    this.procurementPhasesData = procurementPhases;
  }

  @action
  async fetchProcurementPhases() {
    return (
      this.procurementPhasesData ??
      preserve(
        this.procurementPhasesPromise,
        procurementPhaseService.getProcurementPhases,
        this.setProcurementPhases,
      )
    );
  }
}
