

























































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { formatUtils } from '@/util/format-utils';

@Component
export default class InputDatetime extends Vue {
  @Prop({ default: false }) required: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: 'trademark-25'}) background: string;
  @Prop({ default: 'white '}) textColor: string;
  @Prop() gutter: number;
  @Prop({ default: true }) useDatePicker: boolean;
  @Prop({ default: true }) useTimePicker: boolean;
  @Prop({ default: 9 }) minHour: number;
  @Prop({ default: 16 }) maxHour: number;
  @Prop({ default: 15 }) minuteInterval: number;
  @Ref() dateTimePicker: HTMLDivElement;

  width = window.innerWidth;

  date: string = null;
  hour: string = null;
  minute = '00';

  mounted() {
    if (this.dateTimePicker && this.dateTimePicker.clientWidth > 0) {
      this.width = this.dateTimePicker.clientWidth;
    }
    this.date = formatUtils.formatDate(this.tomorrow, 'YYYY-MM-DD');
    this.hour = (this.minHour < 10 ? '0' : '') + this.minHour.toString();
    this.$emit('inputDate', this.date);
    this.emitDateTime();
    window.addEventListener('resize', this.resizeHandler);
  }

  resizeHandler() {
    if (this.dateTimePicker) {
      this.width = this.dateTimePicker.clientWidth;
    }
  }

  get rowClasses() {
    return [
      this.gutter ? `gx-${this.gutter}` : '',
    ];
  }

  get pickerClasses() {
    return [
      `bg-${this.background}`,
      this.disabled ? 'picker-disabled' : '',
    ];
  }

  get dateColumnClasses() {
    return [
      this.useTimePicker && this.width >= 582 ? 'col-6' : 'col-12',
    ];
  }

  get timeColumnClasses() {
    return [
      this.useTimePicker && this.width >= 582 ? 'col-6' : 'col-12',
    ];
  }

  get timeTextClasses() {
    return [
      `text-${this.textColor}`,

    ]
  }

  get tomorrow(): Date {
    const result = new Date();
    result.setDate(result.getDate() + 1);
    while (result.getDay() == 0 || result.getDay() == 6) {
      result.setDate(result.getDate() + 1);
    }
    return result;
  }

  get hourRange(): string[] {
    const range: string[] = [];
    for (let i = this.minHour % 24; i < this.maxHour % 24; i++) {
      range.push(i < 10 ? `0${i}` : i.toString());
    }
    return range;
  }

  get minuteRange(): string[] {
    const range: string[] = [];
    for (let i = 0; i < 60; i = i + this.minuteInterval) {
      range.push(i < 10 ? `0${i}` : i.toString());
    }
    return range;
  }

  get inputDate() {
    return this.date;
  }

  set inputDate(val: string) {
    this.date = val;
    this.emitDateTime();
  }

  get selectedHour() {
    return this.hour;
  }

  set selectedHour(hour: string) {
    this.hour = hour;
    this.emitDateTime();
  }

  get selectedMinute() {
    return this.minute;
  }

  set selectedMinute(minute: string) {
    this.minute = minute;
    this.emitDateTime();
  }

  emitDateTime() {
    if (this.useDatePicker) {
      this.$emit('inputDate', this.date);
    }
    if (this.useTimePicker) {
      this.$emit('inputTime', `${this.hour}:${this.minute}`);
    }
    if (this.useDatePicker && this.useTimePicker) {
      this.$emit('inputDateTime', `${this.date} ${this.hour}:${this.minute}`);
    }
  }

  isValidDate(): boolean {
    const val = this.date != null && this.date.length > 0;
    return val;
  }

  /**
   * Disable weekends (Sunday = `0`, Saturday = `6`).
   * Return `true` if the date should be disabled
   */
  disabledDates(ymd: string, date: Date): boolean {
    const weekday = date.getDay()
    return weekday === 0 || weekday === 6
  }

  public setSize() {
    this.resizeHandler();
  }

}
