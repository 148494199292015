import * as domainUtils from '@/util/domain-utils';
import { Component, Vue } from 'vue-property-decorator';
import FormContact from '@/components/forms/form-contact.vue';
import LogoCarousel from '@/components/home-components/logo-carousel.vue';
import ModalBookDemo from '@/components/modals/modal-book-demo.vue';
import { Product } from '@/interfaces/product';
import ProductBox from '@/components/general-components/product-box.vue';
import ProductTable from '@/components/general-components/product-table.vue';
import { Project } from '@/interfaces/project';
import { projectService } from '@/services/restapi/project-service';
import ProjectShowcaseBox from '@/components/home-components/project-showcase-box.vue';
import Quotes from '@/components/home-components/quotes.vue';
import { Ref } from 'vue-slider-component/node_modules/vue-property-decorator';
import TheHeader from '@/components/headers/the-header.vue';
import { userService } from '@/services/restapi/user-service';

@Component({
  components: {
    FormContact,
    LogoCarousel,
    ModalBookDemo,
    ProductBox,
    ProductTable,
    ProjectShowcaseBox,
    Quotes,
    TheHeader,
  },
})
export default class HomeView extends Vue {
  @Ref() formElement: HTMLFormElement;
  @Ref() modalBookDemo: ModalBookDemo;
  projects: Project[] = [];
  products: Product[] = [];
  retries = 0;

  created() {
    projectService.getNewestProjects(4).then((projects: Project[]) => {
      this.projects = projects;
    });
  }

  mounted() {
    this.getProducts();
  }

  getProducts() {
    this.products = this.$vmx.product.products;
    if (this.products.length < 1 && this.retries <= 50) {
      setTimeout(() => {
        this.getProducts();
        this.retries++;
      }, 100);
    }
  }

  get videoFromFile() {
    const video = domainUtils.domain.fileId;
    if (video) {
      return video.link ? video.link : this.$vmx.file.fromFile(`${video.code}.${video.extension}`);
    }
    return null;
  }

  scrollToContact() {
    const boxTop = document.querySelector('#contact').getBoundingClientRect().top;
    const bodyTop = document.documentElement.scrollTop;

    window.scrollTo(0, boxTop + bodyTop);
  }

  getBackground(fileName: string) {
    return 'url(' + this.getFromFile('backgrounds/' + fileName) + ')';
  }

  getFromFile(fileName: string) {
    return this.$vmx.file.fromFile('project-agent/' + fileName);
  }

  async writeUsSubmit(name: string, email: string, phone: string): Promise<void> {
    await userService.sendRequestSubscription(name, phone, email);
  }

  resizeModal() {
    this.modalBookDemo.resetSize();
  }

  fuckautoplay() {
    if (process.env.NODE_ENV === 'development') {
      return false;
    }
    return true;
  }
}
