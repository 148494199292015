import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Company } from '@/interfaces/company';
import { CompanyList } from '@/interfaces/company-list';

const api = '/company-lists';

export class CompanyListService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getCompanyLists = async (): Promise<CompanyList[]> => {
    const response = await this.get<CompanyList[]>(api);
    return this.getResponse(response);
  }

  public createCompanyList = async (companyList: CompanyList): Promise<CompanyList> => {
    const response = await this.post<CompanyList>(api, companyList);
    return this.getResponse(response);
  }

  public updateCompanyList = async (companyList: CompanyList): Promise<CompanyList> => {
    const response = await this.put<CompanyList>(api, companyList);
    return this.getResponse(response);
  }

  public deleteCompanyList = async (id: number): Promise<void> => {
    const response = await this.delete<void>(api + '/' + id);
    return this.getResponse(response);
  };

  public getCompanyListItems = async (id: number): Promise<Company[]> => {
    const response = await this.get<Company[]>(`${api}/items/${id}`);
    return this.getResponse(response);
  }
}

export const companyListService = new CompanyListService(apiConfig);