





































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseDropDown extends Vue {
  @VModel({ required: true }) model: string;
  @Prop({ required: true }) options: string[]
  @Prop({ default: null }) show: (val: string) => string;

  @Prop({ required: true }) id: number;

  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: ''}) size: string;
  @Prop({ default: 'roboto rounded' }) buttonStyleClasses: string;
  @Prop({ default: 'outline-primary' }) variant: string;
  @Prop({ default: '' }) ulClass: string;
  @Prop() paddingX: string;
  @Prop() paddingY: string;

  setOption(option: string) {
    this.model = option;
  }
}
