



























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Tooltip } from 'bootstrap';

let uid = 0;

@Component
export default class BaseButtonIcon extends Vue {
  @Prop() buttonId: string;
  @Prop() routeParams: Object;
  @Prop() styleClasses: string;
  @Prop({ default: 'primary' }) variant: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ required: true}) iconName: string;
  @Prop({ default: 'fal'}) type: string;
  @Prop({ default: 'md'}) size: string;
  @Prop({ default: 'xs'}) iconSize: string;
  @Prop({ default: false }) rounded: boolean;
  @Prop() label: string;
  @Prop() toolTipText: string;
  @Prop({ default: 'top'}) tooltipPlacement: 'auto' | 'top' | 'bottom' | 'left' | 'right';
  @Prop({ default: false }) showBadge: boolean;
  @Prop() badgeText: string;
  @Prop({ default: 'danger'}) badgeColor: string;
  @Prop({ default: null }) onClickCb: () => Promise<void>;
  @Prop() bsToggle: string;
  @Prop() bsDismiss: string;
  @Prop() bsTarget: string;
  @Prop() bsAutoClose: string;
  @Prop({ default: false }) isDropup: boolean;
  public isClicked = false;

  mounted() {
    this.setTooltip();
  }

  activated() {
    this.setTooltip();
  }

  setTooltip() {
    this.$nextTick(() => {
      const popperElement = document.getElementById(`tooltip-${this.uid}`);
      if (popperElement) {
        new Tooltip(popperElement, {
          placement: this.tooltipPlacement,
          trigger: 'hover',
        });
      }
    })
  }

  get uid(): string {
    if (!this.buttonId) {
      uid += 1;
      return 'icon-button-' + uid;
    }
    return this.buttonId;
  }

  get styleClassObj() {
    return [
      `icon-class-${this.size}`,
      !this.rounded ? 'circle' : '',
      `btn-${this.variant}`,
      this.styleClasses,
      // this.bsToggle === 'dropdown' ? 'dropdown-toggle' : '',
    ];
  }

  get dropdownClasses() {
    return {
      dropdown: this.bsToggle === 'dropdown' && !this.isDropup,
      dropup: this.bsToggle === 'dropdown' && this.isDropup,
    }
  }

  get badgeClasses() {
    return [
      'position-absolute',
      this.rounded ? ['top-0', 'start-100'] : 'position-90',
      'translate-middle',
      'badge',
      this.badgeText ? '' : 'p-2',
      this.badgeText ? 'rounded-pill' : 'rounded-circle',
      `bg-${this.badgeColor}`,
      this.sameColor ? 'border' : '',
      this.sameColor ? 'border-white' : '',
    ];
  }

  get sameColor() {
    return this.variant === this.badgeColor;
  }

  onClick() {
    if (!this.isClicked) {
      Tooltip.getInstance(document.getElementById(`tooltip-${this.uid}`))?.hide();
      this.isClicked = true;
      this.$emit('click');
      if (this.onClickCb != null) {
        this.onClickCb()?.finally(() => this.isClicked = false);
      } else {
        this.isClicked = false;
      }
    }
  }

}
