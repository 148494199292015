import { createModule, preserve } from '../preloader';
import { action } from 'vuex-class-component';
import { ProcurementType } from '@/interfaces/criterias/procurement-type';
import { procurementTypeService } from '@/services/restapi/procurement-type-service';

export default class ProcurementTypeStore extends createModule('procurementType') {
  private procurementTypesData = null as ProcurementType[];
  private procurementTypesPromise = null as Promise<ProcurementType[]>;

  get procurementTypes() {
    return this.procurementTypesData;
  }

  @action
  async setProcurementPhases(procurementTypes: ProcurementType[]) {
    this.procurementTypesData = procurementTypes;
  }

  @action
  async fetchProcurementTypes() {
    return (
      this.procurementTypesData ??
      preserve(
        this.procurementTypesPromise,
        procurementTypeService.getProcurementTypes,
        this.setProcurementPhases,
      )
    );
  }
}
