import * as domainUtils from '@/util/domain-utils';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { AnnouncementTypes } from '@/util/enums/announcement-types';
import { CompanyRole } from '@/interfaces/company-role';
import ContainerDateRow from '@/components/containers/container-date-row.vue';
import { Criterion } from '@/interfaces/criterias/criterion';
import Error401 from '@/components/error-components/error-401.vue';
import Error404 from '@/components/error-components/error-404.vue';
import { formatUtils } from '@/util/format-utils';
import { globalFunctions } from '@/util/global-functions';
import IconBar from '@/components/containers/icon-bar.vue';
import { List } from '@/interfaces/list';
import { Modal } from 'bootstrap';
import ModalAnnouncementInfo from '@/components/modals/modal-announcement-info.vue';
import ModalNoteDelete from '@/components/modals/modal-note-delete.vue';
import ModalNoteEdit from '@/components/modals/modal-note-edit.vue';
import ModalPaywall from '@/components/modals/modal-paywall.vue';
import ModalRoleInfo from '@/components/modals/modal-role-info.vue';
import { Note } from '@/interfaces/note';
import NoteList from '@/components/general-components/note-list.vue';
import PhaseTimeline from '@/components/phase-timeline.vue';
import { Procurement } from '@/interfaces/procurement';
import { procurementService } from '@/services/restapi/procurement-service';
import { ProcurementUpdate } from '@/interfaces/procurement-update';
import { Project } from '@/interfaces/project';
import ProjectReferenceCard from '@/components/cards/project-reference-card.vue';
import { projectService } from '@/services/restapi/project-service';
import { RouterNames } from '@/util/enums/router-names';
import { Update } from '@/interfaces/update';
import UpdateList from '@/components/general-components/update-list.vue';
import { updateService } from '@/services/restapi/update-service';
import { User } from '@/interfaces/user';

@Component({
  components: {
    ContainerDateRow,
    Error401,
    Error404,
    IconBar,
    ModalAnnouncementInfo,
    ModalNoteEdit,
    ModalNoteDelete,
    ModalRoleInfo,
    ModalPaywall,
    NoteList,
    PhaseTimeline,
    ProjectReferenceCard,
    UpdateList,
  },
})
export default class ProcurementView extends Vue {
  @Prop() private id: number;
  @Prop({ default: false }) printOnLoad: boolean;
  @Ref() summary: HTMLElement;
  @Ref() description: HTMLElement;

  loading = true;
  errorCode: number = null;
  procurement: Procurement = null;
  project: Project = null;
  getFromFile = this.$vmx.file.fromFile;
  windowSize = window.innerWidth;
  sentTips: boolean[] = [];
  tipHasBeenSent: boolean = null;
  showSummaryFoldingButton = false;
  showDescriptionFoldingButton = false;
  summaryCollapsed = false;
  descriptionCollapsed = false;
  winnersCollapsed = true;
  selectedCompanyRole: CompanyRole = null;
  selectedAnnouncement: ProcurementUpdate = null;
  selectedNote: Note = null;
  updates: Update[] = null;

  currentHash: string = null;


  mounted() {
    updateService.getUpdatesByProcurementId(this.id).then(updates => this.updates = updates) 

    window.scrollTo(0, 0);
    window.addEventListener('resize', this.resizeHandler);
    procurementService.getProcurementById(this.id).then(
      (proc) => {
        if ((proc as Procurement).id !== undefined) {
          this.procurement = proc as Procurement;
          if (this.procurement.summary) {
            this.formatSummary();
          }
          if (this.procurement && this.procurement.project && this.procurement.project.id !== 0) {
            projectService
              .getProjectById(this.procurement.project.id)
              .then((proj) => {
                this.project = proj;
                this.initSetup();
              })
              .catch(() => {
                this.procurement.project = null;
                this.initSetup();
              });
          } else {
            this.initSetup();
          }
        }
      },
      (error) => {
        this.errorCode = error;
        if (error == 404) {
          this.$router.push({ name: RouterNames.ERROR_NOT_FOUND });
        } else if (error == 401) {
          this.renderContent();
        }
      },
    );
  }

  private initSetup() {
    if (
      this.procurement &&
      this.procurement.procurementUpdates &&
      this.procurement.procurementUpdates.length > 0
    ) {
      this.procurement.procurementUpdates.sort((a, b) => (a.date < b.date ? 1 : -1));
      document.title = this.procurement.name;
    }
    this.loading = false;
    this.renderContent();
  }

  private renderContent() {
    this.$nextTick(() => {
      if (this.printOnLoad) {
        // We wait 0.5 sec for the page to render and then open print
        globalFunctions.timeout(500).then(() => window.print());
      }
      globalFunctions.timeout(50).then(() => {
        // Wait a very short while, for the summary div to be loaded correctly
        if (this.windowSize < 768) {
          this.summaryCollapsed = true;
          this.descriptionCollapsed = true;
          this.winnersCollapsed = true;
        }
        this.$nextTick(() => {
          this.showSummaryFoldingButton =
            this.summary?.offsetHeight < this.summary?.scrollHeight && this.windowSize < 768;
          this.showDescriptionFoldingButton =
            this.description?.offsetHeight < this.description?.scrollHeight &&
            this.windowSize < 768;
        });
      });
      this.setHash();
    });
  }

  get blacklistModalId() {
    return `modal-blacklist-${this.commonId}`;
  }

  get listModalId() {
    return `modal-list-${this.commonId}`;
  }

  get noteId() {
    return 'note-list';
  }

  get commonId() {
    return `proc-${this.procurement.id}`;
  }

  get firstListWithObject(): List {
    if (this.isOnList) {
      return this.$vmx.list.listsWithObject(this.procurement)[0];
    }
    return null;
  }

  get isOnList(): boolean {
    return this.$vmx.list.isInList(this.procurement);
  }

  get winners(): CompanyRole[] {
    return this.filteredCompanyRoles(true);
  }

  get computedWinners(): CompanyRole[] {
    const winners = this.winners;
    if (winners.length > 5 && this.winnersCollapsed) {
      return winners.splice(0, 5);
    }
    return winners;
  }

  get companyRoles(): CompanyRole[] {
    return this.filteredCompanyRoles(false);
  }

  get filteredAnnouncements(): ProcurementUpdate[] {
    return this.procurement.procurementUpdates.filter(
      (x) => x.type != AnnouncementTypes.RESULT && x.type != AnnouncementTypes.CANCELLATION,
    );
  }

  get procurementType(): string {
    return this.procurement.procurementType?.name ?? (this.$t('general.emptyField') as string);
  }

  get budgetString(): string {
    if (this.procurement && this.procurement.budget) {
      return this.$n(this.procurement.budget, 'currency', domainUtils.domain.numberFormat);
    }
    return this.$t('general.emptyField') as string;
  }

  get roleModalId() {
    return 'modal-role-info';
  }

  get paywallModalId() {
    return globalFunctions.getPaywallModalId();
  }

  get announcementModalId() {
    return 'modal-announcement-info';
  }

  get tipSent(): boolean {
    if (this.selectedCompanyRole) {
      return this.sentTips[this.selectedCompanyRole.id];
    }
    return false;
  }

  get projectStart(): string {
    return this.project?.start ?? this.procurement.start;
  }

  get projectEnd(): string {
    return this.project?.end ?? this.procurement.end;
  }

  get hasMaterial(): boolean {
    return (
      !!this.procurement.materialFrom ||
      !!this.procurement.materialTo ||
      !!this.procurement.materialLink
    );
  }

  get hasInquiries(): boolean {
    return (
      !!this.procurement.inquiryAddress ||
      !!this.procurement.inquiryFrom ||
      !!this.procurement.inquiryTo
    );
  }

  get hasParticipation(): boolean {
    return (
      !!this.procurement.participationFrom ||
      !!this.procurement.participationTo ||
      !!this.procurement.participationAddress ||
      !!this.procurement.participationNote
    );
  }

  get hasOffers(): boolean {
    return (
      !!this.procurement.offersFrom ||
      !!this.procurement.offersTo ||
      !!this.procurement.offersAddress ||
      !!this.procurement.offersLink ||
      !!this.procurement.offersNote
    );
  }

  get isAboveBasicProduct(): boolean {
    return !(this.$vmx.user.user as User).product.basic;
  }

  get hasNoDates(): boolean {
    return (
      !this.hasInquiries &&
      !this.hasMaterial &&
      !this.hasParticipation &&
      !this.hasOffers &&
      !this.procurement.start &&
      !this.procurement.end
    );
  }

  @Watch('$vmx.blacklist.blacklists')
  blacklistEdited() {
    this.$vmx.search.resetPage();
  }

  companyRoleString(companyRole: CompanyRole): string {
    const unspecified = this.$t('general.unspecified') as string;
    if (companyRole.role && companyRole.role.name && companyRole.role.name != unspecified) {
      return companyRole.role.name;
    } else if (companyRole.roleString) {
      return companyRole.roleString;
    } else {
      return unspecified;
    }
  }

  concatCriterionName(criteria: Criterion[], id: number): string {
    if (criteria && criteria.length > 0) {
      for (const criterion of criteria) {
        if (criterion.id === id) {
          return criterion.name;
        } else if (criterion.children && criterion.children.length > 0) {
          const childConcat = this.concatCriterionName(criterion.children, id);
          if (childConcat) {
            return childConcat.includes('>') ? childConcat : `${criterion.name} > ${childConcat}`;
          }
        }
      }
    }
    return null;
  }

  getCriteriaOfTypeString(typeId: number): string {
    const str = this.getCriteriaOfType(typeId).map((x) => x.name).join(', ');
    return str;
  }

  getCriteriaOfType(typeId: number): Criterion[] {
    return this.procurement.criteria?.filter(criterion => criterion.typeId === typeId) ?? [];
  }

  filteredCompanyRoles(isWinner: boolean) {
    const roles: CompanyRole[] = [];
    if (this.procurement.companyRoles && this.procurement.companyRoles.length > 0) {
      this.procurement.companyRoles.forEach((companyRole) => {
        if (companyRole.isWinner == isWinner) {
          roles.push(companyRole);
        }
      });
    }
    return roles;
  }

  public formatDate(date: string) {
    return formatUtils.formatDate(date, 'DD.MM.YYYY');
  }

  selectRole(companyRole: CompanyRole) {
    if (this.isAboveBasicProduct) {
      this.selectedCompanyRole = companyRole;
      this.openModal(this.roleModalId);
    }
  }

  selectAnnouncement(announcement: ProcurementUpdate) {
    this.selectedAnnouncement = announcement;
    this.openModal(this.announcementModalId);
  }

  openModal(modalId: string) {
    const element = document.getElementById(modalId);
    const modal = new Modal(element);
    modal.show();
  }

  public resizeHandler() {
    this.windowSize = window.innerWidth;
    if (this.windowSize < 768) {
      this.summaryCollapsed = true;
      this.descriptionCollapsed = true;
      this.winnersCollapsed = true;
    }
    if (this.summary && this.description) {
      this.showSummaryFoldingButton =
        this.summary.offsetHeight < this.summary.scrollHeight && this.windowSize < 768;
      this.showDescriptionFoldingButton =
        this.description.offsetHeight < this.description.scrollHeight && this.windowSize < 768;
    }
  }

  public toggleSummary() {
    this.summaryCollapsed = !this.summaryCollapsed;
  }

  public toggleDescription() {
    this.descriptionCollapsed = !this.descriptionCollapsed;
  }

  public toggleWinners() {
    this.winnersCollapsed = !this.winnersCollapsed;
  }

  public formatSummary() {
    this.procurement.summary = formatUtils.formatTextToHtml(this.procurement.summary);
  }

  public formatAnnouncementChanges(changes: string) {
    if (!changes || changes.trim().length === 0) {
      return `(${this.$t('pages.procurement.announcements.noChangeText')})`;
    } else {
      return this.formatTextToHtml(changes);
    }
  }

  public formatTextToHtml(text: string) {
    return formatUtils.formatTextToHtml(text);
  }

  addSentTip(companyRoleId: number) {
    this.sentTips[companyRoleId] = true;
    this.sentTips = [...this.sentTips];
  }

  openDeleteNoteModal(id: number) {
    this.selectedNote = this.procurement.notes.find((note) => note.id == id);
    this.openModal(`delete-${this.noteId}`);
  }

  noteSaved(note: Note) {
    if (this.procurement.notes.some((x) => x.id === note.id)) {
      this.procurement.notes = this.procurement.notes.map((obj) =>
        obj.id === note.id ? note : obj,
      );
    } else {
      this.procurement.notes.push(note);
      this.procurement.notes.sort((a, b) => (a.created > b.created ? -1 : 1));
    }
    this.selectedNote = null;
  }

  noteDeleted(id: number) {
    this.procurement.notes.splice(
      this.procurement.notes.findIndex((x) => x.id === id),
      1,
    );
    this.selectedNote = null;
  }

  routeToCompanyView(companyRole: CompanyRole) {
    this.$router.push({ name: RouterNames.LOGGED_IN_COMPANY_VIEW, params: { id: companyRole.companyId.toString() } })
  }

  setHash(type?: string) {
    if (type) {
      if (this.currentHash == type) {
        document.getElementById(type).scrollIntoView();
        return;
      }

      this.currentHash = type;
      this.$router.replace({ hash: `#${type}`});
      return;
    }

    this.currentHash = location.hash.split('#')[1] ?? null;
    if (this.currentHash == null) return;
    setTimeout(() => {
      document.getElementById(this.currentHash).scrollIntoView();
    }, 500);
  }

  getHashColor(type: string): string {
    return this.currentHash == type ? 'cta' : 'trademark';
  }

}
