import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Update } from '@/interfaces/update';

const api = '/updates';

export class UpdateService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getUpdateById = async (id: number): Promise<Update> => {
    const response = await this.get<Update>(api + '/' + id);
    return this.getResponse(response);
  };

  public getUpdatesByProjectId = async (projectId: number): Promise<Update[]> => {
    const response = await this.get<Update[]>(`${api}/byProjectId/${projectId}`);
    return this.getResponse(response);
  }

  public getUpdatesByProcurementId = async (procurementId: number): Promise<Update[]> => {
    const response = await this.get<Update[]>(`${api}/byProcurementId/${procurementId}`);
    return this.getResponse(response);
  }
}

export const updateService = new UpdateService(apiConfig);
