import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Product } from '@/interfaces/product';
import { ProductFeature } from '@/interfaces/product-feature';

const api = '/products';

export class ProductService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getProducts = async (): Promise<Product[]> => {
    const response = await this.get<Product[]>(api);
    return this.getResponse(response);
  };

  public getFeatures = async (): Promise<ProductFeature[]> => {
    const response = await this.get<ProductFeature[]>(`${api}/features`);
    return this.getResponse(response);
  };
}

export const productService = new ProductService(apiConfig);
