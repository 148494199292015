





























































































import { Component, Ref, Vue } from 'vue-property-decorator';
import FormBookDemo from '@/components/forms/form-book-demo.vue';
import FormLogin from '@/components/forms/form-login.vue';
import FormLoginLimitedAccess from '@/components/forms/form-login-limited-access.vue';
import ProductBox from '@/components/general-components/product-box.vue';
import { RouterNames } from '@/util/enums/router-names';
import TheHeader from '@/components/headers/the-header.vue';

@Component({
  components: {
    FormBookDemo,
    FormLogin,
    FormLoginLimitedAccess,
    ProductBox,
    TheHeader,
  },
})
export default class TheLoginPrompt extends Vue {
  @Ref() bookDemoContainer: HTMLDivElement;
  
  windowSize = window.innerWidth;

  mounted() {
    window.addEventListener('resize', this.resizeHandler);
  }
  
  get isProject() {
    return this.$router.currentRoute.name === RouterNames.LOGGED_IN_PROJECT_VIEW;
  }

  get middleColClasses() {
    return [
      this.windowSize >= 1400 ? 'border-start' : 'border-top',
      'border-trademark',
    ]
  }

  resizeHandler() {
    this.windowSize = window.innerWidth;
  }


  scrollToBookDemo() {
    if (this.bookDemoContainer) {
      this.bookDemoContainer.scrollIntoView();
    }
  }
}
