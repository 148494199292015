import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Project } from '@/interfaces/project';

const api = '/projects';

export class ProjectService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getProjectById = async (id: number): Promise<Project> => {
    const response = await this.get<Project>(`${api}/byId/${id}`);
    return this.getResponse(response);
  };

  public getNewestProjects = async (limit: number): Promise<Project[]> => {
    const response = await this.get<Project[]>(`${api}/newest/${limit}`);
    return this.getResponse(response);
  };

}

export const projectService = new ProjectService(apiConfig);
