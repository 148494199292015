import * as languageUtils from '../util/language-utils';
import messages from './messages';
import numberFormats from './locales/number-formats';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export default new VueI18n({
  locale: languageUtils.getLanguageId().iso6391 ?? process.env.VUE_APP_I18N_LOCALE ?? 'da',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
  numberFormats,
});
