












































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Blacklist } from '@/interfaces/blacklist';
import DropdownOrderBy from '@/components/dropdowns/dropdown-order-by.vue';
import { globalFunctions } from '@/util/global-functions';
import { List } from '@/interfaces/list';
import ListItemCard from '@/components/cards/list-item-card.vue';
import moment from 'moment';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';
import SearchInput from '@/components/form-fields/search-input.vue';
import SearchItemCard from '@/components/cards/search-item-card.vue';
import SearchItemsListView from '@/components/search-components/search-items-list-view.vue';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
    DropdownOrderBy,
    ListItemCard,
    SearchInput,
    SearchItemCard,
    SearchItemsListView,
  },
})
export default class ListOverview extends Vue {
  @Prop({ required: true }) listItems: Array<Project | Procurement>;
  @Prop({ required: true }) emptyListText: string;

  @Prop({ default: null }) list: List;
  @Prop({ default: true }) listView: boolean;

  @Prop({ default: null }) filterFunc: (v: Procurement | Project | ProcurementReference) => boolean;

  // Blacklist Props
  @Prop({ default: false }) showShowBlacklistButton: boolean;
  @Prop({ default: null }) onPersistBlacklist: (blacklist: Blacklist) => Promise<Blacklist>;

  showBlacklists = false;

  listViewCopy = true;
  sortedList: Array<Project | Procurement> = [];

  listItemsResult: Array<Project | Procurement> = [];

  orderBy: TranslateResult = null;
  orderByDescending = true;
  filterText = '';

  mounted() {
    this.listViewCopy = this.listView;
    this.sort();
  }

  created() {
    this.orderBy = this.sortingOptions[0][0];
  }

  @Watch('listItems')
  @Watch('listViewCopy')
  @Watch('$vmx.blacklist.blacklists')
  @Watch('showBlacklists')
  sort() {
    this.listItemsResult = [...this.listItems]
      .filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()))
      .filter((item) => {
        if (this.showBlacklists) {
          return true;
        } else if (this.filterFunc != null) {
          return this.filterFunc(item);
        } else {
          return true;
        }
      })
      .sort(this.sortingMethod)
  }

  sortingOptions: [TranslateResult, (a: Project | Procurement, b: Project | Procurement) => number][] = [
    [ this.$t('components.sorting.updated'),     this.compareLatestUpdate ],
    [ this.$t('components.sorting.contractSum'), (a, b) => b.budget - a.budget ],
    [ this.$t('components.sorting.published'),   (a, b) => moment(a.published).isBefore(moment(b.published)) ? 1 : -1 ],
  ]

  get orderByDesc() {
    return this.orderByDescending ? 'desc' : 'asc'
  }

  set orderByDesc(val: string) {
    this.orderByDescending = val === 'desc';
    this.sort();
  }

  get sortingMethod(): (a: Project | Procurement, b: Project | Procurement) => number {
    let method: (a: Project | Procurement, b: Project | Procurement) => number;

    for (const [sortName, sortMethod] of this.sortingOptions) {
      if (this.orderBy.toString() === sortName) {
        method = sortMethod;
      }
    }
    return (a, b) => this.orderByDescending ? method(a, b) : method(b, a);
  }

  get viewType() {
    return this.listViewCopy ? 'list' : 'card';
  }

  set viewType(val: string) {
    this.listViewCopy = val === 'list';
  }

  @Watch('listView')
  onListViewChanged() {
    this.listViewCopy = this.listView;
  }

  removeFromList(list: Array<Project | Procurement>, blacklistItem: Blacklist) {
    if (blacklistItem.procurementId != null) {
      list.splice(
        list.findIndex((item) => item.id == blacklistItem.procurementId),
        1,
      );
    } else if (blacklistItem.projectId != null) {
      list.splice(
        list.findIndex((item) => item.id == blacklistItem.projectId),
        1,
      );
    }
  }

  isProcurement(item: Project | Procurement) {
    return globalFunctions.isProcurement(item);
  }

  compareLatestUpdate(a: Procurement | Project, b: Procurement | Project) {
    if (!a.latestUpdateDate) return 1;
    if (!b.latestUpdateDate) return -1;
    return moment(a.latestUpdateDate).isAfter(moment(b.latestUpdateDate)) ? -1 : 1;
  }
}
