










































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import { globalFunctions } from '@/util/global-functions';
import InputPhone from '@/components/form-fields/input-phone.vue';

@Component({
  components: {
    AbstractForm,
  },
})
export default class FormUserSettings extends Vue {
  @Ref() inputUsername: BaseInputField;
  @Ref() inputName: BaseInputField;
  @Ref() inputEmail: BaseInputField;
  @Ref() inputPhone: InputPhone;

  initialMail: string = null;

  committed = false;
  mailChanged = false;

  created() {
    this.initialMail = this.user.mail;
  }

  get user() {
    return this.$vmx.user.user;
  }

  get formIsValid(): boolean {
    let isValid = this.inputUsername.validate();
    isValid = this.inputName.validate() && isValid;
    isValid = this.inputEmail.validate() && isValid;
    isValid = this.inputPhone.validate() && isValid;
    return isValid;
  }

  setNumber(number: string) {
    this.user.phone = number;
  }

  setCallingCode(code: string) {
    this.user.callingCode = +code;
  }

  async submit() {
    if (this.formIsValid) {
      try {
        this.mailChanged = this.user.mail !== this.initialMail;
        await this.$vmx.user.putUser(this.user);

        this.committed = true;
        globalFunctions.timeout(10000).then(() => {
          this.committed = false;
          this.mailChanged = false;
        })
      } catch (error) {
        throw error;
      }
    }
  }
}
