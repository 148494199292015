import * as qs from 'qs';
import { PathLike } from 'fs';

/**
 * Config to use as parameter for Axios API instances (when using axios.create(config))
 * @author Jais Christiansen
 */

const baseURL =
  process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PREFIX
    ? process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PREFIX
    : 'http://localhost:8080/rest/restapi';

export const apiConfig = {
  returnRejectedPromiseOnError: true,
  withCredentials: true,
  timeout: 0,
  baseURL,
  headers: {
    common: {
      Authorization: '',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: process.env.BASE_URL,
    },
  },
  paramsSerializer: (params: PathLike) => qs.stringify(params, { indices: false }),
};
