import { Component, Vue } from 'vue-property-decorator';
import FormBookDemo from '@/components/forms/form-book-demo.vue';
import TheHeader from '@/components/headers/the-header.vue';

@Component({
  components: {
    TheHeader,
    FormBookDemo,
  },
})
export default class BookDemoView extends Vue {
  getBackground(fileName: string) {
    return 'url(' + this.getFromFile('backgrounds/' + fileName) + ')';
  }

  getFromFile(fileName: string) {
    return this.$vmx.file.fromFile('project-agent/' + fileName);
  }
}
