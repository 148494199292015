
































































































































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { companyService } from '@/services/restapi/company-service';
import { dateUtils } from '@/util/date-utils';
import DropdownIconBar from '@/components/dropdowns/dropdown-icon-bar.vue';
import DropdownOrderBy from '@/components/dropdowns/dropdown-order-by.vue';
import { formatUtils } from '@/util/format-utils';
import moment from 'moment';
import { Project } from '@/interfaces/project';
import { RouterNames } from '@/util/enums/router-names';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
    DropdownIconBar,
    DropdownOrderBy,
  },
})
export default class CompanyProjectsList extends Vue {
  @Prop({ required: true }) companyId: number;
  @Prop({ default: true }) filters: boolean;
  @Prop({ default: null }) limit: number;
  @Prop({ default: 10 }) itemsToShow: number;
  @Prop({ default: 'transparent' }) headerColor: string;
  @Prop() headerText: string;
  @Ref() tableContainer: HTMLDivElement;

  sortOptions: [TranslateResult, (a: Project, b: Project) => number][] = [
    [this.$t('pages.company.companyRoles.sortNewest'), this.comparatorDate],
    [this.$t('pages.company.companyRoles.sortName'), this.comparatorItemName],
    [this.$t('pages.company.companyRoles.sortPhase'), this.comparatorPhase],
    [this.$t('pages.company.companyRoles.sortRole'), this.comparatorRoleName],
  ];

  errorCode: number = null;
  getImageFromFile = this.$vmx.file.imageFromFile;
  items: Array<Project> = null;
  loading = false;
  order: 'asc' | 'desc' = 'asc';
  sort: TranslateResult = this.sortOptions[0][0];
  tableOptions = {
    lg: 1080,
    md: 768,
    sm: 535,
  }
  tableSize = 4000;

  mounted() {
    window.addEventListener('resize', this.resizeHandler);
    this.loading = true;
    this.loadItemsInCompany();
  }

  get headerClasses() {
    return [
      // this.headerColor ? `table-${this.headerColor}` : '',
      this.headerColor ? `bg-${this.headerColor}` : '',
      this.headerText ? `text-${this.headerText}` : '',
    ]
  }

  get sortedItems(): Array<Project> {
    const sortedAndFiltered = [...this.items];

    for (const [sortName, sortMethod] of this.sortOptions) {
      if (sortName === this.sort) {
        if (this.order === 'asc') {
          sortedAndFiltered.sort(sortMethod);
        } else {
          sortedAndFiltered.sort((a, b) => sortMethod(b, a));
        }
        break;
      }
    }

    return sortedAndFiltered;
  }

  get shownItems(): Array<Project> {
    const sortedAndFiltered = this.sortedItems;
    return sortedAndFiltered.slice(0, this.itemsToShow);
  }

  getCriteriaStringsOfType(item: Project, typeId: number): string[] {
    return item.criteria?.filter(criterionData => criterionData.typeId === typeId)?.map(c => c.name) ?? [];
  }

  comparatorDate(item1: Project, item2: Project): number {
    const isAfter: boolean = dateUtils.isDateStringAfter(item1.published, item2.published);
    const isBefore: boolean = dateUtils.isDateStringAfter(item2.published, item1.published);
    return isAfter ? -1 : isBefore ? 1 : 0;
  }

  comparatorItemName(item1: Project, item2: Project): number {
    return item1.name < item2.name ? -1 : item2.name < item1.name ? 1 : 0;
  }

  comparatorPhase(item1: Project, item2: Project): number {
    const phaseId1 = item1.projectPhase.id;
    const phaseId2 = item2.projectPhase.id;
    return phaseId1 < phaseId2 ? -1 : phaseId2 < phaseId1 ? 1 : 0;
  }

  comparatorRoleName(item1: Project, item2: Project): number {
    const role1 = item1.companyRoles[0].role?.name ?? item1.companyRoles[0].roleString ?? this.$t('general.unspecified');
    const role2 = item2.companyRoles[0].role?.name ?? item2.companyRoles[0].roleString ?? this.$t('general.unspecified');
    return role1 < role2 ? -1 : role2 < role1 ? 1 : 0;
  }

  formatDate(date: string) {
    return formatUtils.formatDate(date, 'DD.MM.YYYY');
  }

  getDatesForItem(item: Project): string[] {
    const dates: string[] = [];
    if (item.companyRoles) {
      for (const companyRole of item.companyRoles) {
        if (companyRole.added) {
          dates.push(formatUtils.formatDate(companyRole.added, 'DD.MM.YYYY'));
        }
      }
    }
    return dates;
  }

  getRolesForItem(item: Project): {role: string, added: string}[] {
    const roles: { role: string, added: string }[] = [];
    if (item.companyRoles) {
      for (const companyRole of item.companyRoles) {
        const newRole = {
          role: companyRole.role?.name ?? companyRole.roleString ?? this.$t('general.unspecified') as string,
          added: companyRole.added ? formatUtils.formatDate(companyRole.added, 'DD.MM.YYYY') : null,
        }
        roles.push(newRole);
      }
    }
    return roles;
  }

  loadItemsInCompany() {
    companyService.getItemsForCompany(this.companyId, true, this.limit)
      .then(
        (items) => {
          items.forEach(item => {
            item.companyRoles = item.companyRoles.sort((cr1, cr2) => moment(cr1.added).isAfter(moment(cr2.added)) ? 1 : -1)
          })
          this.items = items as Array<Project>;
        },
        (error) => {
          this.errorCode = error;
        },
      ).finally(() => {
        this.loading = false;
      })
  }

  @Watch('filter')
  resetShown() {
    this.itemsToShow = 10;
  }

  public resizeHandler() {
    if (this.tableContainer) {
      this.tableSize = this.tableContainer.clientWidth;
    }
  }

  routerLink(item: Project) {
    return {
      name: RouterNames.LOGGED_IN_PROJECT_VIEW,
      params: { id: item.id.toString() },
    };
  }

  showAtSize(minSize: number) {
    return [
      this.tableSize < minSize ? 'd-none' : '',
    ];
  }
}
