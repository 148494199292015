import { Component, Ref, Vue } from 'vue-property-decorator';
import FormContact from '@/components/forms/form-contact.vue';
import { ProductFeature } from '@/interfaces/product-feature';
import { SuggestedProduct } from '@/interfaces/suggested-product';
import SuggestedProductBox from '@/components/service-components/suggested-product-box.vue';
import { suggestedProductService } from '@/services/restapi/suggested-product-service';
import TheHeader from '@/components/headers/the-header.vue';

@Component({
  components: {
    FormContact,
    TheHeader,
    SuggestedProductBox,
  },
})
export default class ServicesView extends Vue {
  @Ref() contact: HTMLDivElement;
  suggestedProducts: SuggestedProduct[] = [];
  features: ProductFeature[] = this.$vmx.product.productFeatures;

  selectedIndex: number = null;

  mounted() {
    suggestedProductService.getSuggestedProducts().then((spList: SuggestedProduct[]) => {
      spList.sort((a, b) => (a.index < b.index ? -1 : 1));
      this.suggestedProducts = spList;
    });
  }

  getDomainSVG(tld: string) {
    return {
      backgroundImage: `url('${require('@/assets/flags/' + tld + '.svg')}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };
  }

  scrollToContact() {
    this.contact.scrollIntoView();
  }
}
