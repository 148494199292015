import { createModule, preserve } from '../preloader';
import { action } from 'vuex-class-component';
import { projectRoleService } from '@/services/restapi/role-service';
import { Role } from '@/interfaces/criterias/role';

export default class RoleStore extends createModule('role') {
  private rolesData = null as Role[];
  private rolesPromise = null as Promise<Role[]>;

  get roles(): Role[] {
    return this.rolesData;
  }

  @action
  async setRoles(roles: Role[]) {
    this.rolesData = roles;
  }

  @action
  async fetchRoles() {
    return (
      this.rolesData ?? preserve(this.rolesPromise, projectRoleService.getRoles, this.setRoles)
    );
  }
}
