



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { RoutingButton } from '@/interfaces/non-entities/routing-button';

@Component
export default class HeaderRoutingBar extends Vue {
  @Prop() routingButtons: RoutingButton[];
  routeName: string = null;

  mounted() {
    this.routeName = this.$router.currentRoute.name;
    this.$router.beforeEach((to, from, next) => {
      this.routeName = to.name;
      next();
    });
  }

  isActive(routingButton: RoutingButton): boolean {
    return routingButton.name === this.routeName;
  }

  getLocation(routingButton: RoutingButton): Location {
    return {
      name: routingButton.name,
      params: routingButton.params,
    }
  }
}
