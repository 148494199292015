import { createModule, preserve } from '../preloader'
import { action } from 'vuex-class-component';
import { criteriaUtils } from '@/util/criteria-utils';
import { Criterion } from '@/interfaces/criterias/criterion';
import { criterionService } from '@/services/restapi/criterion-service';
import { CriterionType } from '@/interfaces/criterias/criterion-type'
import { vmx } from '..';

export default class CriterionStore extends createModule('criterion') {
  private criterionTypesData = null as CriterionType[];
  private criterionTypesPromise = null as Promise<CriterionType[]>;

  get criterionTypes(): CriterionType[] {
    return this.criterionTypesData ?? [];
  }

  get searchableCriterionTypes(): CriterionType[] {
    return this.criterionTypes.filter(x => x.searchable);
  }

  get standardCriterionTypes(): CriterionType[] {
    return this.criterionTypes.filter(x => !x.tailored);
  }

  get tailoredCriterionTypes(): CriterionType[] {
    return this.criterionTypes.filter(x => x.tailored);
  }

  get criterionType(): (id:number) => CriterionType {
    return (id) => {
      if (this.criterionTypesData === null) {
        return null;
      }
      return this.criterionTypesData.find(criterionType => criterionType.id === id);
    }
  }

  get criteriaOfType(): (id: number) => Criterion[] {
    return (id) => {
      if (this.criterionTypesData === null) {
        return null;
      }
      return this.criterionTypesData.find(criterionType => criterionType.id === id).criteria;
    }
  }

  get criterionTypeFromTranslation(): (translation: string) => CriterionType {
    return (translation) => {
      if (this.criterionTypesData === null) {
        return null;
      }
      return this.criterionTypesData
        .find(criterionType => criterionType.translationProperty === translation);
    }
  }

  get criteriaOfTypeFromTranslation(): (translation: string) => Criterion[] {
    return (translation) => {
      if (this.criterionTypesData === null) {
        return null;
      }
      return this.criterionTypesData
        .find(criterionType => criterionType.translationProperty === translation).criteria;
    }
  }

  get highlightedCriterionTypes(): (isProcurement: boolean) => CriterionType[] {
    return (isProcurement) => {
      return this.criterionTypes.filter(ct => isProcurement ? ct.highlightedProcurement : ct.highlightedProject);
    }
  }

  @action
  async setCriterionTypes(criterionTypes: CriterionType[]) {
    criterionTypes.forEach(criterionType => {
      criterionType.criteria = criteriaUtils.sortCriteriaTreeByName(criterionType.criteria);
    });
    this.criterionTypesData = criterionTypes;
    // if (
    //   this.criterionTypesData && vmx.search.agent
    //   && !vmx.search.agent.id
    //   && (vmx.search.agent.allowUnusedCriterionTypes?.length <= 0 ?? true)
    // ) {
    //   vmx.search.agent.allowUnusedCriterionTypes = this.criterionTypesData.map(criterionType => criterionType.id);
    // }
  }

  @action
  async fetchCriterionTypes(): Promise<CriterionType[]> {
    return this.criterionTypesData ??
    await preserve(this.criterionTypesPromise, criterionService.getCriterionTypes, this.setCriterionTypes);
  }
}