






































import { Component, Prop, Vue } from 'vue-property-decorator';
import FormCallMe from '@/components/forms/form-call-me.vue';

@Component({
  components: {
    FormCallMe,
  },
})
export default class ContactOptions extends Vue {
  @Prop() title: string;
  @Prop({ default: 'trademark' }) backgroundColor: string;
  @Prop({ default: 'white' }) textColor: string;

  windowSize = window.innerWidth;

  mounted() {
    window.addEventListener('resize', this.resizeHandler);
  }

  resizeHandler() {
    this.windowSize = window.innerWidth;
  }

  get middleColClasses() {
    return [
      this.windowSize >= 1200 ? 'border-start' : 'border-top',
      `border-${this.textColor}`,
    ]
  }
}
