import * as blacklistUtil from '@/util/blacklist-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Blacklist } from '@/interfaces/blacklist';
import { blacklistService } from '@/services/restapi/blacklist-service';
import { List } from '@/interfaces/list';
import ListOverview from '@/components/list-components/list-overview.vue';
import { listService } from '@/services/restapi/list-service';
import { Procurement } from '@/interfaces/procurement';
import { Project } from '@/interfaces/project';
import { Watch } from 'vue-slider-component/node_modules/vue-property-decorator';

@Component({
  components: {
    ListOverview,
  },
})
export default class ListView extends Vue {
  @Prop({ required: true }) id: number;

  blacklists: Blacklist[] = null;
  listItems: (Project | Procurement)[] = null;

  showAllBlacklisted = false;

  loading = true;
  error = false;

  mounted() {
    this.loading = true;
    this.$vmx.list.fetchLists().then(() => {
      blacklistService.getBlacklists().then((blacklists) => {
        this.blacklists = blacklists.filter((blacklist) => blacklist.userIsOnList);
        this.updateListItems().finally(() => {
          this.loading = false;
        });
      });
    });
  }

  get list(): List {
    return this.$vmx.list.listById(this.id);
  }

  @Watch('list')
  @Watch('blacklists')
  @Watch('$vmx.modal.lastNoteUpdate')
  async updateListItems() {
    if (this.list != null) {
      try {
        this.listItems = await listService.getListItems(this.list.id);
        this.error = false;
      } catch (error) {
        this.error = true;
      }
    }
  }

  get title() {
    if (this.list?.paUserIds?.length > 1) {
      return `${(this.$t('general.shared') as string).toUpperCase()} - ${this.list.name}`;
    }
    return this.list?.name;
  }

  filterFunc(val: Procurement | Project): boolean {
    return !blacklistUtil.isItemInBlacklists(val, this.$vmx.blacklist.userBlacklists);
  }

  openListSettingsModal() {
    this.$vmx.modal.openListSettingsModal({ list: this.list });
  }

  async onPersistBlacklist(blacklist: Blacklist): Promise<Blacklist> {
    return this.$vmx.blacklist.pushBlacklist(blacklist);
  }
}
