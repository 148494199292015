import * as domainUtils from '@/util/domain-utils';
import * as languageUtils from '@/util/language-utils';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { cookieUtils } from '@/util/cookie-utils';
import { ResponseCodes } from '@/util/enums/response-codes';
import { RouterNames } from '@/util/enums/router-names';
import { vmx } from '@/store';

/**
 * Abstract class to extend when creating specific services.
 * @author Jais Christiansen
 */

export class AbstractService {
  private axiosInstance: AxiosInstance;
  private token: string;
  private config: AxiosRequestConfig;

  constructor(config?: AxiosRequestConfig) {
    this.config = config;
    this.axiosInstance = axios.create(config);
    const jwt = cookieUtils.getCookie('session');
    this.token = jwt ? jwt : '';

    this.axiosInstance.interceptors.request.use((param) => ({
      ...param,
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        'Content-Type': 'application/json',
      },
      params: {
        ...param.params,
        languageId: languageUtils.getLanguageId().id,
        domainId: domainUtils.domain.id,
      },
    }))

    const onSuccefullResponse: (response: AxiosResponse) => AxiosResponse = response => {
      return response;
    };

    const onFailureResponse: (error: AxiosError) => Promise<any> = async error => {
      const response = error.response

      console.error(`!!! Service Error !!!
      Service: ${this.constructor.name}
      URL: ${response.config.url} (${response.config.method})
      Status: ${response.status}
      Status Text: ${response.statusText}
      Body: ${response.data.slice(0, 100)}`);

      if (error.response.status == ResponseCodes.NOT_CORRECT_SESSION) {
        await vmx.session.loginOut({ name: RouterNames.INVALID_SESSION });
        return;
      }

      if (error.response.status == ResponseCodes.SESSION_NULL) {
        await vmx.session.panic('You have been logged out by an administrator')
        return;
      }

      return error.response;
    }

    this.axiosInstance.interceptors.response.use(onSuccefullResponse, onFailureResponse);
  }

  private formatUrl = (url: string) => url;

  public getToken = () => {
    if (!this.token) {
      const jwt = cookieUtils.getCookie('session');
      this.token = jwt ? jwt : '';
    }
    return this.token;
  };

  public setToken = (token: string, hours: number) => {
    cookieUtils.setCookie('session', token, hours);
    // localStorage.setItem('jwt', token);
    this.token = token;
  };

  protected get = async <T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> => {
    const response: AxiosResponse<T> = await this.axiosInstance.get<T>(this.formatUrl(url), config)
    return response;
  };

  protected post = async <T>(
    url: string,
    body?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> => {
    const response: AxiosResponse<T> = await this.axiosInstance.post<T>(url, body, config) 
    return response;
  };

  protected put = async <T>(
    url: string,
    body?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> => {
    const response: AxiosResponse<T> = await this.axiosInstance.put(url, body, config)
    return response;
  };

  protected delete = async <T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> => {
    const response: AxiosResponse<T> = await this.axiosInstance.delete<T>(url, config)
    return response;
  };

  public getResponse = <T>(response: AxiosResponse<T>): T => {
    if (response != null) {
      if (ResponseCodes.is2xx(response.status)) {
        return this.success(response);
      }
      throw response.status;
    }
    throw 500;
  }

  public success = <T>(response: AxiosResponse<T>): T => {
    return response.data;
  };

}
