








































import { Component, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { CompanyAgent } from '@/interfaces/search/company-agent';
import { Modal } from 'bootstrap';
import ModalAgentSettings from '@/components/modals/modal-agent-settings.vue';
import { ModalIds } from '@/util/enums/modal-ids';

@Component({
  components: {
    AbstractForm,
    BaseModalContainer,
    ModalAgentSettings,
  },
})
export default class ModalAgentReplace extends Vue {
  loading = true;
  submitting = false;
  error = false;


  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  get companyAgent(): CompanyAgent {
    return this.$vmx.modal.companyAgent;
  }

  get modalId(): string {
    return ModalIds.COMPANY_AGENT_DELETE_MODAL;
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  async deleteCompanyAgent() {
    this.submitting = true;
    try {
      await this.$vmx.companyAgent.deleteCompanyAgent(this.companyAgent);
      this.hideModal();
    } catch (error) {
      this.error = true;
      this.submitting = false;
    }
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  initializeModal() {
    this.loading = true;
    if (!this.companyAgent) {
      this.hideModal();
      return;
    }
    this.loading = false;
  }

  openCompanyAgentSettingsModal() {
    this.$vmx.modal.openCompanyAgentSettingsModal(this.companyAgent);
  }
}
