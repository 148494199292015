import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { CriterionType } from '@/interfaces/criterias/criterion-type';

const api = '/criteria';

export class CriterionService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getCriterionTypes = async (): Promise<CriterionType[]> => {
    const response = await this.get<CriterionType[]>(`${api}/criterion-types`);
    return this.getResponse(response);
  }
}

export const criterionService = new CriterionService(apiConfig);