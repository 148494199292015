import { render, staticRenderFns } from "./about-view.vue?vue&type=template&id=6afc7dac&scoped=true&"
import script from "./about-view.ts?vue&type=script&lang=ts&"
export * from "./about-view.ts?vue&type=script&lang=ts&"
import style0 from "./about-view.scss?vue&type=style&index=0&id=6afc7dac&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6afc7dac",
  null
  
)

export default component.exports