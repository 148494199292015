import { Component, Vue } from 'vue-property-decorator';
import FormContact from '@/components/forms/form-contact.vue';
import ProductBox from '@/components/general-components/product-box.vue';
import TheHeader from '@/components/headers/the-header.vue';
import { userService } from '@/services/restapi/user-service';

@Component({
  components: {
    TheHeader,
    ProductBox,
    FormContact,
  },
})
export default class PricesView extends Vue {

  scrollToContact() {
    const contact = document.querySelector('#contact');
    contact.scrollIntoView();
  }

  getBackground(fileName: string) {
    return 'url(' + this.$vmx.file.fromFile('project-agent/backgrounds/' + fileName) + ')';
  }


  async writeUsSubmit(name: string, email: string, phone: string): Promise<void> {
    await userService.sendRequestSubscription(name, phone, email);
  }
}
