import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Notification } from '@/interfaces/notification';

const api = '/notifications';

export class NotificationService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getNotifications = async (isSeen: boolean): Promise<Notification[]> => {
    let config = null;
    if (isSeen != null) {
      config = {
        params: {
          isSeen,
        },
      };
    }

    const response = await this.get<Notification[]>(`${api}`, config);
    return this.getResponse(response);
  };

  public setAllSeen = async () => {
    const response = await this.put<void>(`${api}/setAllSeen`);
    return this.getResponse(response);
  };

}

export const notificationService = new NotificationService(apiConfig);
