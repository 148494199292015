














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseLoadingSpinner extends Vue {
  @Prop( { default: 'Loading...'}) private label: string;
  @Prop( { default: '2rem'}) private size: string;
  @Prop( {default: '.25 rem'}) private borderSize: string;
  @Prop( {
    type: String,
    default: 'primary',
  })
  private color: string;

  get sizeStyle() {
    return {
      width: this.size,
      height: this.size,
      'border-width': this.borderSize,
    };
  }

  get styleClass() {
    return [
      `text-${this.color}`,
    ]
  }

}
