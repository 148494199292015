import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { ProcurementType } from '@/interfaces/criterias/procurement-type';

const api = '/procurement-types';

export class ProcurementTypeService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getProcurementTypes = async (): Promise<ProcurementType[]> => {
    const response = await this.get<ProcurementType[]>(api);
    return this.getResponse(response);
  };

  public getProcurementTypeById = async (id: number): Promise<ProcurementType> => {
    const response = await this.get<ProcurementType>(api + '/' + id);
    return this.getResponse(response);
  };
}

export const procurementTypeService = new ProcurementTypeService(apiConfig);
