import * as languageUtils from '../../util/language-utils';
import { action } from 'vuex-class-component';
import { createModule } from '../preloader';
import i18n from '@/localization/i18n';
import { Language } from '@/interfaces/language';

export default class LanguageStore extends createModule('language') {
  get language(): Language {
    return languageUtils.getLanguageId();
  }

  get languages(): Language[] {
    return languageUtils.languages;
  }

  @action
  async updateLanguage(language: Language) {
    if (i18n.locale !== language.iso6391) {
      i18n.locale = language.iso6391;
    }
    localStorage.setItem('languageId', JSON.stringify(language));
  }
}