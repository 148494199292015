
















import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component
export default class BaseSidebar extends Vue {
  @VModel() active: boolean;
  @Prop({ default: true }) left: boolean;
  @Prop() styleClasses: string;
  @Prop({ default: true }) overlayClickable: boolean;

  get sidebarClasses() {
    return [
      this.left ? 's-navbar' : 'e-navbar',
      this.left ? 'ps-5' : 'pe-5',
      this.active ? 'active' : '',
      this.styleClasses,
    ];
  }

  overlayClicked() {
    if (this.overlayClickable) {
      this.toggleSidebar(false);
    }
  }

  public toggleSidebar(show: boolean) {
    this.active = show;
  }
}
