import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBan,
  faBars,
  faBell,
  faBinoculars,
  faCalendarPlus,
  faCheck,
  faChevronDoubleDown,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDoubleUp,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboard,
  faCog,
  faCommentAltPlus,
  faCookieBite,
  faCreditCard,
  faDoorOpen,
  faEllipsisV,
  faEnvelope,
  faExternalLink,
  faEyeSlash,
  faFileDownload,
  faFileSignature,
  faFlagCheckered,
  faGlobe,
  faGlobeEurope,
  faHandshake,
  faHardHat,
  faHeadset,
  faHome,
  faInfoCircle,
  faLandmark,
  faLightbulb,
  faLink,
  faList,
  faLock,
  faMegaphone,
  faMinus,
  faNewspaper,
  faPencil,
  faPlus,
  faPollPeople,
  faQuestion,
  faSearch,
  faShareAlt,
  faSortAmountDown,
  faSortAmountUp,
  faStar,
  faStickyNote,
  faTachometer,
  faTimes,
  faTimesCircle,
  faToolbox,
  faTools,
  faTrashAlt,
  faUnlock,
  faUnlockAlt,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import {
  faBorderAll,
  faBan as fasBan,
  faCircle as fasCircle,
  faQuestion as fasQuestion,
  faStar as fasStar,
  faThLarge,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircle as farCircle,
} from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

export default function () {
  library.add(
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArchive,
    faArrowLeft,
    faArrowDown,
    faArrowUp,
    faBan,
    faBars,
    faBell,
    faBinoculars,
    faBorderAll,
    faCheck,
    faChevronDoubleDown,
    faThLarge,
    faChevronDoubleUp,
    faChevronDoubleRight,
    faChevronDoubleLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faCircle,
    faClipboard,
    faCog,
    faCommentAltPlus,
    faCookieBite,
    faCreditCard,
    faDoorOpen,
    faEllipsisV,
    faEnvelope,
    faEyeSlash,
    faExternalLink,
    faFileDownload,
    faFileSignature,
    faFlagCheckered,
    faGlobeEurope,
    faHandshake,
    faHardHat,
    faHeadset,
    faHome,
    faInfoCircle,
    faLandmark,
    faLightbulb,
    faLink,
    faList,
    faLock,
    faMegaphone,
    faMinus,
    faNewspaper,
    faPencil,
    faPlus,
    faPollPeople,
    farCircle,
    fasCircle,
    fasBan,
    fasQuestion,
    faQuestion,
    faSearch,
    faShareAlt,
    faSortAmountDown,
    faSortAmountUp,
    faStar,
    fasStar,
    faStickyNote,
    faTachometer,
    faTimes,
    faTimesCircle,
    faTools,
    faToolbox,
    faTrashAlt,
    faUnlock,
    faUnlockAlt,
    faUser,
    faCalendarPlus,
    faGlobe,
  );
}
