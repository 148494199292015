import { Blacklist } from '@/interfaces/blacklist';
import { globalFunctions } from './global-functions';
import { Procurement } from '@/interfaces/procurement';
import { Project } from '@/interfaces/project';
import { User } from '@/interfaces/user';

export function userIsOnBlacklist(blacklist: Blacklist, user: User) {
  return blacklist.usersIds.some((userId) => userId == user.id);
}

export function isItemInBlacklists(item: Procurement | Project, blacklits: Blacklist[]) {
  const isProcurement = globalFunctions.isProcurement(item);
  return blacklits.some(
    (blacklist) => item.id == ((isProcurement && blacklist.procurementId) || blacklist.projectId),
  );
}
