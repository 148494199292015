import { action } from 'vuex-class-component';
import { Blacklist } from '@/interfaces/blacklist';
import { blacklistService } from '@/services/restapi/blacklist-service';
import { createModule } from '../preloader';
import { globalFunctions } from '@/util/global-functions';
import { Procurement } from '@/interfaces/procurement';
import { Project } from '@/interfaces/project';
import { vmx } from '..';

export default class BlacklistStore extends createModule('blacklist') {
  private blacklistsData = null as Blacklist[];
  private blacklistsPromise = null as Promise<Blacklist[]>;

  get blacklists(): Blacklist[] {
    return this.blacklistsData;
  }

  get userBlacklists(): Blacklist[] {
    return (
      this.blacklists?.filter((blacklist) =>
        blacklist.usersIds.some((id) => vmx.user.user.id === id),
      ) ?? []
    );
  }

  get isOnUserBlacklist(): (dataObject: Project | Procurement) => boolean {
    return (dataObject) => {
      const isProcurement = globalFunctions.isProcurement(dataObject);
      return this.blacklists
        ?.filter((blacklist) => blacklist.usersIds.some((userId) => vmx.user.user.id == userId))
        .some(
          (blacklist: Blacklist) =>
            dataObject.id == (isProcurement ? blacklist.procurementId : blacklist.projectId),
        );
    };
  }

  @action
  async pushBlacklist(blacklist: Blacklist): Promise<Blacklist> {
    if (blacklist == null) {
      throw 'Tried to push null blacklist';
    }

    const newBlacklist = await blacklistService.editBlacklist(blacklist);

    if (newBlacklist != null) {
      this.setBlacklists([
        ...this.blacklists.filter((blacklist) => blacklist.id != newBlacklist.id),
        newBlacklist,
      ]);
    }
    return newBlacklist;
  }

  @action
  async deleteBlacklist(blacklistId: number) {
    await blacklistService.deleteBlacklist(blacklistId);

    this.blacklistsData = this.blacklists.filter((blacklist) => blacklist.id != blacklistId);
  }

  @action
  async filterSearchItemList(searchItemList: (Procurement | Project)[]) {
    const blacklists = await this.fetchBlacklists();

    return searchItemList.filter((item) => {
      const isProcurement = globalFunctions.isProcurement(item);

      if (isProcurement) {
        return blacklists.every((blacklist) => blacklist.procurementId !== item.id);
      } else {
        return blacklists.every((blacklist) => blacklist.projectId !== item.id);
      }
    });
  }

  @action
  async fetchBlacklists() {
    if (this.blacklists != null) {
      return this.blacklists;
    }

    if (this.blacklistsPromise != null) {
      return this.blacklistsPromise;
    }

    this.blacklistsPromise = blacklistService.getBlacklists();
    this.blacklistsPromise.then((blacklists) => {
      this.blacklistsData = blacklists;
    });

    return this.blacklistsPromise;
  }

  @action
  async setBlacklists(blacklists: Blacklist[]) {
    this.blacklistsData = blacklists;
  }

  @action
  async modBlacklists(f: (blacklists: Blacklist[]) => Blacklist[]) {
    this.setBlacklists(f(this.blacklists));
  }

  @action
  async addBlacklist(blacklist: Blacklist) {
    this.blacklists.push(blacklist);
  }

  @action
  async removeBlacklist(blacklist: Blacklist) {
    this.setBlacklists(this.blacklists.filter((blacklist0) => blacklist0.id != blacklist.id));
  }
}
