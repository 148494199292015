
































































import { Component, Vue } from 'vue-property-decorator';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { Blacklist } from '@/interfaces/blacklist';
import { globalFunctions } from '@/util/global-functions';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';

@Component({
  components: {
    BaseModalContainer,
  },
})
export default class ModalBlacklistRemove extends Vue {
  loading = true;
  submitting = false;
  error = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  initializeModal() {
    this.loading = true;
    if (!this.dataObject) {
      this.hideModal();
      return;
    }
    this.loading = false;
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  get modalId(): string {
    return ModalIds.BLACKLIST_REMOVE_MODAL;
  }

  get dataObject(): Project | Procurement | ProcurementReference {
    return this.$vmx.modal.blacklistObject;
  }

  get blacklist() {
    const isProcurement = globalFunctions.isProcurementOrProcurementReference(this.dataObject);
    return this.$vmx.blacklist.blacklists
      ?.filter((blacklist: Blacklist) =>
        isProcurement || (blacklist?.projectId === this.dataObject.id),
      )
      ?.filter((blacklist: Blacklist) =>
        !isProcurement || (blacklist?.procurementId === this.dataObject.id),
      )
      ?.pop() ?? null;
  }

  async deleteBlacklist() {
    if (this.blacklist) {
      this.submitting = true;
      try {
        await this.$vmx.blacklist.deleteBlacklist(this.blacklist.id);
        this.error = false;
        this.hideModal();
      } catch (error) {
        this.error = true;
        this.submitting = false;
      }
    } else {
      this.error = true;
    }
  }

  openBlacklistSettingsModal() {
    this.$vmx.modal.openBlacklistSettingsModal(this.dataObject);
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }
}
