




















































































import { Component, Vue } from 'vue-property-decorator';
import DropdownProfileContent from '@/components/dropdowns/dropdown-profile-content.vue';
import DropdownProfileLimitedContent from '@/components/dropdowns/dropdown-profile-limited-content.vue';
import FormLogin from '@/components/forms/form-login.vue';
import HeaderDefault from '@/components/headers/header-default.vue';
import HeaderLoggedIn from '@/components/headers/header-logged-in.vue';
import { RouterNames } from '@/util/enums/router-names';
import { RoutingButton } from '@/interfaces/non-entities/routing-button';

@Component({
  components: {
    HeaderDefault,
    HeaderLoggedIn,
    FormLogin,
    DropdownProfileContent,
    DropdownProfileLimitedContent,
  },
})
export default class TheHeader extends Vue {
  showLogin = false;
  viewDefaultLogin = false;

  changeToLogin() {
    this.viewDefaultLogin = true;
  }

  get header() {
    if (this.$route.meta.header != null && this.$route.meta.header == 'compact') {
      return HeaderLoggedIn;
    } 
    return HeaderDefault;
  }

  get routingButtons() {
    if (this.$vmx.session.isLoggedinUser) {
      return this.loggedInRoutingButtons;
    } else {
      return this.defaultRoutingButtons;
    }
  }

  formLoginClick() {
    if (this.$vmx.session.isLoggedInLimitedAccess) {
      this.viewDefaultLogin = !this.viewDefaultLogin;
    } else {
      this.showLogin = false
    }
  }

  setShowLogin(val: boolean) {
    this.showLogin = !this.showLogin;
  }

  loggedInRoutingButtons: RoutingButton[] = [
    {
      title: 'pages.dashboard.title',
      name: RouterNames.LOGGED_IN_DASHBOARD,
    },
    {
      title: 'pages.blacklist.title',
      name: RouterNames.LOGGED_IN_BLACKLIST_VIEW,
    },
    {
      title: 'pages.search.title',
      name: RouterNames.LOGGED_IN_SEARCH,
      params: { page: '0' },
    },
    {
      title: 'pages.companySearch.title',
      name: RouterNames.LOGGED_IN_COMPANY_SEARCH,
    },
  ]

  defaultRoutingButtons: RoutingButton[] = [
    {
      title: 'pages.services.title',
      name: RouterNames.LOGGED_OUT_SERVICES,
    },
    {
      title: 'pages.prices.title',
      name: RouterNames.LOGGED_OUT_PRICES,
    },
    {
      title: 'pages.about.title',
      name: RouterNames.LOGGED_OUT_ABOUT,
    },
    {
      title: 'pages.bookDemo.title',
      name: RouterNames.LOGGED_OUT_BOOK_DEMO,
      marginClass: 'ms-md-auto',
    },
  ]
}
