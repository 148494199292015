export class ResponseCodes {

  public static readonly SUCCESS = 200;

  public static readonly BAD_REQUEST = 400;
  public static readonly UNAUTHORIZED = 401;
  public static readonly FORBIDDEN = 403;
  public static readonly NOT_FOUND = 404;
  public static readonly CONFLICT = 409;
  public static readonly INVALID_TOKEN = 498;

  public static readonly INTERNAL_SERVER_ERROR = 500;

  // Custom Responses
  public static readonly ACCESS_RUN_OUT = 480;      // No more views
  public static readonly NOT_CORRECT_SESSION = 481; // Session does not match user session
  public static readonly SESSION_NULL = 482         // "Session is null"

  public static is2xx = (responseCode: number) => {
    return responseCode >= 200 && responseCode < 300;
  };
}