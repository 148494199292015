import { action } from 'vuex-class-component';
import { createModule } from '../preloader';
import { globalFunctions } from '@/util/global-functions';
import { List } from '@/interfaces/list';
import { listService } from '@/services/restapi/list-service';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';

export default class ListStore extends createModule('list') {
  private listsData = null as List[];
  private listsPromise = null as Promise<List[]>;

  get lists(): List[] {
    return this.listsData;
  }

  get isInList(): (searchItem: Project | Procurement | ProcurementReference) => boolean {
    return (dataObject) => {
      for (const list of this.lists ?? []) {
        if ((dataObject as Procurement).procurementPhase !== undefined) {
          if (
            list.procurements &&
            list.procurements.some((procLog) => procLog.id === dataObject.id)
          ) {
            return true;
          }
        } else {
          if (list.projects && list.projects.some((projLog) => projLog.id === dataObject.id)) {
            return true;
          }
        }
      }
      return false;
    };
  }

  get isIdInList(): (id: number, isProject: boolean) => boolean {
    return (id, isProject) => {
      for (const list of this.lists ?? []) {
        if (isProject) {
          return list.projects ? list.projects.some(proj => proj.id === id) : false;
        } else {
          return list.procurements ? list.procurements.some(proc => proc.id === id) : false;
        }
      }
    };
  }

  get listById(): (id: number) => List {
    return (id) => {
      if (this.lists === null) {
        return null;
      }
      for (const list of this.lists) {
        if (list.id == id) {
          return list;
        }
      }
      return null;
    };
  }

  get listsWithObject(): (dataObject: Procurement | Project | ProcurementReference) => List[] {
    return (dataObject) => {
      return this.lists.filter((list) => {
        if (
          globalFunctions.isOfInterfaceType<Procurement | ProcurementReference>(
            dataObject,
            'procurementPhase',
          )
        ) {
          return (
            list.procurements && list.procurements.some((procLog) => procLog.id === dataObject.id)
          );
        } else {
          return list.projects && list.projects.some((projLog) => projLog.id === dataObject.id);
        }
      });
    };
  }

  get listsLoaded(): boolean {
    return this.lists != null;
  }

  @action
  async putList(list: List): Promise<List> {
    const newList = await listService.putList(list);

    this.listsPromise = listService.getLists();
    this.listsData = await this.listsPromise;

    return newList;
  }

  @action
  async refetchLists() {
    this.listsPromise = listService.getLists();
    this.listsData = await this.listsPromise;

    return this.listsData;
  }

  @action
  async fetchLists() {
    if (this.listsData != null) {
      return this.listsData;
    }

    if (this.listsPromise != null) {
      return await this.listsPromise;
    }

    this.listsPromise = listService.getLists();
    this.listsData = await this.listsPromise;

    return this.listsData;
  }
}
