

































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseInputSelect extends Vue {
  @VModel({ required: true }) model: string | string[]
  @Prop({ default: null }) options: string[];

  @Prop({ default: 'primary' }) variant: string;
  @Prop({ default: '2' }) buttonPaddingY: string;
  @Prop({ default: '3' }) buttonPaddingX: string;
  @Prop({ default: () => {[]} }) labelClass: string[];
  // @Prop({ default: false }) disabled: boolean;

  get multiSelect() {
    return this.model.constructor.name === 'Array'
  }

  get disabled() {
    return this.model == null;
  }

  get optionsValues() {
    if (this.options != null) {
      return this.options;
    }
    return Object.keys(this.$slots);
  }

  isChecked(val: string) {
    if (this.disabled) {
      return;
    }

    if (!this.multiSelect) {
      return val == this.model;
    } else {
      return (this.model as string[]).some((val0) => val === val0);
    }
  }

  clicked(val: string) {
    if (this.disabled) {
      return;
    }

    if (!this.multiSelect) {
      this.model = val;
    } else if ((this.model as string[]).some((val0) => val0 === val)) {
      this.model = (this.model as string[]).filter((val0) => val0 !== val);
    } else {
      this.model = [ ...this.model, val ];
    }
  }
}
