import { ActivityLog } from '@/interfaces/non-entities/activity-log';
import { BuildingDate } from '@/interfaces/building-date';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';
import { Quarter } from '@/interfaces/quarter';
import { Update } from '@/interfaces/update';
import { updateService } from '@/services/restapi/update-service';

export const globalFunctions = {
  isProcurement(data: Procurement | Project): data is Procurement {
    return (data as Procurement).procurementPhase !== undefined;
  },

  isProcurementOrProcurementReference(
    data: ProcurementReference | Project | Procurement,
  ): data is ProcurementReference | Procurement {
    return (data as ProcurementReference).procurementPhase !== undefined;
  },

  isProcurementAndNotProcurementReference(
    data: ProcurementReference | Procurement,
  ): data is Procurement {
    return (data as Procurement).procurementPhaseRecords !== undefined;
  },

  timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  buildingDateFromString(str: string): BuildingDate {
    const splittedString: string[] = str.split(' ');
    let quarter: Quarter;
    if (splittedString[0] === 'Q1') {
      quarter = Quarter.FIRST_QUARTER;
    } else if (splittedString[0] === 'Q2') {
      quarter = Quarter.SECOND_QUARTER;
    } else if (splittedString[0] === 'Q3') {
      quarter = Quarter.THIRD_QUARTER;
    } else {
      quarter = Quarter.FOURTH_QUARTER;
    }
    return {
      quarter: quarter,
      year: parseInt(splittedString[1]),
    };
  },

  findLastUpdate(updates: Update[]): string {
    if (updates && updates.length > 0) {
      return updates
        .map((u) => {
          return u.created;
        })
        .sort()
        .reverse()[0]
        .toString();
    } else {
      return undefined;
    }
  },

  isOfInterfaceType<Type>(object: any, ...propertyNames: string[]): object is Type {
    for (const propertyName of propertyNames) {
      if (!(propertyName in object)) {
        return false;
      }
    }
    return true;
  },

  getPaywallModalId(): string {
    return 'paywall-modal';
  },
  
  async createActivityLogFromItem(item: Project | Procurement | ProcurementReference): Promise<ActivityLog> {
    const isProcurement = globalFunctions.isProcurementOrProcurementReference(item);
    const updates = isProcurement
      ? await updateService.getUpdatesByProcurementId(item.id)
      : await updateService.getUpdatesByProjectId(item.id);
    return {
      id: item.id,
      isProcurement,
      name: item.name,
      updates,
    };
  },
};
