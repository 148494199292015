






















import { Component, Prop, Ref, VModel, Vue } from 'vue-property-decorator';

let uid = 0;

@Component
export default class BaseInputCheckbox extends Vue {
  @VModel() selected: boolean;
  @Prop({ default: 'Missing Text' }) text: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: null }) onClick: (value?: Boolean) => void;
  @Ref() input: HTMLInputElement;

  click(el: any) {
    if (this.onClick) this.onClick(el.target.checked);
    this.$emit(el.target.checked ? 'checked' : 'unchecked');
  }

  get uid(): string {
    uid += 1;
    return 'checkbox' + uid;
  }
}
