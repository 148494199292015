






























import { Component, Vue } from 'vue-property-decorator';
import FormLogin from '@/components/forms/form-login.vue';

@Component({
  components: {
    FormLogin,
  },
})
export default class DropdownLogin extends Vue {
  canBeClosed = false;
}
