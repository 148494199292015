






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { formatUtils } from '@/util/format-utils';
import { ProcurementUpdate } from '@/interfaces/procurement-update';

@Component({
  components: {
    BaseModalContainer,
  },
})
export default class ModalAnnouncementInfo extends Vue {
  @Prop({ required: true }) modalId: string;
  @Prop({ required: true }) announcement: ProcurementUpdate;
  loading = true;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
  }

  initializeModal() {
    this.loading = false;
    // Do stuff
  }

  public formatAnnouncementChanges(changes: string) {
    if (!changes || changes.trim().length === 0) {
      return `(${this.$t('pages.procurement.announcements.noChangeText')})`;
    } else {
      return this.formatTextToHtml(changes);
    }
  }

  public formatTextToHtml(text: string) {
    return formatUtils.formatTextToHtml(text);
  }

  public formatDate(date: string): string {
    return formatUtils.formatDate(date, 'DD.MM.YYYY');
  }
}
