import BaseAlert from '@/components/containers/base-alert.vue';
import BaseButton from '@/components/buttons/base-button.vue';
import BaseButtonIcon from '@/components/buttons/base-button-icon.vue';
import BaseContainer from '@/components/containers/base-container.vue';
import BaseInputCheckbox from '@/components/form-fields/base-input-checkbox.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import BaseInputRadio from '@/components/form-fields/base-input-radio.vue';
import BaseInputSelect from '@/components/form-fields/base-input-select.vue';
import BaseInputTextarea from '@/components/form-fields/base-input-textarea.vue';
import BaseLoadingSpinner from '@/components/general-components/base-loading-spinner.vue';
import CriteriaTree from '@/components/search-components/criteria-tree.vue';
import CriterionTree from '@/components/search-components/criterion-tree.vue';
import InputDatetime from '@/components/form-fields/input-datetime.vue';
import InputPhone from '@/components/form-fields/input-phone.vue';
import InputTags from '@/components/form-fields/input-tags.vue';
import Vue from 'vue';

export default function () {
  // BUTTONS
  Vue.component('BaseButton', BaseButton);
  Vue.component('BaseButtonIcon', BaseButtonIcon);

  // CONTAINERS
  Vue.component('BaseAlert', BaseAlert);
  Vue.component('BaseContainer', BaseContainer);

  // INPUT COMPONENTS
  Vue.component('BaseInputCheckbox', BaseInputCheckbox);
  Vue.component('BaseInputField', BaseInputField);
  Vue.component('BaseInputRadio', BaseInputRadio);
  Vue.component('BaseInputSelect', BaseInputSelect);
  Vue.component('BaseInputTextarea', BaseInputTextarea);
  Vue.component('InputPhone', InputPhone);
  Vue.component('InputDatetime', InputDatetime);
  Vue.component('InputTags', InputTags);

  // OTHER
  Vue.component('BaseLoadingSpinner', BaseLoadingSpinner);

  // RECURSIVE COMPONENTS
  // Tree
  Vue.component('CriteriaTree', CriteriaTree);
  Vue.component('CriterionTree', CriterionTree);
}
