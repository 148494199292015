import { Component, Vue } from 'vue-property-decorator';
import FormContact from '@/components/forms/form-contact.vue';
import TheHeader from '@/components/headers/the-header.vue';

@Component({
  components: {
    FormContact,
    TheHeader,
  },
})
export default class AboutView extends Vue {
  goTo(anchorName: string) {
    const anchor = document.querySelector(anchorName);
    anchor.scrollIntoView();
  }

  getFromFile(fileName: string) {
    return this.$vmx.file.fromFile('project-agent/' + fileName);
  }
}
