import { createModule, preserve } from '../preloader';
import { getStorage, removeStorage, setStorage } from '../storage';
import { action } from 'vuex-class-component';
import { authService } from '@/services/restapi/auth-service';
import { LoginDetails } from '@/interfaces/non-entities/login-details';
import { User } from '@/interfaces/user';
import { userService } from '@/services/restapi/user-service';

export default class UserStore extends createModule('user') {
  private userData = null as User;
  private userPromise = null as Promise<User>;

  private colleaguesData = null as User[];
  private colleaguesPromise = null as Promise<User[]>;

  get user() {
    if (this.userPromise == null) {
      this.fetchUser();
    }

    if (this.userData == null) {
      return getStorage('user');
    }

    return this.userData;
  }

  get colleagues(): User[] {
    return this.colleaguesData;
  }

  get hasColleagues(): boolean {
    return (
      this.colleaguesData != null &&
      this.colleaguesData.some((colleague) => colleague.id !== this.user?.id)
    );
  }

  get hasCompanyAccess(): boolean {
    return this.user?.product?.companyEnabled ?? false;
  }

  get hasProduct(): boolean {
    return this.user != null ? !this.user?.product.basic ?? false : false;
  }

  @action
  async fetchUser(): Promise<User> {
    if (this.userData != null) {
      return this.userData;
    }

    if (this.userPromise != null) {
      return this.userPromise;
    }

    this.userPromise = userService.getUser();
    this.userPromise.then(this.setUser);

    if (getStorage('user') != null) {
      const user = getStorage('user');
      this.setUser(user);
      return user;
    }

    return this.userPromise;
  }

  @action
  async login(loginDetails: LoginDetails): Promise<User> {
    const user = await authService.login(loginDetails.username, loginDetails.password, loginDetails.rememberMe);
    this.setUser(user);
    return user;
  }

  @action
  async impersonate(args: { username: string; password: string; token: string }) {
    const user = await authService.impersonate(args.username, args.password, args.token);
    this.setUser(user);
    return user;
  }

  @action
  async putUser(user: User): Promise<User> {
    const updatedUser = await userService.putUser(user);
    this.setUser(updatedUser);
    return updatedUser;
  }

  @action
  async updateEmail(payload: { email: string; code: string }): Promise<void> {
    await userService.updateEmail(payload.email, payload.code);

    this.setUser({
      ...this.user,
      mail: payload.email,
    });

    return null;
  }

  @action
  async clearUser() {
    this.userData = null;
    removeStorage('user');
  }

  @action
  async setColleagues(users: User[]) {
    this.colleaguesData = users;
  }

  @action
  async fetchColleagues(): Promise<User[]> {
    return (
      this.colleaguesData ??
      preserve(this.colleaguesPromise, userService.getUsersInCompany, this.setColleagues)
    );
  }

  @action
  private async setUser(user: User): Promise<void> {
    this.userData = user;
    setStorage('user', user);
    return;
  }
}
