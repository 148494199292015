



















import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

let uid = 0;

@Component
export default class BaseInputRadio extends Vue {
  @VModel() selected: any;
  @Prop({required: true}) radioValue: any;
  @Prop({required: true}) text: string;
  @Prop({ default: false }) disabled: boolean;

  get uid(): string {
    uid += 1;
    return 'radio' + uid;
  }
}
