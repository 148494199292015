







































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import FormLogin from '@/components/forms/form-login.vue';
import { globalFunctions } from '@/util/global-functions';
import { userService } from '@/services/restapi/user-service';


@Component({
  components: {
    AbstractForm,
    FormLogin,
  },
})
export default class FormChangePassword extends Vue {
  @Prop({required: true}) token: string;
  @Prop({ default: 'white' }) backgroundColor: string;

  @Ref() inputPassword: BaseInputField;
  @Ref() inputPasswordAgain: BaseInputField;
  @Ref() abstractForm: AbstractForm;

  password1 = '';
  password2 = '';
  passwordSaved = false;

  formIsValid(): boolean {
    let isValid = this.inputPassword.validate();
    isValid = this.inputPasswordAgain.validate() && isValid;
    return isValid;
  }

  isValidSecondPassword (val: string): boolean {
    return val === this.password1;
  }

  async submit() {
    this.abstractForm.setValidated(true);
    if (this.formIsValid()) {
      userService.changePassword(this.password1, this.token).then(() => {
        this.passwordSaved = true;
        if (this.$vmx.session.isLoggedIn) {
          globalFunctions.timeout(2000).then(() => {
            this.$router.push('/');
          })
        }
      });
    }
  }
}
