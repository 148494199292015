

















































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component
export default class SearchInput extends Vue {
  @VModel() text: string;
  @Prop({default: ''}) placeholder: string;
  @Prop({default: 'md'}) size: string;
  @Prop({default: 'transparent'}) backgroundColor: string;
  @Prop({default: false, type: Boolean}) button: string;
  @Prop({default: true, type: Boolean}) borderBottom: string;
  @Prop({ default: true }) routeOnFilter: boolean;

  emitSubmit() {
    this.$emit('submit');
  }

  emitNotTyping() {
    this.$emit('notTyping');
  }

  clear() {
    this.$emit('input', '');
    this.$emit('submit');
  }
}
