







































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import { ContactInfo } from '@/interfaces/non-entities/contact-info';
import { LoginDetails } from '@/interfaces/non-entities/login-details';
import { ResponseCodes } from '@/util/enums/response-codes';
import { RouterNames } from '@/util/enums/router-names';
import { userService } from '@/services/restapi/user-service';

@Component({
  components: {
    AbstractForm,
  },
})
export default class FormLogin extends Vue {
  @Prop() contactInfo: ContactInfo
  @Prop({ default: null }) onSubmit: () => Promise<void>;
  @Prop({ default: 'white '}) textColor: string;
  @Prop() backgroundColor: string;
  @Prop({ default: '5' }) paddingX: string;
  @Prop({ default: '5' }) paddingY: string;
  @Prop({ default: RouterNames.LOGGED_IN_DASHBOARD }) redirect: RouterNames | null;
  @Prop() bodyText: string;
  @Prop({ default: false }) centerHeader: boolean;

  @Ref() inputName: BaseInputField;
  @Ref() inputPassword: BaseInputField;
  @Ref() inputEmail: BaseInputField;

  loginDetails: LoginDetails = {
    username: '',
    password: '',
    rememberMe: false,
  };

  email = '';
  unauthorized = false;
  showError = false;
  showForgotPassword = false;
  mailSent = false;
  mailError = false;

  mounted() {
    this.loginDetails.redirect = this.redirect;
  }

  get alertType(): string {
    if (this.mailSent) {
      return 'success';
    } else if (this.mailError) {
      return 'danger'
    }
    return 'primary';
  }

  get alertMessage(): string {
    if (this.mailSent) {
      return this.$t('components.login.forgotten.success') as string;
    } else if (this.mailError) {
      return this.$t('components.login.error') as string;
    }
    return this.$t('components.login.forgotten.text') as string;
  }

  get formIsValid(): boolean {
    let isValid = this.inputName.validate();
    isValid = this.inputPassword.validate() && isValid;
    return isValid;
  }

  async login() {
    if (this.formIsValid) {
      try {
        await this.$vmx.session.loginIn(this.loginDetails);
        this.unauthorized = false;
        this.showError = false;
      } catch (errorCode) {
        this.showError = true;
        if (errorCode !== ResponseCodes.INTERNAL_SERVER_ERROR) {
          this.unauthorized = true;
        }
      }
    }
  }

  async sendResetEmail() {
    if (this.inputEmail.validate()) {
      userService.sendResetPasswordEmail(this.email).then(
        () => { // success
          this.mailSent = true;
          this.mailError = false;
        },
        () => { // error
          this.mailSent = false;
          this.mailError = true;
        },
      );
    }
  }

}
