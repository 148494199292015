




































import { Component, Prop, Ref, VModel, Vue } from 'vue-property-decorator';

@Component
export default class BaseInputTextarea extends Vue {
  @VModel() text: string;
  @Prop({ required: true }) id: string;
  @Prop() label: string;
  @Prop() inputGroupTextBefore: string;
  @Prop() inputGroupTextAfter: string;
  @Prop() placeholder: string;
  @Prop() invalidText: string;
  @Prop({ default: false }) required: boolean
  @Prop({ default: null }) validator: (val: string) => boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: 3 }) rows: number;
  @Ref() textArea: HTMLTextAreaElement;

  public validate(): boolean {
    let isValid = true;
    if (this.required) {
      isValid = !!this.text && this.text.length > 0;
    }
    if (this.validator != null) {
      isValid = isValid && this.validator(this.text);
    }
    this.setValidClass(isValid);
    return isValid;
  }

  public setValidClass(isValid: boolean) {
    if (isValid != null && isValid != undefined) {
      this.textArea.classList.add(isValid ? 'is-valid' : 'is-invalid');
      this.textArea.classList.remove(isValid ? 'is-invalid' : 'is-valid');
    } else {
      this.textArea.classList.remove('is-valid');
      this.textArea.classList.remove('is-invalid');
    }
  }
}
