import { createProxy, extractVuexModule } from 'vuex-class-component';
import AgentStore from './modules/agent-store';
import BlacklistStore from './modules/blacklist-store';
import CompanyAgentStore from './modules/company-agent-store';
import CompanyListStore from './modules/company-list-store';
import CountryStore from './modules/country-store';
import CriterionStore from './modules/criterion-store';
import DomainStore from './modules/domain-store';
import FileStore from './modules/file-store';
import LanguageStore from './modules/language-store';
import LimitedAccessStore from './modules/limited-access-store';
import ListStore from './modules/list-store';
import ModalStore from './modules/modal-store';
import ProcurementPhaseStore from './modules/procurement-phase-store';
import ProcurementTypeStore from './modules/procurement-type-store';
import ProductStore from './modules/product-store';
import ProjectPhaseStore from './modules/project-phase-store';
import RoleStore from './modules/role-store';
import SearchCompanyStore from './modules/search-company-store';
import SearchStore from './modules/search-store';
import SessionStore from './modules/session-store';
import UserStore from './modules/user-store';
import Vue from 'vue';
import Vuex from 'vuex';
('./modules/session-store');

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    ...extractVuexModule(AgentStore),
    ...extractVuexModule(BlacklistStore),
    ...extractVuexModule(CompanyAgentStore),
    ...extractVuexModule(CompanyListStore),
    ...extractVuexModule(CountryStore),
    ...extractVuexModule(CriterionStore),
    ...extractVuexModule(DomainStore),
    ...extractVuexModule(FileStore),
    ...extractVuexModule(LanguageStore),
    ...extractVuexModule(LimitedAccessStore),
    ...extractVuexModule(ListStore),
    ...extractVuexModule(ModalStore),
    ...extractVuexModule(ProcurementPhaseStore),
    ...extractVuexModule(ProcurementTypeStore),
    ...extractVuexModule(ProductStore),
    ...extractVuexModule(ProjectPhaseStore),
    ...extractVuexModule(RoleStore),
    ...extractVuexModule(SearchCompanyStore),
    ...extractVuexModule(SearchStore),
    ...extractVuexModule(SessionStore),
    ...extractVuexModule(UserStore),
  },
});

export const vmx = {
  agent: createProxy(store, AgentStore),
  blacklist: createProxy(store, BlacklistStore),
  companyAgent: createProxy(store, CompanyAgentStore),
  companyList: createProxy(store, CompanyListStore),
  country: createProxy(store, CountryStore),
  criterion: createProxy(store, CriterionStore),
  domain: createProxy(store, DomainStore),
  file: createProxy(store, FileStore),
  language: createProxy(store, LanguageStore),
  limitedAccess: createProxy(store, LimitedAccessStore),
  list: createProxy(store, ListStore),
  modal: createProxy(store, ModalStore),
  procurementPhase: createProxy(store, ProcurementPhaseStore),
  procurementType: createProxy(store, ProcurementTypeStore),
  product: createProxy(store, ProductStore),
  projectPhase: createProxy(store, ProjectPhaseStore),
  role: createProxy(store, RoleStore),
  search: createProxy(store, SearchStore),
  searchCompany: createProxy(store, SearchCompanyStore),
  session: createProxy(store, SessionStore),
  user: createProxy(store, UserStore),
};
