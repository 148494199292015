































import { Component, Prop, Vue } from 'vue-property-decorator';
import { File } from '@/interfaces/file';
import { ProjectReference } from '@/interfaces/project-reference';
import { RouterNames } from '@/util/enums/router-names';

/*
  We handle clicks in this card differently to support horizontal drag
*/
@Component
export default class ProjectReferenceCard extends Vue {
  @Prop({required: true}) project: ProjectReference;
  private getFromFile = this.$vmx.file.fromFile;

  readonly routeProjectView = RouterNames.LOGGED_IN_PROJECT_VIEW


  get criterionTypeData() {
    return this.project.criteria ?? [];
  }

  public imageFromFile(file: File) {
    if (file) {
      return file.link ? file.link : this.getFromFile(`${file.code}.${file.extension}`);
    } else {
      return this.getFromFile(undefined);
    }
  }

}
