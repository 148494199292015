import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { ResponseCodes } from '@/util/enums/response-codes';
import { User } from '@/interfaces/user';

const api = '/users';

export class UserService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  async getUser(): Promise<User> {
    if (this.getToken() == null || this.getToken().length == 0) {
      return null;
    }

    const response = await this.get<User>(api);
    if (ResponseCodes.is2xx(response.status)) {
      return response.data;
    }

    return null;
  }

  public putUser = async (user: User): Promise<User> => {
    const response = await this.put<User>(api, user);
    return this.getResponse(response);
  };

  public updateEmail = async (newEmail: string, code: string): Promise<any> => {
    const body = {
      newEmail: newEmail,
      code: code,
    };
    const response = await this.put<any>(api + '/updateEmail', body);
    return this.getResponse(response);
  };

  public sendResetPasswordEmail = async (email: string): Promise<any> => {
    const config = {
      params: { email: email },
    };
    const response = await this.post<any>(api + '/passwordReset', null, config);
    return this.getResponse(response);
  };

  public sendChangePasswordEmail = async (): Promise<any> => {
    const response = await this.post<any>(api + '/newPassword');
    return this.getResponse(response);
  };

  public changePassword = async (newPassword: string, resetToken: string): Promise<any> => {
    const body = {
      newPassword: newPassword,
      token: resetToken,
    };
    const response = await this.put<any>(api + '/changePassword', body);
    return this.getResponse(response);
  };

  public sendRequestSubscription = async (
    name: string,
    phone: string,
    email: string,
  ): Promise<any> => {
    const body = { name: name, phone: phone, email: email };
    const response = await this.post<any>(api + '/requestSubscription', body);
    return this.getResponse(response);
  };

  public sendChangeSubscription = async (newSubscription: string): Promise<any> => {
    const response = await this.post<any>(api + '/changeSubscription/' + newSubscription);
    return this.getResponse(response);
  };

  public sendCancelSubscription = async (): Promise<any> => {
    const response = await this.post<any>(api + '/cancelSubscription');
    return this.getResponse(response);
  };

  public sendBookDemo = async (
    name: string,
    phone: string,
    email: string,
    companyName: string,
    date: string,
  ): Promise<any> => {
    const body = { name: name, phone: phone, email: email, date: date, companyName: companyName };
    const response = await this.post<any>(api + '/bookDemo', body);
    return this.getResponse(response);
  };

  public getUsersInCompany = async (): Promise<User[]> => {
    const response = await this.get<User[]>(`${api}/company`);
    return this.getResponse(response);
  };
}

export const userService = new UserService(apiConfig);
