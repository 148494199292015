import { action } from 'vuex-class-component';
import { CompanyAgent } from '@/interfaces/search/company-agent';
import { companyAgentService } from '@/services/restapi/company-agent-service';
import { createModule } from '../preloader';
import { vmx } from '..';

export default class CompanyAgentStore extends createModule('company-agent') {
  private companyAgentsData = null as CompanyAgent[];
  private companyAgentsPromise = null as Promise<CompanyAgent[]>;

  get companyAgents(): CompanyAgent[] {
    return this.companyAgentsData?.sort((agent1, agent2) => agent1.id - agent2.id);
  }

  get companyAgentsLoaded(): boolean {
    return this.companyAgents != null;
  }

  @action
  async fetchCompanyAgents(): Promise<CompanyAgent[]> {
    if (!vmx.user.hasCompanyAccess) {
      return [];
    }
    
    if (this.companyAgentsData != null) {
      return this.companyAgentsData;
    }

    if (this.companyAgentsPromise != null) {
      return this.companyAgentsPromise;
    }

    this.companyAgentsPromise = companyAgentService.getAllCompanyAgentsForUser();
    this.companyAgentsPromise.then(this.setCompanyAgents);

    return this.companyAgentsPromise;
  }

  @action
  async pushCompanyAgent(companyAgent: CompanyAgent): Promise<CompanyAgent> {
    companyAgent.name = companyAgent.name.trim();

    const updatedCompanyAgent = await companyAgentService.updateCompanyAgent(companyAgent);

    this.companyAgentsPromise = companyAgentService.getAllCompanyAgentsForUser();
    this.companyAgentsData = await this.companyAgentsPromise;

    return updatedCompanyAgent;
  }

  @action
  async postCompanyAgent(companyAgent: CompanyAgent): Promise<CompanyAgent> {
    companyAgent.name = companyAgent.name.trim();

    const updatedCompanyAgent = await companyAgentService.createCompanyAgent(companyAgent);

    this.companyAgentsPromise = companyAgentService.getAllCompanyAgentsForUser();
    this.companyAgentsData = await this.companyAgentsPromise;

    return updatedCompanyAgent;
  }

  @action
  async deleteCompanyAgent(companyAgent: CompanyAgent): Promise<void> {
    await companyAgentService.deleteCompanyAgent(companyAgent.id);

    if (vmx.searchCompany.companyAgent.id == companyAgent.id) {
      vmx.searchCompany.resetCompanyAgent();
    }

    await companyAgentService.getAllCompanyAgentsForUser().then(this.setCompanyAgents);
  }

  @action
  private async setCompanyAgents(companyAgents: CompanyAgent[]) {
    this.companyAgentsData = companyAgents.sort((a1, a2) => a1.name.localeCompare(a2.name));
  }
}