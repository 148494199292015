import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { ArticleLink } from '@/interfaces/article-link';
import { AxiosRequestConfig } from 'axios';
import { List } from '@/interfaces/list';
import { Procurement } from '@/interfaces/procurement';
import { Project } from '@/interfaces/project';

const api = '/lists';

export class ListService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getLists = async (): Promise<List[]> => {
    const response = await this.get<List[]>(api);
    return this.getResponse(response);
  };

  public findRelevantArticles = async (limit: number): Promise<ArticleLink[]> => {
    const response = await this.get<ArticleLink[]>(api + '/articles', { params: { limit: limit } });
    return this.getResponse(response);
  };

  public postList = async (list: List): Promise<List> => {
    const response = await this.post<List>(api, list);
    return this.getResponse(response);
  };

  public putList = async (list: List): Promise<List> => {
    const response = await this.put<List>(api, list);
    return this.getResponse(response);
  };

  public deleteList = async (id: number): Promise<List> => {
    const response = await this.delete<List>(api + '/' + id);
    return this.getResponse(response);
  };

  public getListItems = async (id: number): Promise<(Project | Procurement)[]> => {
    const response = await this.get<(Project | Procurement)[]>(`${api}/items/${id}`);
    return this.getResponse(response);
  };

  public setListSeen = async (id: number): Promise<List> => {
    const response = await this.put<List>(`${api}/setSeen/${id}`);
    return this.getResponse(response);
  }
}

export const listService = new ListService(apiConfig);
