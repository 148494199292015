






























































































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import DropdownMailExport from '@/components/dropdowns/dropdown-mail-export.vue';
import { formatUtils } from '@/util/format-utils';
import { Modal } from 'bootstrap';
import { Note } from '@/interfaces/note';

@Component({
  components: {
    DropdownMailExport,
  },
})
export default class NoteList extends Vue {
  @VModel({ required: true }) selectedNote: Note;
  @Prop({ required: true }) notes: Note[];
  @Prop({ required: true }) id: string;
  @Prop() limit: number;
  @Prop({ default: true }) canShowAll: boolean;
  @Prop({ default: true }) canCreate: boolean;
  collapsed = true;

  mounted() {
    if (!this.notes) {
      this.notes = [];
    }
  }

  get editModalId() {
    return `edit-${this.id}`;
  }

  get deleteModalId() {
    return `delete-${this.id}`;
  }

  get limitedNotes(): Note[] {
    if (this.collapsed) {
      if (this.hasMore) {
        return this.notes.slice(0, this.limit);
      }
      return this.notes;
    } else {
      return this.notes;
    }
  }

  get hasMore() {
    return this.limit && this.limit < this.notes.length;
  }

  get excessNotes(): number {
    return this.limit < this.notes.length ? this.notes.length - this.limit : 0;
  }

  get now(): string {
    return formatUtils.formatDate(new Date(), 'YYYY-MM-DD HH:mm')
  }

  private mailHeader(note: Note): string {
    return note.title ? note.title : `Note: ${note.createdBy}`;
  }

  private downloadName(note: Note) {
    return `${note.title ? note.title.replaceAll(' ', '_') : 'note_file'}.ics`
  }

  createNote() {
    if (this.$vmx.user.hasProduct) {
      this.selectedNote = null;
      this.openModal(this.editModalId);
    } else {
      this.$vmx.modal.openPaywallModal();
    }
  }

  editNote(id: number) {
    this.selectedNote = this.notes.find(note => note.id ==id);
    this.openModal(this.editModalId);
  }

  openDeleteModal(id: number) {
    this.selectedNote = this.notes.find(note => note.id ==id);
    this.openModal(this.deleteModalId);
  }

  openModal(modalId: string) {
    const element = document.getElementById(modalId);
    const modal = new Modal(element);
    modal.show();
  }

}
