import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Procurement } from '@/interfaces/procurement';

const api = '/procurements';
export class ProcurementService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getProcurementById = async (id: number): Promise<Procurement> => {
    const response = await this.get<Procurement>(`${api}/byId/${id}`);
    return this.getResponse(response);
  };
}

export const procurementService = new ProcurementService(apiConfig);
