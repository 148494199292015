





































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseButton extends Vue {
  @Prop({ default: true }) withSpinner: boolean;
  @Prop() routerParams: Object;
  @Prop() styleClasses: string;
  @Prop({ default: 'primary' }) variant: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: '2' }) paddingY: string;
  @Prop({ default: '3' }) paddingX: string;
  @Prop({ default: false }) showBadge: boolean;
  @Prop() badgeText: string;
  @Prop({ default: 'danger'}) badgeColor: string;
  @Prop({ default: null }) onClickCb: () => Promise<void>;
  @Prop({ default: 'button' }) type: 'button' | 'submit' | 'reset';
  @Prop() bsToggle: string;
  @Prop() bsDismiss: string;
  @Prop() bsTarget: string;
  @Prop() bsAutoClose: string;
  @Prop({ default: false }) isDropup: boolean;
  public isClicked = false;

  get styleClassObj() {
    return [
      `py-${this.paddingY}`,
      `px-${this.paddingX}`,
      `btn-${this.variant}`,
      this.styleClasses,
    ];
  }

  get dropdownClasses() {
    return {
      dropdown: this.bsToggle === 'dropdown' && !this.isDropup,
      dropup: this.bsToggle === 'dropdown' && this.isDropup,
    }
  }

  get badgeClasses() {
    return [
      'position-absolute',
      'top-0',
      'start-100',
      'translate-middle',
      'badge',
      this.badgeText ? '' : 'p-2',
      this.badgeText ? 'rounded-pill' : 'rounded-circle',
      `bg-${this.badgeColor}`,
      this.sameColor ? 'border' : '',
      this.sameColor ? 'border-white' : '',
    ];
  }

  get sameColor() {
    return this.variant === this.badgeColor;
  }

  onClick() {
    if (!this.isClicked) {
      this.isClicked = true;
      this.$emit('click');
      if (this.onClickCb != null) {
        this.onClickCb().finally(() => this.isClicked = false);
      } else {
        this.isClicked = false;
      }
    }
  }

  public stopLoading() {
    this.isClicked = false;
  }
}
