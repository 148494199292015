import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { ResponseCodes } from '@/util/enums/response-codes';
import router from '@/router';
import { RouterNames } from '@/util/enums/router-names';
import { User } from '@/interfaces/user';

const api = '/auth';

export class AuthService extends AbstractService {
  session: number = 24 * 7;
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public login = async (username: string, password: string, rememberMe: boolean): Promise<User> => {
    const body = {
      username,
      password,
    };
    const value = await this.post<User>(api, body);
    if (ResponseCodes.is2xx(value.status) && value.headers.jwt) {
      this.setToken(value.headers.jwt, rememberMe ? this.session : null);
      return value.data;
    }
    throw value.status;
    // return this.post<User>(api, body).then(
    //   (value) => {
    //     if (ResponseCodes.is2xx(value.status) && value.headers.jwt) {
    //       this.setToken(value.headers.jwt, rememberMe ? this.session : null);
    //       return value.data;
    //     }
    //     throw value.status;
    //   },
    //   (error) => {
    //     throw error;
    //   },
    // );
  };

  public isValidToken = async (jwt: string): Promise<boolean> => {
    return this.get<boolean>(api + '/isValidToken/' + jwt).then(
      (value) => {
        return value.data;
      },
      (error) => {
        throw error;
      },
    );
  };

  public impersonate = async (
    adminUsername: string,
    adminPassword: string,
    token: string,
  ): Promise<User> => {
    return this.post<User>(`${api}/impersonate`, { adminUsername, adminPassword, token }).then(
      (value) => {
        if (ResponseCodes.is2xx(value.status) && value.headers.jwt) {
          this.setToken(value.headers.jwt, null);
          localStorage.setItem('user', JSON.stringify(value.data));
          router.push({ name: RouterNames.LOGGED_IN_DASHBOARD });
          location.reload();
          return value.data;
        }
        throw value.status;
      },
      (error) => {
        throw error;
      },
    );
  };
}

export const authService = new AuthService(apiConfig);
