import { AbstractService } from '../superclasses/abstract-service';
import { Agent } from '@/interfaces/agent';
import { AgentStatistics } from '@/interfaces/agent-statistics';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { ResponseCodes } from '@/util/enums/response-codes';
import { SearchItemPage } from '@/interfaces/search-item-page';

const api = '/agents';

export class AgentService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getAgents = async (): Promise<Agent[]> => {
    const response = await this.get<Agent[]>(api);
    return this.getResponse(response);
  };

  public getAgentById = async (id: number): Promise<Agent> => {
    const response = await this.get<Agent>(api + '/' + id);
    return this.getResponse(response);
  };

  public postAgent = async (agent: Agent): Promise<Agent> => {
    const response = await this.post<Agent>(api, agent);
    return this.getResponse(response);
  };

  public putAgent = async (agent: Agent): Promise<Agent> => {
    const response = await this.put<Agent>(api, agent);
    return this.getResponse(response);
  };

  public deleteAgent = async (id: number): Promise<Agent> => {
    const response = await this.delete<Agent>(api + '/' + id);
    return this.getResponse(response);
  };

  public search = async (
    agent: Agent,
    index: number,
    limit: number,
    orderBy: string,
  ): Promise<SearchItemPage> => {
    const config = {
      params: orderBy
        ? { limit: limit, index: index, orderBy: orderBy }
        : { limit: limit, index: index },
    };
    const response = await this.post<SearchItemPage>(api + '/search', agent, config);
    if (response != null && ResponseCodes.is2xx(response.status)) {
      return this.success(response);
    } else {
      throw response;
    }
  };

  public searchCount = async (agent: Agent, maxDaysOld?: number): Promise<number> => {
    let response;
    if (maxDaysOld != null) {
      const config = {
        params: { maxDaysOld: maxDaysOld },
      };
      response = await this.post<number>(`${api}/search/count`, agent, config);
    } else {
      response = await this.post<number>(`${api}/search/count`, agent);
    }
    return this.getResponse(response);
  };

  public agentStatistics = async () : Promise<AgentStatistics[]> => {
    const response = await this.get<AgentStatistics[]>(`${api}/statistics`);
    return this.getResponse(response);
  }
}

export const agentService = new AgentService(apiConfig);
