





































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import InputPhone from '@/components/form-fields/input-phone.vue';
import { userService } from '@/services/restapi/user-service';

@Component({
  components: {
    AbstractForm,
  },
})
export default class FormContact extends Vue {
  @Prop() onSubmit: (name: string, email: string, phone: string) => Promise<void>;
  @Prop({ default: 'white '}) textColor: string;
  @Prop() backgroundColor: string;
  @Prop({ default: '5' }) paddingX: string;
  @Prop({ default: '5' }) paddingY: string;

  @Ref() inputName: BaseInputField;
  @Ref() inputEmail: BaseInputField;
  @Ref() inputPhone: InputPhone;

  name: string = null;
  email: string = null;
  phone: string = null;
  showSent = false;

  liveValidate = 'onlyOnValid'

  get formIsValid(): boolean {
    let isValid = this.inputName.validate();
    isValid = this.inputEmail.validate() && isValid;
    isValid = this.inputPhone.validate() && isValid;
    return isValid;
  }

  setPhoneNumber(formatted: string) {
    this.phone = formatted;
  }

  async submit() {
    if (!this.showSent) {
      if (this.formIsValid) {
        userService.sendRequestSubscription(this.name, this.phone, this.email)
          .then(() => {
            this.showSent = true;
            if (this.onSubmit != null) {
              this.onSubmit(this.name, this.email, this.phone)
                .then(() => this.$emit('success'))
                .catch(() => this.$emit('failure'));
            } else {
              this.$emit('success');
            }
          })
          .catch(() => this.$emit('failure'))
      } else {
        this.liveValidate = 'always'
      }
    }
  }
}
