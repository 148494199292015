import * as domainUtils from '../../util/domain-utils';
import { createModule } from '../preloader';
import { Domain } from '@/interfaces/domain';

export default class DomainStore extends createModule('domain') {
  get domain(): Domain {
    return domainUtils.domain;
  }

  get domains(): Domain[] {
    return domainUtils.domains;
  }
}
