import { render, staticRenderFns } from "./company-lists-overview.vue?vue&type=template&id=5aa02b56&scoped=true&"
import script from "./company-lists-overview.vue?vue&type=script&lang=ts&"
export * from "./company-lists-overview.vue?vue&type=script&lang=ts&"
import style0 from "./company-lists-overview.vue?vue&type=style&index=0&id=5aa02b56&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa02b56",
  null
  
)

export default component.exports