import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { Blacklist } from '@/interfaces/blacklist';
import { globalFunctions } from '@/util/global-functions';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';

const api = '/blacklists';

export class BlacklistService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  // Get all blacklists for users member
  public getBlacklists = async (): Promise<Blacklist[]> => {
    const response = await this.get<Blacklist[]>(api);
    return this.getResponse(response);
  };

  public createBlacklist = async (blacklist: Blacklist): Promise<Blacklist> => {
    const response = await this.post<Blacklist>(api, blacklist);
    return this.getResponse(response);
  };

  // Add blacklist for user
  public editBlacklist = async (blacklist: Blacklist): Promise<Blacklist> => {
    const response = await this.put<Blacklist>(`${api}/${blacklist.id}`, blacklist);
    return this.getResponse(response);
  };

  // Delete blacklist for user
  public deleteBlacklist = async (id: number): Promise<void> => {
    const response = await this.delete<void>(`${api}/${id}`);
    return this.getResponse(response);
  };

  // Get all procurements and project on blacklist for user
  public getAllProcurementsAndProjects = async (): Promise<(Project | Procurement)[]> => {
    const response = await this.get<(Project | Procurement)[]>(api + '/pdata');
    return this.getResponse(response);
  };

  public addToBlacklist = async (
    objectToBlacklist: Project | Procurement | ProcurementReference,
  ): Promise<Blacklist> => {
    if (globalFunctions.isProcurementOrProcurementReference(objectToBlacklist)) {
      return this.addProcurementToBlacklist(objectToBlacklist.id);
    } else {
      return this.addProjectToBlacklist(objectToBlacklist.id);
    }
  };

  public addProjectToBlacklist = async (id: number): Promise<Blacklist> => {
    const response: AxiosResponse<Blacklist> = await this.post<Blacklist>(api + '/project/' + id);
    return this.getResponse(response);
  };

  public addProcurementToBlacklist = async (id: number): Promise<Blacklist> => {
    const response: AxiosResponse<Blacklist> = await this.post<Blacklist>(
      api + '/procurement/' + id,
    );
    return this.getResponse(response);
  };

  public removeFromBlacklist = async (id: number): Promise<Blacklist> => {
    const response = await this.delete<Blacklist>(api + '/' + id);
    return this.getResponse(response);
  };

  public removeProjectFromBlacklist = async (projectId: number): Promise<Blacklist> => {
    const response = await this.delete<Blacklist>(api + '/project/' + projectId);
    return this.getResponse(response);
  };

  public removeProcurementFromBlacklist = async (procurementId: number): Promise<Blacklist> => {
    const response = await this.delete<Blacklist>(api + '/procurement/' + procurementId);
    return this.getResponse(response);
  };
}

export const blacklistService = new BlacklistService(apiConfig);
