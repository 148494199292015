import * as blacklistUtil from '@/util/blacklist-utils';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Agent } from '@/interfaces/agent';
import AgentCriteriaMenu from '@/components/search-components/agent-criteria-menu.vue';
import { Blacklist } from '@/interfaces/blacklist';
import DropdownOrderBy from '@/components/dropdowns/dropdown-order-by.vue';
import ModalAgentReplace from '@/components/modals/modal-agent-replace.vue';
import ModalAgentSettings from '@/components/modals/modal-agent-settings.vue';
import PageNavigationBar from '@/components/search-components/page-navigation-bar.vue';
import { Procurement } from '@/interfaces/procurement';
import { Project } from '@/interfaces/project';
import SearchInput from '@/components/form-fields/search-input.vue';
import SearchItemCard from '@/components/cards/search-item-card.vue';
import SearchItemsListView from '@/components/search-components/search-items-list-view.vue';
import SearchStore from '@/store/modules/search-store';


@Component({
  name: 'searchView',
  components: {
    AgentCriteriaMenu,
    DropdownOrderBy,
    ModalAgentReplace,
    ModalAgentSettings,
    PageNavigationBar,
    SearchInput,
    SearchItemCard,
    SearchItemsListView,
  },
})
export default class SearchView extends Vue {
  @Prop({ required: true }) page: string;
  // private orderBy: TranslateResult = null;
  private isListView = false;


  @Ref() readonly results: Element;

  orderByOptions: [string, string][] = [
    [SearchStore.orderByOptions.published, this.$t('components.sorting.published') as string],
    [SearchStore.orderByOptions.updated, this.$t('components.sorting.updated') as string],
    [SearchStore.orderByOptions.budget, this.$t('components.sorting.contractSum') as string],
    [SearchStore.orderByOptions.bestMatch, this.$t('components.sorting.bestMatch') as string],
  ];

  mounted() {
    const page = Number.parseInt(this.page);

    if (page == 0) {
      this.$router.push({
        params: { page: this.$vmx.search.page.toString() },
      });
    }

    if (page != this.$vmx.search.page) {
      this.$vmx.search.setPage(Number.parseInt(this.page));
    }
  }

  get loading() {
    return this.$vmx.search.searchItemPage?.results == null;
  }

  // Store Getters
  get agent(): Agent {
    return this.$vmx.search.agent;
  }

  get searchItemPage() {
    return this.$vmx.search.searchItemPage;
  }

  get searchItemPageResults() {
    return this.$vmx.search.searchItemPage?.results;
  }

  get totalResults() {
    if (this.$vmx.search.searchItemPage == null) {
      return '-'.repeat(20);
    }
    return this.$tc('pages.search.results', this.$vmx.search.searchItemPage.totalResults, [
      this.$vmx.search.searchItemPage.totalResults,
    ]);
  }

  // FreeSearch
  get freeSearch(): string {
    return this.$vmx.search.agent.freeSearch ?? '';
  }

  set freeSearch(freeSearch: string) {
    this.$vmx.search.setAgent({
      ...this.$vmx.search.agent,
      freeSearch: freeSearch,
    });
  }

  // isProject
  get isProject() {
    return this.agent.isProcurement ? 'procurementsOnly' : 'all';
  }

  set isProject(val) {
    this.onUpdateAgent({
      isProcurement: val === 'procurementsOnly',
    }, null)

    //
    //
    // this.$vmx.search.updateAgent({
    //   isProcurement: val === 'procurementsOnly',
    // });
    //
    // if (this.pageNumber > 1) {
    //   this.pageNumber = 1;
    // }
  }

  get orderBy(): string {
    return this.orderByOptions.find(x => x[0] === this.$vmx.search.orderBy)[1];
  }

  set orderBy(orderBy: string) {
    const index = this.orderByOptions.findIndex(x => x[1] === orderBy)
    this.$vmx.search.setOrderBy(this.orderByOptions[index][0]);
    if (this.pageNumber > 1) {
      this.pageNumber = 1;
    }
  }

  // orderByDescending
  get orderByDesc() {
    if (this.$vmx.search.orderBy == 'bestMatch') {
      return null;
    }
    return this.$vmx.search.orderByDesc ? 'desc' : 'asc';
  }

  set orderByDesc(val) {
    this.$vmx.search.setOrderByDesc(val === 'desc');
    if (this.pageNumber > 1) {
      this.pageNumber = 1;
    }
  }

  // listView

  get listView() {
    return this.isListView ? 'card' : 'list';
  }

  set listView(val) {
    this.isListView = val === 'card';
  }

  // pageNumber
  get pageNumber() {
    return this.$vmx.search.page;
  }

  set pageNumber(page: number) {
    if (page === this.$vmx.search.page) {
      return;
    }

    this.$router
      .push({
        params: { page: page.toString() },
      })
      .then(() => {
        this.results?.scrollIntoView();
      });
  }

  get pageCount() {
    return this.$vmx.search.searchItemPage?.pageCount ?? 0;
  }

  @Watch('page')
  pageChanged() {
    const page = Number.parseInt(this.page);

    if (isNaN(page) || page == this.$vmx.search.page) {
      return;
    }

    if (page == 0) {
      this.$router.push({
        params: { page: this.$vmx.search.page.toString() },
      });
      return;
    }
    this.$vmx.search.setPage(page);
  }

  updateAgent() {
    if (this.pageNumber > 1) {
      this.$router.push({
        params: {
          page: '1',
        },
      });
    }
  }

  filterFunc(val: Procurement | Project): boolean {
    return !blacklistUtil.isItemInBlacklists(val, this.$vmx.blacklist.userBlacklists);
  }

  async onPersistBlacklist(blacklist: Blacklist): Promise<Blacklist> {
    return this.$vmx.blacklist.pushBlacklist(blacklist);
  }

  agentSaved(savedAgent: Agent) {
    this.$vmx.search.setAgent(savedAgent);
  }

  agentDeleted() {
    this.agent.id = null;
  }

  openAgentReplaceModal() {
    this.$vmx.modal.openAgentReplaceModal(this.agent);
  }

  openAgentSettingsModal() {
    this.$vmx.modal.openAgentSettingsModal(this.agent);
  }

  onUpdateAgent(agent: Partial<Agent>, typeId?: number) {
    if (agent.criteria && typeId) {
      this.$vmx.search.updateCriteriaForType({ criteria: agent.criteria, typeId })
    } else {
      this.$vmx.search.updateAgent({
        id: null,
        ...agent,
      });
    }
    if (this.pageNumber > 1) {
      this.pageNumber = 1;
    }
  }
}
