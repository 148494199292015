import { Component, Prop, Vue } from 'vue-property-decorator';
import FormImpersonate from '@/components/forms/form-impersonate.vue';
import TheHeader from '@/components/headers/the-header.vue';

@Component({
  components: {
    FormImpersonate,
    TheHeader,
  },
})
export default class ImpersonateView extends Vue {
  @Prop({ required: true }) token: string;

  getBackground(fileName: string) {
    return 'url(' + this.$vmx.file.fromFile('project-agent/backgrounds/' + fileName) + ')';
  }
}
