











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import AbstractModal from '@/components/modals/abstract-modal.vue';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { Modal } from 'bootstrap';
import { Product } from '@/interfaces/product';
import ProductBox from '@/components/general-components/product-box.vue';
import { userService } from '@/services/restapi/user-service';

@Component({
  components: {
    AbstractModal,
    BaseModalContainer,
    ProductBox,
  },
})
export default class ModalChangeSubscription extends Vue {
  @Prop({ required: true }) modalId: string;
  @Prop() backgroundColor: string;
  @Prop() textColor: string;
  @Prop() fade: boolean;
  @Prop() size: string;
  confirmModal: HTMLElement = null;
  currentProduct: Product;

  chosenProduct: Product = null;
  showConfirm = false;

  mounted() {
    this.currentProduct = this.$vmx.user.user.product;
    this.confirmModal = document.getElementById('confirm-modal');
  }

  confirmToTrue() {
    this.showConfirm = true
  }

  get notCurrentProducts() {
    return this.$vmx.product.products?.filter(x => x.id != this.currentProduct.id) ?? [];
  }

  chooseProduct(product: Product) {
    this.chosenProduct = product;
    this.showConfirmModal(true);
  }

  showConfirmModal(show: boolean) {
    if (this.confirmModal) {
      if (show) {
        const confirm = new Modal(this.confirmModal);
        confirm.show();
      } else {
        const modal = Modal.getInstance(this.confirmModal);
        if (modal) {
          modal.hide();
        }
      }
    }
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  async changeSubscription() {
    userService.sendChangeSubscription(this.chosenProduct.name).then(() => {
      this.$emit('subscriptionSelected', this.chosenProduct.name);
      this.showConfirmModal(false);
      this.hideModal();
    });
  }
}
