import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { ProcurementPhase } from '@/interfaces/criterias/procurement-phase';

const api = '/procurement-phases';

export class ProcurementPhaseService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getProcurementPhases = async (): Promise<ProcurementPhase[]> => {
    const response = await this.get<ProcurementPhase[]>(api);
    return this.getResponse(response);
  };

  public getProcurementPhaseById = async (id: number): Promise<ProcurementPhase> => {
    const response = await this.get<ProcurementPhase>(api + '/' + id);
    return this.getResponse(response);
  };
}

export const procurementPhaseService = new ProcurementPhaseService(apiConfig);
