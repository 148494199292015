




















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Agent } from '@/interfaces/agent';
import BaseFoldMenu from '../buttons/base-fold-menu.vue'
import { CriterionType } from '@/interfaces/criterias/criterion-type';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
    BaseFoldMenu,
  },
})
export default class AgentCriteriaFilter extends Vue {
  @Prop({ required: true }) onUpdateAgent: (agent: Partial<Agent>) => void;

  private readonly A_MILLION = 1000000

  get agent() {
    return this.$vmx.search.agent;
  }

  get getInitialAgent() {
    return this.$vmx.search.getInitialAgent;
  }

  // Reset agent criteria
  get canReset(): boolean {
    if (this.agent == null) {
      return false;
    }

    return [
      this.criteriasCount > 0,
      this.agent.isProcurement != this.getInitialAgent().isProcurement,
      this.agent.freeSearch != this.getInitialAgent().freeSearch,
    ].some((x) => x);
  }

  clearCriteria() {
    // this.$vmx.search.clearAgent();
    this.onUpdateAgent(this.getInitialAgent());
  }

  // Dropdown
  get criterias(): {
    header: TranslateResult,
    items: { text: string | TranslateResult, f: () => Partial<Agent> }[]
  }[] {
    if (this.$vmx.criterion.criterionTypes == null) {
      return [];
    }
    const selectedFilters = this.agent == null ? [] : [
      // projectPhases
      {
        header: this.$t('general.criteria.projectPhase'),
        items: this.projectPhases ?? [],
      },
      // procurementPhases
      {
        header: this.$t('general.criteria.procurementPhase'),
        items: this.procurementPhases ?? [],
      },
      // procurementTypes
      {
        header: this.$tc('general.criteria.procurementType'),
        items: this.procurementTypes ?? [],
      },
      // estimatedSum
      {
        header: this.$tc('pages.search.estimatedSumMillions'),
        items: this.estimatedSum,
      },
      // sizeMetres
      {
        header: this.$tc('general.criteria.sizeMetres'),
        items: this.sizeMetres,
      },
      // sizeBuilding
      {
        header: this.$tc('general.criteria.sizeBuilding'),
        items: this.sizeBuilding,
      },
      // sizeGround
      {
        header: this.$tc('general.criteria.sizeGround'),
        items: this.sizeGround,
      },
      {
        header: this.$tc('general.criteria.numberOfUnits'),
        items: this.numberOfUnits,
      },
      // projectStartEnd
      {
        header: this.$t('general.criteria.startEnd'),
        items: this.projectStartEnd,
      },
      // isPublic
      {
        header: this.$t('pages.search.publicPrivate'),
        items: this.isPublic,
      },
    ];

    for (const criterionType of this.$vmx.criterion.criterionTypes) {
      if (this.getCriteriaOfType(criterionType).length > 0) {
        selectedFilters.push({
          header: this.$tc(criterionType.translationProperty),
          items: this.getCriteriaOfType(criterionType),
        })
      }
    }
    return selectedFilters;
  }


  get criteriasCount() {
    return this.criterias.reduce((acc, criteria) => criteria.items.length + acc, 0)
  }

  // Criterias
  get projectPhases(): { text: string, f: () => Partial<Agent> }[] {
    return this.agent?.isProcurement
      ? []
      : this.agent?.projectPhases?.filter((item) => item != null).map((item) => ({
        text: item.name,
        f: () => ({
          projectPhases: this.agent.projectPhases.filter((item0) => item0.id != item.id),
        }),
      }))
  }

  get procurementPhases(): { text: string, f: () => Partial<Agent> }[] {
    return !this.agent.isProcurement
      ? []
      : this.agent?.procurementPhases?.filter((item) => item != null).map((item) => ({
        text: item.name,
        f: () => ({
          procurementPhases: this.agent.procurementPhases.filter(
            (item0) => item0.id != item.id),
        }),
      }))
  }

  get procurementTypes(): { text: string, f: () => Partial<Agent> }[] {
    return !this.agent?.isProcurement
      ? []
      : this.agent?.procurementTypes?.filter((item) => item != null).map((item) => ({
        text: item.name,
        f: () => ({
          procurementTypes: this.agent.procurementTypes.filter((item0) => item0.id != item.id),
        }),
      }))
  }

  getCriteriaOfType(criterionType: CriterionType): { text: string | TranslateResult, f: () => Partial<Agent> }[] {
    let criteria: { text: string | TranslateResult, f: () => Partial<Agent> }[]
      = this.agent?.criteria?.filter(criterion => criterion != null && criterion.typeId === criterionType.id)
        .map((item) => ({
          text: item.name,
          f: () => ({
            criteria: this.agent.criteria.filter((item0) => item0.id != item.id),
          }),
        })) ?? [];
    if (this.agent?.allowUnusedCriterionTypes?.includes(criterionType.id) ?? false) {
      criteria.push({
        text: this.$t('pages.search.includeUnspecified', [
          this.$tc(criterionType.translationProperty, 1).toLowerCase(),
        ]),
        f: () => ({
          allowUnusedCriterionTypes: this.agent.allowUnusedCriterionTypes.filter((item0) => item0 !== criterionType.id),
          // allowUnusedCriterionTypes: this.agent.allowUnusedCriterionTypes !== undefined && this.agent.allowUnusedCriterionTypes !== null
          //   ? (!this.agent.allowUnusedCriterionTypes.some(ct => ct == criterionType.id)
          //     ? [...this.agent.allowUnusedCriterionTypes].concat(criterionType.id)
          //     : this.agent.allowUnusedCriterionTypes)
          //   : [criterionType.id],
        }),
      });
    }
    return criteria.filter(item => item != null);
  }

  get projectStartEnd(): { text: string | TranslateResult, f: () => Partial<Agent> }[] {
    return [
      ...this.agent.projectStart?.map((item) => ({
        text: `${this.$t('general.criteria.startEndOptions.start')}: ${item}`,
        f: () => ({
          projectStart: this.agent.projectStart.filter((item0) => item != item0 ),
        }),
      })),
      ...this.agent.projectEnd?.map((item) => ({
        text: `${this.$t('general.criteria.startEndOptions.end')}: ${item}`,
        f: () => ({
          projectEnd: this.agent.projectEnd.filter((item0) => item != item0 ),
        }),
      })),
      this.agent?.allowUnspecifiedStartAndEnd == this.getInitialAgent().allowUnspecifiedStartAndEnd
        ? null
        : {
          text: this.$t('pages.search.includeUnspecified', [
            this.$t('general.criteria.startEnd').toString().toLowerCase(),
          ]),
          f: () => ({
            allowUnspecifiedStartAndEnd: this.getInitialAgent().allowUnspecifiedStartAndEnd,
          }),
        },
    ].filter((item) => item != null);
  }

  get estimatedSum(): { text: string | TranslateResult, f: () => Partial<Agent> }[] {
    return [
      this.agent?.minBudget == this.getInitialAgent().minBudget
        ? null
        : {
          text: `Min. ${this.agent.minBudget / this.A_MILLION}`,
          f: () => ({
            minBudget: this.getInitialAgent().minBudget,
          }),
        },
      this.agent?.maxBudget == this.getInitialAgent().maxBudget
        ? null
        : {
          text: `Max. ${this.agent.maxBudget / this.A_MILLION}`,
          f: () => ({
            maxBudget: this.getInitialAgent().maxBudget,
          }),
        },
      this.agent?.allowUnspecifiedBudget == this.getInitialAgent().allowUnspecifiedBudget
        ? null
        : {
          text: this.$t('pages.search.includeUnspecified', [
            this.$t('general.criteria.estimatedSum').toString().toLowerCase(),
          ]),
          f: () => ({
            allowUnspecifiedBudget: this.getInitialAgent().allowUnspecifiedBudget,
          }),
        },
    ].filter((item) => item != null)
  }

  get sizeMetres(): { text: TranslateResult, f: () => Partial<Agent> }[] {
    return [
      this.agent.minSizeMetres == this.getInitialAgent().minSizeMetres ? null : {
        text: `Min. ${this.agent.minSizeMetres}`,
        f: () => ({
          minSizeMetres: this.getInitialAgent().minSizeMetres,
        }),
      },
      this.agent.maxSizeMetres == this.getInitialAgent().maxSizeMetres ? null : {
        text: `Max. ${this.agent.maxSizeMetres}`,
        f: () => ({
          maxSizeMetres: this.getInitialAgent().maxSizeMetres,
        }),
      },
    ].filter((item) => item != null)
  }

  get sizeBuilding(): { text: TranslateResult, f: () => Partial<Agent> }[] {
    return [
      this.agent.minSizeBuilding == this.getInitialAgent().minSizeBuilding ? null : {
        text: `Min. ${this.agent.minSizeBuilding}`,
        f: () => ({
          minSizeBuilding: this.getInitialAgent().minSizeBuilding,
        }),
      },
      this.agent.maxSizeBuilding == this.getInitialAgent().maxSizeBuilding ? null : {
        text: `Max. ${this.agent.maxSizeBuilding}`,
        f: () => ({
          maxSizeBuilding: this.getInitialAgent().maxSizeBuilding,
        }),
      },
      this.agent.allowUnspecifiedSize == this.getInitialAgent().allowUnspecifiedSize
        ? null
        : {
          text: this.$t('pages.search.includeUnspecified', [
            this.$t('general.criteria.sizeBuilding').toString().toLowerCase(),
          ]),
          f: () => ({
            allowUnspecifiedSize: this.getInitialAgent().allowUnspecifiedSize,
          }),
        },
    ].filter((item) => item != null)
  }

  get sizeGround(): { text: string, f: () => Partial<Agent> }[] {
    return [
      this.agent.minSizeGround == this.getInitialAgent().minSizeGround ? null : {
        text: `Min. ${this.agent.minSizeGround}`,
        f: () => ({
          minSizeGround: this.getInitialAgent().minSizeGround,
        }),
      },
      this.agent.maxSizeGround == this.getInitialAgent().maxSizeGround ? null : {
        text: `Max. ${this.agent.maxSizeGround}`,
        f: () => ({
          maxSizeGround: this.getInitialAgent().maxSizeGround,
        }),
      },
    ].filter((item) => item != null)
  }

  get numberOfUnits(): { text: string, f: () => Partial<Agent> }[] {
    return [
      this.agent.minNumberOfUnits == this.getInitialAgent().minNumberOfUnits ? null : {
        text: `Min. ${this.agent.minNumberOfUnits}`,
        f: () => ({
          minNumberOfUnits: this.getInitialAgent().minNumberOfUnits,
        }),
      },
      this.agent.maxNumberOfUnits == this.getInitialAgent().maxNumberOfUnits ? null : {
        text: `Max. ${this.agent.maxNumberOfUnits}`,
        f: () => ({
          maxNumberOfUnits: this.getInitialAgent().maxNumberOfUnits,
        }),
      },
    ].filter((item) => item != null)
  }

  get isPublic(): { text: string, f: () => Partial<Agent> }[] {
    return this.agent.isPublic == this.getInitialAgent().isPublic ? [] : [
      {
        text: this.agent.isPublic
          ? this.$t('pages.search.publicPrivateOptions.public').toString()
          : this.$t('pages.search.publicPrivateOptions.private').toString(),
        f: () => ({ isPublic: this.getInitialAgent().isPublic }),
      },
    ];
  }

  // Events
  onClickBullet(item: { text: string, f: () => { [k in keyof Agent ]?: Agent[k] } }) {
    this.onUpdateAgent(item.f());
    // this.$vmx.search.updateAgent(item.f());
  }
}
