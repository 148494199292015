













































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import { ContactInfo } from '@/interfaces/non-entities/contact-info';
import { emailService } from '@/services/restapi/email-service';
import InputPhone from '@/components/form-fields/input-phone.vue';

@Component({
  components: {
    AbstractForm,
  },
})
export default class FormCallMe extends Vue {
  @Prop({ default: null }) onSubmit: () => Promise<void>;
  @Prop({ default: 'white '}) textColor: string;
  @Prop() backgroundColor: string;
  @Prop({ default: '5' }) paddingX: string;
  @Prop({ default: '5' }) paddingY: string;

  @Ref() inputName: BaseInputField;
  @Ref() inputPhone: InputPhone;
  @Ref() inputEmail: BaseInputField;
  @Ref() inputCompany: BaseInputField;

  contactInfo: ContactInfo = {
    name: '',
    mail: '',
    companyName: '',
    phone: '',
  };

  showSent = false;

  get formIsValid(): boolean {
    let isValid = this.inputName.validate();
    isValid = this.inputEmail.validate() && isValid;
    isValid = this.inputPhone.validate() && isValid;
    isValid = this.inputCompany.validate() && isValid;
    return isValid;
  }

  setPhone(formatted: string) {
    this.contactInfo.phone = formatted;
  }

  async submit() {
    if (!this.showSent) {
      if (this.formIsValid) {
        emailService.scheduleCall(this.contactInfo)
          .then(() => {
            this.showSent = true;
            if (this.onSubmit != null) {
              this.showSent = true;
              this.onSubmit().then(() => {
                this.$emit('success');
              })
            } else {
              this.$emit('success');
            }
          })
          .catch(() => this.$emit('failure'));
      }
    }
  }
}
