

















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { CompanyRole } from '@/interfaces/company-role';
import { formatUtils } from '@/util/format-utils';
import { Modal } from 'bootstrap';
import { tipService } from '@/services/restapi/tip-service';

@Component({
  components: {
    BaseModalContainer,
  },
})
export default class ModalRoleInfo extends Vue {
  @Prop({ required: true }) modalId: string;
  @Prop({ required: true }) objectId: number;
  @Prop({ required: true }) objectName: string;
  @Prop({ required: true }) companyRole: CompanyRole;
  @Prop({ required: true }) isProcurement: boolean;
  @Prop() tipSent = false;
  loading = true;
  submitting = false;
  error = false;
  tipAlreadySent = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  initializeModal() {
    if (this.companyRole) {
      this.loading = true;
      tipService.companyRoleHasTip(this.companyRole.id).then((alreadySent) => {
        this.tipAlreadySent = alreadySent;
      }).finally(() => this.loading = false);
    }
  }

  get roleName(): string {
    const unspecified = this.$t('general.unspecified') as string;
    if (this.companyRole.role && this.companyRole.role.name && this.companyRole.role.name != unspecified) {
      return this.companyRole.role.name;
    } else if (this.companyRole.roleString) {
      return this.companyRole.roleString;
    } else {
      return unspecified;
    }
  }

  public formatDate(date: string, format: string): string {
    return formatUtils.formatDate(date, format);
  }

  getNoteFormatted(note: string) {
    return formatUtils.formatTextToHtml(note);
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  async sendTip() {
    this.submitting = true;
    const user = this.$vmx.user.user;
    const objTrans = this.$t(this.isProcurement ? 'general.procurement' : 'general.project') as string;
    const tip = {
      projectId: this.isProcurement ? null : this.objectId,
      procurementId: this.isProcurement ? this.objectId : null,
      paUserId: user.id,
      companyRoleId: this.companyRole.id,
      name: user.name,
      email: user.mail,
      phone: user.phone,
      description: this.$t(
        'components.modals.role.tipDescription',
        [user.name, objTrans, this.objectName, this.objectId, this.companyRole.companyName, this.roleName],
      ) as string,
    };
    try {
      await tipService.postTip(tip);
      this.$emit('tipSent', this.companyRole.id);
      this.submitting = false;
    } catch (error) {
      this.error = true;
      this.submitting = false;
    }
  }
}
