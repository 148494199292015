import { Domain } from '../interfaces/domain';
import domainsJSON from '../../resources/domains/domains.json';
import { vmx } from '@/store';

export const domains: Domain[] = domainsJSON;
const [label, sld, tld] = window.location.hostname.split('.');

export function findDomain(): Domain {
  const host = window.location.hostname;
  const domains: Domain[] = domainsJSON;
  if (host === 'localhost') {
    const tld: string = new URL(location.toString()).searchParams.get('domain');
    const domain: Domain = domains.filter((domain) => domain.tld == tld)[0]
      ?? domains.filter((domain) => domain.tld == 'dk')[0]
    return domain;
  }

  const domain: Domain = domains.filter(
    (domain: Domain) => domain.tld === tld && domain.sld === sld,
  )[0];

  if (domain == null) {
    return domains.filter((domain) => domain.tld == 'dk')[0]
  }

  return domain;
}

export const chooseDomain = (domain: Domain) => {
  if (location.hostname === 'localhost') {
    const url: URL = new URL(location.toString())
    url.searchParams.set('domain', domain.tld)
    vmx.language.updateLanguage(domain.languageId);
    location.href = url.toString();
    return;
  }
  const [ root ] = location.host.split('.');
  location.href = `https://${root}.${domain.sld}.${domain.tld}`;
}

export const domain: Domain = findDomain();
