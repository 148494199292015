

































































import { Component, Vue } from 'vue-property-decorator';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { globalFunctions } from '@/util/global-functions';
import { List } from '@/interfaces/list';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';

@Component({
  components: {
    BaseModalContainer,
  },
})
export default class ModalListRemove extends Vue {
  selectedLists: List[] = [];
  loading = true;
  submitting = false;
  error = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  initializeModal() {
    this.loading = true;
    if (this.listsWithDataObject.length > 0) {
      this.selectedLists.push(this.listsWithDataObject[0])
    }
    this.loading = false;
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  get modalId(): string {
    return ModalIds.LIST_REMOVE_MODAL;
  }

  get dataObject(): Project | Procurement | ProcurementReference {
    return this.$vmx.modal.listObject;
  }

  get listsWithDataObject(): List[] {
    return this.$vmx.list.listsWithObject(this.dataObject);
  }

  selectedContainsList(id: number): boolean {
    return this.selectedLists.some(list => list.id === id);
  }

  createListName(list: List) {
    const length = list.paUserIds.length;
    if (length > 1) {
      return `${list.name} (${this.$tc('components.iconBar.list.sharedWidth', length - 1, [length - 1])})`;
    }
    return list.name;
  }

  addList(list: List) {
    if (!this.selectedContainsList(list.id)) {
      this.selectedLists.push(list);
    }
  }

  removeList(id: number) {
    if (this.selectedContainsList(id)) {
      const index = this.selectedLists.findIndex((list) => list.id === id);
      if (index >= 0) {
        this.selectedLists.splice(index, 1);
      }
    }
  }

  async removeFromLists() {
    if (this.selectedLists.length <= 0) {
      return;
    }
    this.submitting = true;
    try {
      for (const selectedList of this.selectedLists) {
        let newList: List = null;
        const isProcurement = globalFunctions.isProcurementOrProcurementReference(this.dataObject);
        if (isProcurement) {
          const index = selectedList.procurements.findIndex((proc) => proc.id === this.dataObject.id);
          if (index >= 0) {
            selectedList.procurements.splice(index, 1);
            await this.$vmx.list.putList(selectedList);
          }
        } else {
          const index = selectedList.projects.findIndex((proc) => proc.id === this.dataObject.id);
          if (index >= 0) {
            selectedList.projects.splice(index, 1);
            await this.$vmx.list.putList(selectedList);
          }
        }
      }
      this.error = false;
      this.hideModal();
      this.submitting = false;
    } catch (error) {
      this.error = true;
      this.submitting = false;
    }
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }
}
