import { Component, Vue } from 'vue-property-decorator';
import Error404 from '@/components/error-components/error-404.vue';
import TheHeader from '@/components/headers/the-header.vue';

@Component({
  components: {
    Error404,
    TheHeader,
  },
})
export default class Error404View extends Vue {
  get isLoggedIn(): boolean {
    return this.$vmx.session.isLoggedIn;
  }
}
