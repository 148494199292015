import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Company } from '@/interfaces/company';
import { CompanyList } from '@/interfaces/company-list';
import { companyListService } from '@/services/restapi/company-list-service';
import Error401 from '@/components/error-components/error-401.vue';
import SearchInput from '@/components/form-fields/search-input.vue';
import TableCompanyList from '@/components/company-list-components/table-company-list.vue';

@Component({
  components: {
    Error401,
    SearchInput,
    TableCompanyList,
  },
})
export default class CompanyListView extends Vue {
  @Prop({ required: true }) id: number;

  companies: Company[] = [];
  filterText = '';
  loading = true;
  error = false;

  mounted() {
    this.loading = true;
    this.$vmx.companyList.fetchCompanyLists().then(() => {
      this.updateCompanies().finally(() => {
        this.loading = false;
      })
    });
  }

  get companyList(): CompanyList {
    return this.$vmx.companyList.companyListById(this.id);
  }

  get filteredCompanies(): Company[] {
    return this.companies.filter(company =>
      company.companyName.toLowerCase().includes(this.filterText.toLowerCase()),
    );
  }

  get title() {
    if (this.companyList?.paUserIds?.length > 1) {
      return `${(this.$t('general.shared') as string).toUpperCase()} - ${this.companyList.name}`;
    }
    return this.companyList?.name;
  }

  @Watch('companyList')
  async updateCompanies() {
    if (this.companyList != null) {
      try {
        this.companies = await companyListService.getCompanyListItems(this.companyList.id);
        this.error = false;
      } catch (error) {
        this.error = true;
      }
    }
  }

  openCompanyListSettingsModal() {
    this.$vmx.modal.openCompayListSettingsModal({ companyList: this.companyList });
  }

}
