








import { Component, Prop, Vue } from 'vue-property-decorator';
import { CompanyAgent } from '@/interfaces/search/company-agent';
import { companyService } from '@/services/restapi/company-service';

@Component
export default class CompanyAgentCountField extends Vue {
  @Prop({ required: true }) companyAgent: CompanyAgent;
  @Prop({ default: null }) maxDaysOld: number;
  amountOfResults = 0;

  created() {
    companyService.searchCompanyCount(this.companyAgent, this.maxDaysOld).then((count) => this.amountOfResults = count);
  }
}
