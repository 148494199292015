






























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseLogo from '@/components/buttons/base-logo.vue';
import DropdownDomain from '@/components/dropdowns/dropdown-domain.vue';
import DropdownLogin from '@/components/dropdowns/dropdown-login.vue';
import DropdownProfile from '@/components/dropdowns/dropdown-profile.vue';
import FormLogin from '@/components/forms/form-login.vue';
import HeaderRoutingBar from '@/components/headers/header-routing-bar.vue';
import { RoutingButton } from '@/interfaces/non-entities/routing-button';

@Component({
  components: {
    BaseLogo,
    DropdownDomain,
    DropdownLogin,
    FormLogin,
    HeaderRoutingBar,
    DropdownProfile,
  },
})
export default class HeaderDefault extends Vue {
  @Prop({ required: true }) routingButtons: RoutingButton[];
  @Prop({ required: true }) showLogin: boolean;
  @Prop({ required: true }) setShowLogin: (val: boolean) => void;
  // showLogin = false;

  // get routingButtons(): RoutingButton[] {
  //   return [
  //     {
  //       title: 'pages.services.title',
  //       name: 'Services',
  //     },
  //     {
  //       title: 'pages.prices.title',
  //       name: 'Prices',
  //     },
  //     {
  //       title: 'pages.about.title',
  //       name: 'About',
  //     },
  //     {
  //       title: 'pages.bookDemo.title',
  //       name: 'Book Demo',
  //       marginClass: 'ms-md-auto',
  //     },
  //   ];
  // }

}
