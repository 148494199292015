import { findDomain } from './domain-utils';
import { Language } from '@/interfaces/language';
import languagesJSON from '../../resources/languages/languages.json';

export const languages: Language[] = languagesJSON;

export const getLanguageId: () => Language = () => {
  try {
    const languages: Language[] = languagesJSON;
    const languageId = JSON.parse(localStorage.getItem('languageId')) as Language;
    if (languageId?.id == null) {
      languages.find(language => language.id === findDomain().languageId.id)
      return findDomain().languageId;
    }
    return languageId;
  } catch (err) {
    return findDomain().languageId;
  }
};
