import { Component, Vue } from 'vue-property-decorator';
import { globalFunctions } from '@/util/global-functions';
import ModalAgentDelete from '@/components/modals/modal-agent-delete.vue';
import ModalAgentReplace from '@/components/modals/modal-agent-replace.vue';
import ModalAgentSettings from '@/components/modals/modal-agent-settings.vue';
import ModalBlacklistRemove from '@/components/modals/modal-blacklist-remove.vue';
import ModalBlacklistSettings from '@/components/modals/modal-blacklist-settings.vue';
import ModalCompanyAgentDelete from '@/components/modals/modal-company-agent-delete.vue';
import ModalCompanyAgentReplace from '@/components/modals/modal-company-agent-replace.vue';
import ModalCompanyAgentSettings from '@/components/modals/modal-company-agent-settings.vue';
import ModalCompanyListAdd from '@/components/modals/modal-company-list-add.vue';
import ModalCompanyListRemove from '@/components/modals/modal-company-list-remove.vue';
import ModalCompanyListSettings from '@/components/modals/modal-company-list-settings.vue';
import ModalListAdd from '@/components/modals/modal-list-add.vue';
import ModalListRemove from '@/components/modals/modal-list-remove.vue';
import ModalListSettings from '@/components/modals/modal-list-settings.vue';
import ModalPaywall from '@/components/modals/modal-paywall.vue';
import ModalTip from '@/components/modals/modal-tip.vue';
import { Notification } from '@/interfaces/notification';
import { notificationService } from '@/services/restapi/notification-service';
import NotificationSidebar from '@/components/sidebars/notification-sidebar.vue';
import TheFooter from '@/components/footers/the-footer.vue';
import TheHeader from '@/components/headers/the-header.vue';
import TheLoginPrompt from '@/components/general-components/the-login-prompt.vue';

@Component({
  components: {
    ModalAgentDelete,
    ModalAgentReplace,
    ModalAgentSettings,
    ModalBlacklistRemove,
    ModalBlacklistSettings,
    ModalCompanyAgentDelete,
    ModalCompanyAgentReplace,
    ModalCompanyAgentSettings,
    ModalCompanyListAdd,
    ModalCompanyListRemove,
    ModalCompanyListSettings,
    ModalListAdd,
    ModalListRemove,
    ModalListSettings,
    ModalPaywall,
    ModalTip,
    NotificationSidebar,
    TheFooter,
    TheHeader,
    TheLoginPrompt,
  },
})
export default class LayoutLoggedIn extends Vue {
  notifications: Notification[] = null;
  showSidebar = false;
  loading = true;

  get paywallModalId() {
    return globalFunctions.getPaywallModalId();
  }

  get hasAccessToSite() {
    if (this.$vmx.session.isLoggedinUser) {
      return true;
    }

    if (this.$route.meta.limitedAccess && this.$vmx.session.isLoggedInLimitedAccess) {
      return true;
    }

    return false;
  }

  mounted() {
    if (this.$vmx.session.isLoggedinUser) {
      this.loading = true;
      this.$vmx.user.fetchUser().then(user => {
        if (user?.showPopups) {
          notificationService.getNotifications(false).then((notifications) => {
            notifications.sort((a, b) => a.created > b.created ? -1 : 1);
            this.notifications = notifications;
            if (notifications != null && notifications.length > 0) {
              this.showSidebar = true;
            }
          });
        }
        
      }).finally(() => {
        this.loading = false;
      })
    } else {
      this.loading = false;
    }
  }

  getBackground(fileName: string) {
    return 'url(' + this.$vmx.file.fromFile('project-agent/backgrounds/' + fileName) + ')';
  }
}
