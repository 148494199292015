














































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import { ContactInfo } from '@/interfaces/non-entities/contact-info';
import { emailService } from '@/services/restapi/email-service';
import InputDatetime from '@/components/form-fields/input-datetime.vue';
import InputPhone from '@/components/form-fields/input-phone.vue';

@Component({
  components: {
    AbstractForm,
  },
})
export default class FormBookDemo extends Vue {
  @Prop({ default: null }) onSubmit: () => Promise<void>;
  @Prop({ default: 'white '}) textColor: string;
  @Prop() backgroundColor: string;
  @Prop({ default: '5' }) paddingX: string;
  @Prop({ default: '5' }) paddingY: string;
  @Ref() inputName: BaseInputField;
  @Ref() inputEmail: BaseInputField;
  @Ref() inputPhone: InputPhone;
  @Ref() inputCompany: BaseInputField;
  @Ref() InputDatetime: InputDatetime;

  liveValidate = 'onlyOnValid'

  contactInfo: ContactInfo = {
    name: null,
    companyName: null,
    mail: null,
    phone: null,
    date: null,
    online: true,
    url: location.href,
  };

  showSent = false;

  get formIsValid(): boolean {
    let isValid = this.inputName.validate();
    isValid = this.inputEmail.validate() && isValid;
    isValid = this.inputPhone.validate() && isValid;
    isValid = this.inputCompany.validate() && isValid;
    return isValid;
  }

  setDateTime(dateTime: string) {
    this.contactInfo.date = dateTime;
  }

  setPhone(formatted: string) {
    this.contactInfo.phone = formatted;
  }

  get online() {
    return this.contactInfo.online == true ? 'online' : 'offline' as const;
  }

  set online(val) {
    this.contactInfo.online = val === 'online';
  }

  async submit() {
    if (this.showSent) {
      this.liveValidate = 'always'
      return;
    }

    if (!this.formIsValid) {
      return;
    }

    try {
      await emailService.sendBookDemo(this.contactInfo);

      this.showSent = true;

      if (this.onSubmit == null) {
        this.$emit('success');
        return;
      }

      await this.onSubmit();
      this.$emit('success');
    } catch (e) {
      this.$emit('failure')
      return;
    }
  }

  public setSize() {
    this.InputDatetime.setSize();
  }
}
