import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Role } from '@/interfaces/criterias/role';

const api = '/roles';

export class ProjectRoleService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getRoles = async (): Promise<Role[]> => {
    const response = await this.get<Role[]>(api);
    return this.getResponse(response);
  };

  public getRoleById = async (id: number): Promise<Role> => {
    const response = await this.get<Role>(api + '/' + id);
    return this.getResponse(response);
  };
}

export const projectRoleService = new ProjectRoleService(apiConfig);
