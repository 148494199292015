





























































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import BaseInputTextarea from '@/components/form-fields/base-input-textarea.vue';
import { ContactInfo } from '@/interfaces/non-entities/contact-info';
import { emailService } from '@/services/restapi/email-service';
import InputPhone from '@/components/form-fields/input-phone.vue';

@Component({
  components: {
    AbstractForm,
  },
})
export default class FormAskQuestion extends Vue {
  @Prop({ default: () => { return {
    name: '',
    mail: '',
    companyName: '',
    phone: '',
    date: null,
    time: null,
    online: true,
    question: '',
  } as ContactInfo } }) contactInfo: ContactInfo
  @Prop({ default: null }) onSubmit: () => Promise<void>;
  @Prop({ default: 'white '}) textColor: string;
  @Prop() backgroundColor: string;
  @Prop({ default: '5' }) paddingX: string;
  @Prop({ default: '5' }) paddingY: string;

  @Ref() inputName: BaseInputField;
  @Ref() inputEmail: BaseInputField;
  @Ref() inputPhone: InputPhone;
  @Ref() inputCompany: BaseInputField;
  @Ref() inputQuestion: BaseInputTextarea;

  showSent = false;

  get formIsValid(): boolean {
    let isValid = this.inputName.validate();
    isValid = this.inputEmail.validate() && isValid;
    isValid = this.inputPhone.validate() && isValid;
    isValid = this.inputCompany.validate() && isValid;
    isValid = this.inputQuestion.validate() && isValid;
    return isValid;
  }

  async submit() {
    if (!this.showSent) {
      if (this.formIsValid) {
        emailService.sendQuestion(this.contactInfo)
          .then(() => {
            this.showSent = true;
            if (this.onSubmit != null) {
              this.onSubmit()
                .then(() => {
                  this.$emit('success')
                })
                .catch(() => this.$emit('failure'))
            } else {
              this.$emit('success');
            }
          })
          .catch(() => this.$emit('failure'));
      }
    }
  }

  setPhone(formatted: string) {
    this.contactInfo.phone = formatted;
  }

  public setContactInfo(contactInfo: ContactInfo) {
    this.contactInfo = {...contactInfo};
  }
}
