import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { LimitedAccess } from '@/interfaces/limited-access';
import { ResponseCodes } from '@/util/enums/response-codes';

const api = '/limited-access';

export class LimitedAccessService extends AbstractService { 
  session: number = 24 * 14;
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public async getLimitedAccess() {
    if (this.getToken() == null || this.getToken().length == 0) {
      return null;
    }

    const value = await this.get<LimitedAccess>(api)
    if (ResponseCodes.is2xx(value.status)) {
      return value.data;
    }

    return null;
  }

  public async getLimitedAccessToken(): Promise<string> {
    return this.getToken();
  }

  public async create({name, mail} : {name: string, mail: string}) {
    const value = await this.post<string>(api + '/create', { name, mail })

    if (ResponseCodes.is2xx(value.status)) {
      return ResponseCodes.SUCCESS; // A limited access has been created and mail sent
    }

    if (value.status == ResponseCodes.FORBIDDEN) { 
      return ResponseCodes.FORBIDDEN; // IP Blacklisted
    }

    if (value.status == ResponseCodes.CONFLICT) {
      return ResponseCodes.CONFLICT; // Mail already exists
    }

    return ResponseCodes.INTERNAL_SERVER_ERROR; // I dont know
  }

  public async resend(mail: string) {
    const value = await this.post<string>(api + '/resend', { mail })

    if (value.status == ResponseCodes.SUCCESS) {
      return ResponseCodes.SUCCESS // Missing email
    }

    if (value.status == ResponseCodes.BAD_REQUEST) {
      return ResponseCodes.BAD_REQUEST // Missing email
    }

    if (value.status == ResponseCodes.NOT_FOUND) {
      return ResponseCodes.NOT_FOUND // Email not in database
    }

    return ResponseCodes.INTERNAL_SERVER_ERROR; // I dont know
  }

  public async auth({mail, code}: {mail: string, code: string}) {
    const value = await this.post<string>(api + '/auth', {mail, code})

    if (value.status == ResponseCodes.BAD_REQUEST) {
      return ResponseCodes.BAD_REQUEST // Missing body
    }

    if (value.status == ResponseCodes.NOT_FOUND) {
      return ResponseCodes.NOT_FOUND // Could not find email in db
    }

    if (value.status == ResponseCodes.UNAUTHORIZED) {
      return ResponseCodes.UNAUTHORIZED // Code does not match db
    }

    if (ResponseCodes.is2xx(value.status) && value.data != null) {
      this.setToken(value.data, this.session);
      return ResponseCodes.SUCCESS;
    }
  
    return ResponseCodes.INTERNAL_SERVER_ERROR // I dont know
  }
}

export const limitedAccessService = new LimitedAccessService(apiConfig);